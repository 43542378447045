<div>
    <div class="row">
        <div class="col-12">
            <div class="table-container">
                <h2>Zugewiesene Termine</h2>

                <table>
                    <thead>
                    <tr>
                        <th>Von</th>
                        <th>User</th>
                        <th>Termin</th>
                        <th></th>
                        <th></th>
                    </tr>
                    </thead>

                    <tbody>
                    <tr *ngFor="let row of appointments">
                        <td>{{row.formerAssignedConsultant}}</td>
                        <td>{{row.userId}}</td>
                        <td>{{utilitiesService.formatDisplayedDateAndTime(row.dateTime)}}</td>
                        <td>
                            <a class="table-link"
                               (click)="rejectAppointment(row.uuid, row.dateTime)">
                                Ablehnen
                            </a>
                        </td>
                        <td>
                            <a class="table-link"
                               (click)="confirmAppointment(row.uuid)">
                                Annehmen
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
