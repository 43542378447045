<div class="wrapper">
    <sc-month-calendar [grow]="config.growMode"
                       [dayOfWeekCaptionFormatter]="dayOfWeekFormatter"
                       [value]="newDate"
                       [firstDayOfWeek]="config.firstDayOfWeek"
                       (change)="setNewDate($event)">

        <!-- Day caption template -->
        <ng-template scDayTemplate
                     let-day>
            <div class="month__day"
                 [class.month__day--selected]="day?.isSelected">
                <span [ngClass]="getAvailabilityStateCssClass(day)">
                    {{ day?.day }}
                </span>
            </div>
        </ng-template>

        <!-- Day of week caption template -->
        <ng-template scDayOfWeekCaptionTemplate
                     let-dayOfWeek
                     let-dayOfWeekIndex="dayOfWeekIndex">
            <div class="month__day-of-week-caption">
                <span>{{ dayOfWeek }}</span>
            </div>
        </ng-template>

        <!-- Month caption template -->
        <ng-template scMonthCaptionTemplate
                     let-date>
            <div class="month__caption">
                <span class="month-selector month-selector--prev">
                    <button class="no-btn"
                            (click)="navigateMonths('prev')">
                        <svg class="arrow-svg"
                             viewBox="0 0 13 24"
                             width="100%"
                             height="100%">
                            <use xlink:href="./assets/icons/arrow.svg#icon"></use>
                        </svg>
                    </button>
                </span>

                <div class="month-label">
                    {{ config.monthNames[date.getMonth()] }}
                    {{ date.getFullYear() }}
                </div>

                <span class="month-selector month-selector--next">
                    <button class="no-btn"
                            (click)="navigateMonths('next')">
                        <svg class="arrow-svg"
                             viewBox="0 0 13 24"
                             width="100%"
                             height="100%">
                            <use xlink:href="./assets/icons/arrow.svg#icon"></use>
                        </svg>
                    </button>
                </span>
            </div>
        </ng-template>

    </sc-month-calendar>

    <div *ngIf="legend"
         class="legend">
        <div *ngFor="let legendItem of legend"
             class="legend-item legend-item--{{legendItem.cssClass}}">
            {{legendItem.label}}
        </div>
    </div>
</div>
