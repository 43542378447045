import {Component, Input, ViewChild} from '@angular/core';
import {UtilitiesService} from '../../../../../../services/global/utilities.service';
import {DragScrollComponent} from 'ngx-drag-scroll';

@Component({
    selector: 'tk-bo-stop-smoking-health-objective-details-list',
    templateUrl: './stop-smoking-health-objective-details-list.component.html',
    styleUrls: ['./stop-smoking-health-objective-details-list.component.scss']
})
export class StopSmokingHealthObjectiveDetailsListComponent {

    @Input() calendarData: any;
    @Input() stopSmokingDate?: string;
    
    constructor(public readonly utilitiesService: UtilitiesService) {
    }

    public getWeekdayByDate(dateString: string): string {
        const date = new Date(dateString);

        return this.utilitiesService.getWeekdayByDate(date, 'SHORT');
    }
}
