import {NgModule} from '@angular/core';
import {PreloadAllModules, RouterModule, Routes} from '@angular/router';

import {
    AppointmentAvailabilityComponent
} from './modules/consultant-ui/appointments/appointment-availability/appointment-availability.component';
import {AppointmentManagementComponent} from './modules/consultant-ui/appointments/appointment-management/appointment-management.component';
import {AppointmentStatusComponent} from './modules/consultant-ui/appointments/appointment-status/appointment-status.component';
import {AppointmentsComponent} from './modules/consultant-ui/appointments/appointments.component';
import {
    ParticipantConversationProtocolComponent
} from './modules/consultant-ui/participants/participant-conversation-protocol/participant-conversation-protocol.component';
import {ParticipantsComponent} from './modules/consultant-ui/participants/participants.component';
import {LoginComponent} from './modules/login/login.component';
import {DashboardComponent} from './components/global/dashboard/dashboard.component';
import {
    AppointmentConfirmAssignmentsComponent
} from './modules/consultant-ui/appointments/appointment-confirm-assignments/appointment-confirm-assignments.component';
import {LoginGuard} from './shared/guards/login.guard';
import {AuthGuard} from './shared/guards/auth.guard';

const routes: Routes = [
    {
        path: '',
        component: LoginComponent,
        canActivate: [LoginGuard],
    },
    {
        path: 'start',
        component: DashboardComponent,
        canActivate: [AuthGuard],
    },
    {
        path: 'nutzer',
        component: ParticipantsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: 'gespraechsverlauf',
                component: ParticipantConversationProtocolComponent,
            },
        ],
    },
    {
        path: 'termine',
        component: AppointmentsComponent,
        canActivate: [AuthGuard],
        children: [
            {
                path: '',
                pathMatch: 'full',
                redirectTo: 'verwaltung',
            },
            {
                path: 'verwaltung',
                component: AppointmentManagementComponent,
            },
            {
                path: 'verfuegbarkeit',
                component: AppointmentAvailabilityComponent,
            },
            {
                path: 'status',
                component: AppointmentStatusComponent,
            },
            {
                path: 'termine-bestaetigen',
                component: AppointmentConfirmAssignmentsComponent,
            },
        ],
    },
    {
        path: 'auth/callback',
        redirectTo: 'start'
    }

];

@NgModule({
    imports: [RouterModule.forRoot(routes, {
        scrollPositionRestoration: 'enabled',
        preloadingStrategy: PreloadAllModules
    })],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
