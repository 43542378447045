<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>
    <h1>
        {{text}}
    </h1>

    <div *ngIf="additionalText"
         class="spacer--top"
         [innerHTML]="additionalText">
    </div>

    <div *ngIf="buttonLabel"
         class="button-container">
        <tkui-button class="float-right"
                     [label]="buttonLabel"
                     [viewType]="'primary'"
                     (click)="closeModal()">
        </tkui-button>
    </div>
</div>
