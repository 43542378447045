<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <h1>
        Möchten Sie {{reservedSlotsToCancel.length > 1 ? 'folgenden Termin' : 'folgende Termine'}} wirklich löschen?
    </h1>

    <ul>
        <li *ngFor="let slot of reservedSlotsToCancel">
            Termin {{slot.id}} - {{util.formatDisplayedTime(slot.dateTime)}}
        </li>
    </ul>

    <p>
        Weisen Sie die Termine besser einem Ihrer Kollegen zu!
    </p>
</div>

<button class="btn btn-primary"
        (click)="confirmCancelSlots()">
    Löschen!
</button>

<button class="btn"
        (click)="closeModal()">
    Abbrechen
</button>
