<div class="main main--white">
    <tkui-warn-notice *ngIf="isIE()"
                      [title]="'Beachten Sie:'"
                      [description]="'Das Beraterinterface ist nicht für den Internet Explorer optimiert! Unter Umständen sind Funktionen nicht nutzbar oder werden falsch dargestellt!'"
                      [type]="'info'"
                      [fullWidth]="false">
    </tkui-warn-notice>

    <h1>Willkommen im Beraterinterface</h1>

    <div *ngIf="userService.isConsultantUser()"
         class="spacer--top">
        <h2>Terminverwaltung</h2>
        <ul>
            <li *ngFor="let entry of navigationEntries.consultantInterface">
                <a [routerLink]="entry.href">
                    {{entry.overviewLabel}}
                </a>
            </li>
        </ul>
    </div>
</div>
