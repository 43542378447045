import {Component, forwardRef, Input} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';

@Component({
    selector: 'tk-bo-toggle-switch',
    templateUrl: './toggle-switch.component.html',
    styleUrls: ['./toggle-switch.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ToggleSwitchComponent),
            multi: true,
        }
    ]
})
export class ToggleSwitchComponent {

    @Input() label: string;
    @Input() title?: string;
    @Input() id?: string;

    public value: string;

    constructor() {
    }

    public get idPrefix() {
        return 'toggle_' + this.id;
    }

    public onChange(value) {
        this.onChangePublishFn(value);
        this.onTouchedPublishFn();
    }

    onChangePublishFn = (value: any) => {
    };

    onTouchedPublishFn = () => {
    };

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChangePublishFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedPublishFn = fn;
    }



}
