<!-- Month -->
<div [ngClass]="['column', grow.mode == 'stretch' ? 'stretch-vertically' : '', getClassForMonth()]"
     (click)="onMonthClick()">

  <!-- Month caption -->
  <div class="row">

    <!-- Month caption cell -->
    <div class="w-100"
         [class.label]="grow.mode != 'mixed'"
         [class.ratio-height]="grow.mode == 'proportional'"
         [class.stretch-horizontally]="grow.mode == 'stretch' || grow.mode == 'mixed'">

      <!-- Month caption cell content -->
      <div [class.label__content]="grow.mode != 'mixed'">
        <ng-container *ngTemplateOutlet="monthTemplate ? monthTemplate : defaultMonthTemplate; context: { $implicit: value }"></ng-container>
      </div>
    </div>
  </div>

  <!-- Week captions -->
  <div class="row">

    <!-- Week captions cell -->
    <div *ngFor="let dayOfWeekCaption of daysOfWeekCaptions; let i = index"
         [class.label]="grow.mode != 'mixed'"
         [class.ratio-square]="grow.mode == 'proportional'"
         [class.stretch-horizontally]="grow.mode == 'stretch' || grow.mode == 'mixed'">

      <!-- Week caption cell content -->
      <div [class.label__content]="grow.mode != 'mixed'">
        <ng-container *ngTemplateOutlet="dayOfWeekTemplate ? dayOfWeekTemplate : defaultDayOfWeekTemplate; context: { $implicit: dayOfWeekCaption, dayOfWeekIndex: i }"></ng-container>
      </div>
    </div>
  </div>

  <div *ngFor="let week of view"
       class="row">

    <div *ngFor="let day of week"
         class="ratio-square label"
         [class.ratio-square]="grow.mode == 'proportional' || grow.mode == 'mixed'"
         [class.stretch-horizontally]="grow.mode == 'stretch'"
         (click)="onDayClick(day)">

      <div class="label__content">
        <ng-container *ngTemplateOutlet="dayTemplate ? dayTemplate : defaultDayTemplate; context: { $implicit: day }"></ng-container>
      </div>
    </div>
  </div>
</div>


<!-- Templates -->

<!-- Day template -->
<ng-template #defaultDayTemplate
             let-day>
  <div class="flex-expanded-container">
    <div [ngClass]="['flex-expand', getClassForDay(day)]">
        {{ getFormattedDay(day) }}
    </div>
  </div>
</ng-template>

<!-- Day of week cells template -->
<ng-template #defaultDayOfWeekTemplate
             let-dayOfWeek
             let-dayOfWeekIndex="dayOfWeekIndex">
  <div [class.flex-expanded-container]="grow.mode != 'mixed'">
    <div [ngClass]="['flex-expand', 'flex-centered', dayOfWeekCaptionClass]">
      {{ dayOfWeek }}
    </div>
  </div>
</ng-template>

<!-- Month cell template -->
<ng-template #defaultMonthTemplate
             let-date>
  <div [class.flex-expanded-container]="grow.mode != 'mixed'">
    <div [ngClass]="['flex-expand', 'flex-centered', monthCaptionClass]">
      {{ monthCaption }}
    </div>
  </div>
</ng-template>
