import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tk-bo-confirm-block-account-modal',
    templateUrl: './confirm-modal.component.html',
    styleUrls: ['./confirm-modal.component.scss']
})
export class ConfirmModalComponent {

    @Input() cancelLabel: string;
    @Input() confirmLabel: string;
    @Input() content: string;

    constructor(public activeModal: NgbActiveModal) { }

    public confirm(): void {
        this.activeModal.close();
    }

    public closeModal(): void {
        this.activeModal.dismiss();
    }

}
