<div class="row">
    <div class="col-5"></div>
    <div class="col-7">
        <div class="select-spacer">
            <div *ngIf="userService.isSupervisor()">
                <h2>Programme:</h2>

                <div class="program-type-radio">
                    <input type="radio"
                           id="programType_default"
                           name="programType"
                           value="default"
                           (click)="onProgramTypeChange('default')" checked>
                    <label class="program-type-radio__label"
                           for="programType_default">
                        Alle
                    </label>
                </div>

                <div *ngFor="let programType of programTypes"
                     class="program-type-radio">
                    <input type="radio"
                           id="programType_{{programType.name}}"
                           name="programType"
                           value="{{programType.name}}"
                           (click)="onProgramTypeChange(programType.name)">
                    <label class="program-type-radio__label"
                           for="programType_{{programType.name}}">
                        {{programType.shorthand_name}}
                    </label>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-5">
        <tk-bo-calendar *ngIf="calendar"
                        [legend]="calendarLegend"
                        [availabilityArray]="calendar"
                        (dateChange)="setDate($event)">
        </tk-bo-calendar>
    </div>
    <div class="col-7">
        <div class="select-spacer">
            <div *ngIf="userService.isSupervisor()">
                <h2>Mitarbeiter:</h2>

                <tk-bo-select *ngIf="consultants.length > 0"
                              class="select-container"
                              [options]="consultants"
                              (changeSelected)="updateConsultant($event)">
                </tk-bo-select>

                <div *ngIf="consultants.length === 0">Es wurden keine Mitarbeiter:innen gefunden</div>
            </div>
        </div>

        <div class="push-availability">
            <div *ngFor="let week of weeks, let weekIndex = index"
                 class="row week-spacer">
                <div *ngIf="week?.length > 0"
                     class="col-3 checkbox-container">
                    <label class="week-label"
                           for="week_{{weekIndex}}">
                        <span>{{week.length === 5 ? 'Ganze Woche' : 'Alle Tage'}}</span>
                        <input class="checkbox"
                               id="week_{{weekIndex}}"
                               type="checkbox"
                               (change)="processWeekSelection($event.target.checked, week, weekIndex.toString())"/>
                        <span class="checkmark checkmark--small"></span>
                    </label>
                </div>

                <div *ngIf="week?.length > 0"
                     class="col-3 shift-select-container">
                    <tk-bo-select [options]="shiftStartOptions"
                                  [changeDetectionId]="weekIndex.toString()"
                                  (changeSelected)="processShiftStartSelection($event)">
                    </tk-bo-select>
                </div>

                <div *ngIf="week?.length > 0"
                     class="col-3 shift-select-container">
                    <tk-bo-select [options]="shiftEndOptions"
                                  [changeDetectionId]="weekIndex.toString()"
                                  (changeSelected)="processShiftEndSelection($event)">
                    </tk-bo-select>
                </div>

                <div *ngIf="week?.length > 0"
                     class="col-3 availability-check">
                    Anwesend
                    <div>
                        Ja
                        <input type="radio"
                               name="weekAvailability-{{weekIndex}}"
                               value="available"
                               (click)="availabilityCheckClicked('available', weekIndex.toString())"/>
                        Nein
                        <input type="radio"
                               name="weekAvailability-{{weekIndex}}"
                               value="not_available"
                               (click)="availabilityCheckClicked('not_available', weekIndex.toString())"/>
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="row">
    <div class="col-12">
        <div class="btn-container spacer--top">
            <button class="btn btn-primary"
                    (click)="processRequestData()">
                Verfügbarkeiten speichern
            </button>
        </div>
    </div>
</div>

<div *ngIf="userService.isSupervisor()"
     class="row">
    <div class="col-12">
        <tk-bo-appointment-consultant-availability-table *ngIf="consultants.length > 0 && showTable"
                                                         [date]="currentDate"
                                                         [consultants]="consultants">
        </tk-bo-appointment-consultant-availability-table>
    </div>
</div>

