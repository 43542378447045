import {Component, OnInit, Input, forwardRef, Output, EventEmitter} from '@angular/core';
import {NG_VALUE_ACCESSOR} from '@angular/forms';
import {SelectOptionInterface} from '../../../interfaces/selectOption.interface';

@Component({
    selector: 'tk-bo-select',
    templateUrl: './select.component.html',
    styleUrls: ['./select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        }
    ]
})
export class SelectComponent implements OnInit {

    @Input() changeDetectionId?: string;
    @Input() isDisabled?: boolean;
    @Input() label: string;
    @Input() options?: SelectOptionInterface[] = [];
    @Input() showBorder = false;

    @Output() changeSelected: EventEmitter<object> = new EventEmitter();

    public inputId: string;
    public value: string;


    constructor() { }

    ngOnInit() {
        this.setInitialValue();
    }

    public onChange(value): void {
        this.onChangePublishFn(value);
        this.onTouchedPublishFn();

        if (typeof this.changeDetectionId !== 'undefined') {
            this.changeSelected.emit({
                val: value,
                changeDetectionId: this.changeDetectionId
            });
        } else {
            this.changeSelected.emit(value);
        }
    }

    private setInitialValue(): void {
        for (const option of this.options) {
            if (option.selected) {
                this.value = option.value;
                break;
            }
        }
    }

    // callback functions, bridge to angular form control
    // this empty functions will be overridden by angular (show: registerOnChange, registerOnTouched)
    onChangePublishFn = (value: any) => {};
    onTouchedPublishFn = () => {};

    writeValue(value: any) {
        this.value = value;
    }

    registerOnChange(fn: any): void {
        this.onChangePublishFn = fn;
    }

    registerOnTouched(fn: any): void {
        this.onTouchedPublishFn = fn;
    }

}
