import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';

import {CombinedAppointmentsStatusInterface, MonthlyStatisticInterface} from '../../../../interfaces/appointment.interface';
import {AppointmentService} from '../../../../services/consultant-ui/appointment.service';
import {SupervisorAppointmentApiService} from '../../../../services/consultant-ui/supervisor-appointment-api.service';
import {ToastService} from '../../../../services/global/toast.service';
import {SpinnerService} from '../../../../services/global/spinner.service';
import {DownloadService} from '../../../../services/global/download.service';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {firstValueFrom, lastValueFrom, Subscription} from 'rxjs';

@Component({
    selector: 'tk-bo-appointment-status',
    templateUrl: './appointment-status.component.html',
    styleUrls: ['./appointment-status.component.scss']
})
export class AppointmentStatusComponent implements OnInit, OnDestroy {

    public appointments: CombinedAppointmentsStatusInterface = {} as CombinedAppointmentsStatusInterface;
    public monthStatistics: MonthlyStatisticInterface[];
    public pageSize = 5; // items per page
    public validatorConstants = ConsultantUiConstants.settingsBorderValues;

    public settingsForm: UntypedFormGroup = new UntypedFormGroup({
        teaserLoginCount: new UntypedFormControl(ConsultantUiConstants.settingsBorderValues.teaserLoginCount.min, Validators.compose([Validators.required, Validators.min(ConsultantUiConstants.settingsBorderValues.teaserLoginCount.min), Validators.max(ConsultantUiConstants.settingsBorderValues.teaserLoginCount.max)])),
        teaserWeekCount: new UntypedFormControl(ConsultantUiConstants.settingsBorderValues.teaserWeekCount.min, Validators.compose([Validators.required, Validators.min(ConsultantUiConstants.settingsBorderValues.teaserWeekCount.min), Validators.max(ConsultantUiConstants.settingsBorderValues.teaserWeekCount.max)]))
    });

    private subscriptions: Subscription[] = [];

    constructor(public readonly appointmentService: AppointmentService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly toastService: ToastService,
                private readonly spinnerService: SpinnerService,
                private readonly downloadService: DownloadService) {
    }

    async ngOnInit() {
        await this.fetchStatisticsMonth();
        await this.fetchStatistics();
    }

    ngOnDestroy() {
        this.subscriptions.forEach(subscription => {
            subscription?.unsubscribe();
        });
    }

    public getBookedByPage(pageConfig): void {
        this.fetchDataByTypeAndPage('booked', this.pageSize, pageConfig.currentPage, pageConfig.sortBy, pageConfig.ascending);
    }

    public getCanceledByUserByPage(pageConfig): void {
        this.fetchDataByTypeAndPage('canceledByUser', this.pageSize, pageConfig.currentPage, pageConfig.sortBy, pageConfig.ascending);
    }

    public getCanceledByConsultantByPage(pageConfig): void {
        this.fetchDataByTypeAndPage('canceledByConsultant', this.pageSize, pageConfig.currentPage, pageConfig.sortBy, pageConfig.ascending);
    }

    public getReachedByPage(pageConfig): void {
        this.fetchDataByTypeAndPage('reached', this.pageSize, pageConfig.currentPage, pageConfig.sortBy, pageConfig.ascending);
    }

    public onSettingsFormSubmit(form): void {
        firstValueFrom(this.supervisorAppointmentApiService.postSettings(form, ConsultantUiConstants.program.antistress.programType))
            .then(() => {
                this.toastService.showSuccess('Einschränkungen gespeichert');
            });
    }

    public parseDateToMonthYear(date: string): string {
        const _date = new Date(date);
        return ConsultantUiConstants.monthNames[_date.getMonth()] + ' ' + _date.getFullYear();
    }

    public onDownloadCsvClicked(): void {
        this.spinnerService.show();
        this.subscriptions.push(this.supervisorAppointmentApiService.getStatisticsCsv().subscribe({
            next: (response) => {
                this.downloadService.downloadText(response);
            },
            complete: () => {
                this.spinnerService.hide();
            }
        }));
    }

    private async fetchStatisticsMonth(): Promise<void> {
        this.spinnerService.onRequestStart();
        this.subscriptions.push(this.supervisorAppointmentApiService.getAppointmentStatisticsMonthly().subscribe({
            next: (response) => {
                this.monthStatistics = response.items;
                this.spinnerService.onRequestEnd();
            },
            error: (error) => {
                console.log('Error at fetchStatisticsMonth:', error);
                this.spinnerService.onRequestEnd();
            }
        }));
    }

    private async fetchStatistics(): Promise<void> {
        this.spinnerService.onRequestStart();
        this.subscriptions.push(this.supervisorAppointmentApiService.getAppointmentStatistics(this.pageSize).subscribe({
            next: (response: CombinedAppointmentsStatusInterface) => {
                this.appointments = response;
                this.spinnerService.onRequestEnd();
            },
            error: (error) => {
                this.spinnerService.onRequestEnd();
                console.log('Error fetchStatistics:', error);
            }
        }));
    }

    private async fetchDataByTypeAndPage(type: string, pageSize: number, page: number, sortBy?: string, ascending?: boolean) {
        this.spinnerService.onRequestStart();
        this.subscriptions.push(this.supervisorAppointmentApiService.getAppointmentStatusByType(type, pageSize, page, sortBy, ascending).subscribe({
            next: (response) => {
                this.appointments[type] = response;
                this.spinnerService.onRequestEnd();
            },
            error: (error) => {
                console.log('Error fetchDataByTypeAndPage', error);
                this.spinnerService.onRequestEnd();
            }
        }));
    }

}
