<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <div class="spacer--top"
         [innerHTML]="content">
    </div>
</div>

<div class="spacer--top">
    <button class="btn btn-primary"
            (click)="confirm()">
        {{confirmLabel}}
    </button>

    <button class="btn"
            (click)="closeModal()">
        {{cancelLabel}}
    </button>
</div>
