import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {OAuthService} from 'angular-oauth2-oidc';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoginGuard  {

  public hasValidAccessToken = false;

  constructor(private readonly oauthService: OAuthService,
              private readonly router: Router) {
    this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
  }

  public canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | Promise<any> | boolean {
    if (this.hasValidAccessToken) {
      return this.router.navigateByUrl('start');
    }
  }
}
