<div class="row">
    <div class="col-7">
        <div class="day-selector-container">
            <button class="day-selector day-selector--prev no-btn"
                    (click)="navigateDays('prev')">
                <svg class="arrow-svg"
                     viewBox="0 0 13 24"
                     width="100%"
                     height="100%">
                    <use xlink:href="./assets/icons/arrow.svg#icon"></use>
                </svg>
            </button>

            <h1>
                {{util.getWeekdayByDate(currentDate, 'LONG')}} {{currentDate.toLocaleDateString()}}
            </h1>

            <button class="day-selector day-selector--next no-btn"
                    (click)="navigateDays('next')">
                <svg class="arrow-svg"
                     viewBox="0 0 13 24"
                     width="100%"
                     height="100%">
                    <use xlink:href="./assets/icons/arrow.svg#icon"></use>
                </svg>
            </button>
        </div>

        <div *ngIf="consultants.length > 0"
             class="select-container row">
            <div class="col-2"></div>
            <div class="col-5">
                <tk-bo-select *ngIf="userService.isSupervisor()"
                              class="select-one"
                              [options]="consultantOneOptions"
                              (changeSelected)="updateEmployeeOne($event)">
                </tk-bo-select>

                <span *ngIf="!userService.isSupervisor()">
                   {{username}}
                </span>
            </div>

            <div class="col-5">
                <tk-bo-select *ngIf="consultantTwoOptions"
                              class="select-two"
                              [options]="consultantTwoOptions"
                              (changeSelected)="updateEmployeeTwo($event)">
                </tk-bo-select>
            </div>
        </div>

        <div *ngIf="consultants.length === 0 && !isRequestBusy">Es wurden keine Berater:innen gefunden.</div>

        <div *ngIf="consultants.length > 0 && employeeOne && employeeTwo && employeeOne.programTypes"
             class="row">
            <div class="col-2"></div>

            <div class="col-5">
                <span
                    *ngIf="isProgramTypeAvailable(employeeOne.programTypes, programConstants.stop_smoking.programType)"
                    class="consultant-program-type">
                    {{programConstants.stop_smoking.shorthand_name}}
                </span>
                <span
                    *ngIf="isProgramTypeAvailable(employeeOne.programTypes, programConstants.antistress.programType)"
                    class="consultant-program-type">
                    {{programConstants.antistress.shorthand_name}}
                </span>
            </div>

            <div class="col-5">
                <span
                    *ngIf="isProgramTypeAvailable(employeeTwo.programTypes, programConstants.stop_smoking.programType)"
                    class="consultant-program-type">
                    {{programConstants.stop_smoking.shorthand_name}}
                </span>
                <span
                    *ngIf="isProgramTypeAvailable(employeeTwo.programTypes, programConstants.antistress.programType)"
                    class="consultant-program-type">
                    {{programConstants.antistress.shorthand_name}}
                </span>
            </div>
        </div>

        <tk-bo-employee-appointments-column *ngIf="employeeOne?.availabilities && employeeTwo?.availabilities"
                                            [employeeOne]="employeeOne"
                                            [employeeTwo]="employeeTwo"
                                            [currentDate]="currentDate"
                                            (selectedEmployeeOne)="updateSelectedSlots($event)">
        </tk-bo-employee-appointments-column>
    </div>

    <div class="col-5">
        <tk-bo-calendar *ngIf="calendar"
                        [availabilityArray]="calendar"
                        [legend]="calendarLegend"
                        [newDate]="currentDate"
                        (dateChange)="processDateUpdateAndUpdateEmployeeAvailabilities($event)">
        </tk-bo-calendar>

        <div *ngIf="util.isTodayOrInTheFuture(currentDate)"
             class="btn-container spacer--top">
            <ng-container *ngIf="userService.isConsultant()">
                <button class="btn btn-primary btn-fullWidth"
                    [disabled]="slotsToConfirm.length < 1"
                    (click)="confirmAssignments()">

                    <span *ngIf="slotsToConfirm.length <= 1">
                        Terminzuweisung bestätigen
                    </span>
                    <span *ngIf="slotsToConfirm.length > 1">
                        {{slotsToConfirm.length}} Terminzuweisungen bestätigen
                    </span>
                </button>

                <button class="btn btn-primary btn-fullWidth"
                        [disabled]="slotsToConfirm.length < 1"
                        (click)="rejectAssignments()">

                    <span *ngIf="slotsToConfirm.length <= 1">
                        Terminzuweisung ablehnen
                    </span>
                    <span *ngIf="slotsToConfirm.length > 1">
                        {{slotsToConfirm.length}} Terminzuweisungen ablehnen
                    </span>
                </button>
            </ng-container>

            <button class="btn btn-primary btn-fullWidth"
                    [disabled]="slotsToCancel.length < 1"
                    (click)="cancelSlots()">
                <span *ngIf="slotsToCancel.length <= 1">
                    Termin löschen
                </span>
                <span *ngIf="slotsToCancel.length > 1">
                    {{slotsToCancel.length}} Termine löschen
                </span>
            </button>

            <button class="btn btn-primary btn-fullWidth"
                    [disabled]="slotsToReassign.length < 1"
                    (click)="reassignSlots()">
                <span *ngIf="slotsToReassign.length <= 1">
                    Termin zuweisen
                </span>
                <span *ngIf="slotsToReassign.length > 1">
                    {{slotsToReassign.length}}Termine zuweisen
                </span>
            </button>

            <button *ngIf="slotToBook"
                    class="btn btn-primary btn-fullWidth"
                    [disabled]="!slotToBook.day || !slotToBook.isBookable"
                    (click)="bookSlotsWithUser()">
                Termin mit User buchen
            </button>

            <button class="btn btn-primary btn-fullWidth"
                    [disabled]="slotsToMakeAvailable.length < 1"
                    (click)="makeSlotsAvailable()">
                <span *ngIf="slotsToMakeAvailable.length <= 1">
                    Termin verfügbar machen
                </span>
                <span *ngIf="slotsToMakeAvailable.length > 1">
                    {{slotsToMakeAvailable.length}}Termine verfügbar machen
                </span>
            </button>
        </div>
    </div>
</div>

