import {Injectable} from '@angular/core';
import {BehaviorSubject, lastValueFrom, Observable} from 'rxjs';
import {AuthConfig, OAuthService} from 'angular-oauth2-oidc';
import {RoutesConstants} from '../../constants/routes.constants';
import {StateHandlerService} from './statehandler.service';
import {SessionStorageService} from '../../services/global/session-storage.service';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private readonly _authenticationChanged: BehaviorSubject<boolean> = new BehaviorSubject(this.authenticated);

    constructor(private readonly authConfig: AuthConfig,
                private readonly oauthService: OAuthService,
                private readonly stateHandler: StateHandlerService) {
    }

    public requestedUrl: string;

    private _authenticated = false;
    private userRoles: any;

    private static trimContextPath(url: string): string {
        if (url.indexOf(RoutesConstants.context) !== -1) {
            url = url.substring(RoutesConstants.context.length, url.length);
        }

        return url;
    }

    public get authenticated(): boolean {
        return this._authenticated;
    }

    public get authenticationChanged(): Observable<boolean> {
        return this._authenticationChanged;
    }

    public getAuthorizationHeader(): string {
        return this.oauthService.authorizationHeader();
    }

    public getZitadelBaseUrl(): string {
        return this.authConfig.issuer;
    }

    public loadUserProfile(): any {
        return this.oauthService.loadUserProfile();
    }

    public getAccessToken(): any {
        return this.oauthService.getAccessToken();
    }

    public resetToken(): void {
        this.oauthService.refreshToken();
    }

    public async authenticate(setState: boolean = true): Promise<boolean> {

        this.oauthService.configure(this.authConfig);
        this.oauthService.setupAutomaticSilentRefresh();

        this.oauthService.strictDiscoveryDocumentValidation = false;
        await this.oauthService.loadDiscoveryDocumentAndTryLogin();

        this._authenticated = this.oauthService.hasValidAccessToken();


        if (!this.oauthService.hasValidIdToken() || !this.authenticated) {
            const newState = setState ? await lastValueFrom(this.stateHandler.createState()) : undefined;
            this.oauthService.initCodeFlow(newState);
        }

        const userProfile = await this.loadUserProfile();
        this.userRoles = userProfile.info['urn:zitadel:iam:org:project:roles'];

        this._authenticationChanged.next(this.authenticated);

        return this.authenticated;
    }

    public hasRole(role: string): boolean {
        return this.userRoles && !!this.userRoles[role];
    }

    public getRoles(): any {
        return this.userRoles;
    }

    public logout(): void {
        this.oauthService.logOut();
        SessionStorageService.resetSessionStorage();
        this._authenticated = false;
        this._authenticationChanged.next(false);
    }

    public saveRequestedUrl(url: string, params?: string) {
        if (url.indexOf(RoutesConstants.login.href) === -1) {
            let requestedUrl = AuthenticationService.trimContextPath(url);

            if (params) {
                requestedUrl += params;
            }

            this.requestedUrl = requestedUrl;
        }
    }


}
