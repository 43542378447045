import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {AppointmentApiService} from '../../../services/consultant-ui/appointment-api.service';
import {AppointmentResult} from '../../../interfaces/appointment.interface';
import {lastValueFrom} from 'rxjs';

@Component({
    selector: 'tk-bo-confirm-unavailable-modal',
    templateUrl: './confirm-unavailable-modal.component.html',
    styleUrls: ['./confirm-unavailable-modal.component.scss']
})
export class ConfirmUnavailableModalComponent {

    @Input() appointmentId: string;
    @Input() cancelLabel: string;
    @Input() confirmLabel: string;

    public note = '';

    constructor(public activeModal: NgbActiveModal,
        private readonly appointmentApiService: AppointmentApiService) { }

    public confirm(): void {
        lastValueFrom(this.appointmentApiService.saveAppointmentProtocol(this.appointmentId, AppointmentResult.NOT_REACHED, 0, this.note))
            .then(() => {
                this.activeModal.close();
            });
    }

    public closeModal(): void {
        this.activeModal.dismiss();
    }

    public onChange(text: string): void {
        this.note = text;
    }

}
