<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <div class="spacer--top">
        <h1>Haben Sie den Teilnehmer wirklich nicht erreicht?</h1>
    </div>
</div>

<div class="spacer--top">
        <h2>Notizen</h2>

        <textarea [value]="note"
                  [maxLength]="255"
                  (change)="onChange($event.target.value)">
        </textarea>
</div>

<div class="spacer--top">
    <button class="btn btn-primary"
            (click)="confirm()">
        {{confirmLabel}}
    </button>

    <button class="btn"
            (click)="closeModal()">
        {{cancelLabel}}
    </button>
</div>
