<h4 *ngIf="title"
    class="title">
    {{title}}
</h4>

<label class="switch"
       [for]="idPrefix">
    <span [innerHTML]="label"></span>

    <input type="checkbox"
           [checked]="value"
           [id]="idPrefix"
           (change)="onChange($event.target.checked)">

    <span class="slider"></span>
</label>
