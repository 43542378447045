import {AfterViewChecked, ChangeDetectorRef, Component, OnDestroy, OnInit} from '@angular/core';
import {SpinnerService} from './services/global/spinner.service';
import {animate, style, transition, trigger} from '@angular/animations';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    animations: [
        trigger('fade', [
            transition(':enter', [
                style({
                    height: 0,
                    opacity: 0
                }),
                animate(200)
            ]),
            transition(':leave', [
                style({
                    height: '*',
                    opacity: 0
                }),
                animate(500)
            ])
        ])
    ]
})
export class AppComponent implements OnInit, AfterViewChecked, OnDestroy {

    spinnerState: boolean;
    spinnerStateSubscription;

    constructor(private readonly spinner: SpinnerService,
                private readonly cdRef: ChangeDetectorRef) {}

    ngOnInit(): void {
        this.initSpinnerStateSubscription();
    }

    ngAfterViewChecked(): void {
        this.cdRef.detectChanges();
    }

    ngOnDestroy(): void {
        this.spinnerStateSubscription.unsubscribe();
    }

    initSpinnerStateSubscription() {
        this.spinnerStateSubscription = this.spinner.spinnerState$.subscribe((spinnerState: boolean) => {
            this.spinnerState = spinnerState;
        });
    }
}
