import {Component, EventEmitter, Output} from '@angular/core';

@Component({
    selector: 'tk-bo-modal-close',
    templateUrl: './modal-close.component.html',
    styleUrls: ['./modal-close.component.scss']
})
export class ModalCloseComponent {

    @Output() closeModalEventEmitter: EventEmitter<undefined> = new EventEmitter();

    constructor() { }

    public closeModalIconClicked(): void {
        this.closeModalEventEmitter.emit();
    }

}
