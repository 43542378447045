<h3>Protokoll der Rauchentwöhnung</h3>
<div *ngIf="calendarData">

        <div *ngFor="let day of calendarData"
             class="carousel-list-item">
            <span *ngIf="stopSmokingDate === day.date"
                  class="carousel-list-item-date">
                <strong>Rauchstopp</strong>
            </span>

            <div class="carousel-list-item-icon-container">
                <span>
                    <!--<tk-bo-boolean-to-icon [boolean]="day.smokeFree"></tk-bo-boolean-to-icon>-->
                </span>
            </div>

            <span class="carousel-list-item-date">
                <strong>{{getWeekdayByDate(day.date)}}</strong> {{utilitiesService.formatToGermanDisplayedDate(day.date)}}
            </span>
        </div>

    <button class="no-btn button--prev"
            (click)="moveLeft()">
        <svg class="arrow-svg"
             viewBox="0 0 13 24"
             width="100%"
             height="100%">
            <use xlink:href="./assets/icons/arrow.svg#icon"></use>
        </svg>
    </button>

    <button class="no-btn button--next"
            (click)="moveRight()">
        <svg class="arrow-svg"
             viewBox="0 0 13 24"
             width="100%"
             height="100%">
            <use xlink:href="./assets/icons/arrow.svg#icon"></use>
        </svg>
    </button>
</div>
<!--
<div class="spacer&#45;&#45;top">
    <span>
        <tk-bo-boolean-to-icon [boolean]="true"></tk-bo-boolean-to-icon>
    </span>

    <span class="legend-text">
        rauchfrei
    </span>

    <span>
        <tk-bo-boolean-to-icon [boolean]="false"></tk-bo-boolean-to-icon>
    </span>

    <span class="legend-text">
        nicht rauchfrei
    </span>
</div>-->
