import {Component, OnInit} from '@angular/core';
import {AppointmentService} from '../../../../services/consultant-ui/appointment.service';
import {UtilitiesService} from '../../../../services/global/utilities.service';
import {UserService} from '../../../../services/global/user.service';
import {SessionStorageService} from '../../../../services/global/session-storage.service';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {Router} from '@angular/router';
import {AppointmentApiService} from '../../../../services/consultant-ui/appointment-api.service';
import {AssignmentInterface, DateTime} from '../../../../interfaces/appointment.interface';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalConstants} from '../../../../constants/modal.constants';
import {ConfirmModalComponent} from '../../../../components/modals/confirm-modal/confirm-modal.component';
import {firstValueFrom} from 'rxjs';

@Component({
    selector: 'tk-bo-appointment-confirm-assignments',
    templateUrl: './appointment-confirm-assignments.component.html',
    styleUrls: ['./appointment-confirm-assignments.component.scss']
})
export class AppointmentConfirmAssignmentsComponent implements OnInit {

    public appointments: AssignmentInterface[];

    private modalOptions: NgbModalOptions = ModalConstants.modalOptions;

    constructor(public readonly appointmentService: AppointmentService,
                public readonly utilitiesService: UtilitiesService,
                private readonly userService: UserService,
                private readonly sessionStorageService: SessionStorageService,
                private readonly router: Router,
                private readonly appointmentApiService: AppointmentApiService,
                private readonly modal: NgbModal) {

    }

    ngOnInit() {
        this.getUnconfirmedAppointments();
    }


    public confirmAppointment(appointmentId: string): void {
        firstValueFrom(this.appointmentApiService.confirmAssignments([appointmentId]))
            .then(() => {
                this.filterSelected(appointmentId);
            });
    }

    public rejectAppointment(appointmentId: string, appointmentDateTime: string): void {
        const modalRef = this.modal.open(ConfirmModalComponent, this.modalOptions);

        modalRef.componentInstance.confirmLabel = 'Ablehnen';
        modalRef.componentInstance.cancelLabel = 'Abbrechen';
        modalRef.componentInstance.text = '<h1>Möchten Sie den Termin wirklich ablehnen?</h1>' +
            '<p>' + this.utilitiesService.formatDisplayedDateAndTime(appointmentDateTime) + '</p>';
        modalRef.result
            .then(() => {
                this.confirmAppointmentRejection(appointmentId);
            })
            .catch(() => {
            });
    }

    private getUnconfirmedAppointments(): void {
        firstValueFrom(this.appointmentApiService.getAssignments())
            .then((response: { assignedAppointments: AssignmentInterface[] }) => {
                this.appointments = response.assignedAppointments;
                this.sessionStorageService.save(ConsultantUiConstants.sessionStorage.assignments, this.appointments.length);
            });
    }

    private confirmAppointmentRejection(uuid: string): void {
        firstValueFrom(this.appointmentApiService.rejectAssignments([uuid]))
            .then(() => {
                this.filterSelected(uuid);
            });
    }

    private filterSelected(appointmentId: string): void {
        this.appointments = this.appointments.filter((item) => {
            return item.uuid !== appointmentId;
        });

        // substract the filtered assignment
        this.appointmentService.decrementAssignedAppointmentCount(1);

        if (this.sessionStorageService.get(ConsultantUiConstants.sessionStorage.assignments) === 0) {
            this.router.navigate(['/termine/verwaltung']);
        }
    }

}
