export const ConversationGuideConstants = {
    STOP_SMOKING: {
        content: [{
            title: 'Anliegen und Auftragsklärung',
            expanded: false,
            children: [{
                title: 'Themen/Fragestellung User',
                expanded: false,
                children: [{
                    text: 'Frage zum NRC'
                }, {
                    text: 'Gesundheitsrisiken'
                }, {
                    text: 'andere Methoden'
                }, {
                    text: 'Adressen von Anbietern Tabakentwöhnung'
                }, {
                    text: 'E-Zigarette und ähnliche Produkte'
                }, {
                    text: 'Tabakerhitzer (IQOS)'
                }, {
                    text: 'Schnupftabak/Kautabak/oraler Tabak'
                }, {
                    text: 'Pfeife/Zigarre/Wasserpfeife'
                }, {
                    text: 'Anwendung Nikotinersatz'
                }, {
                    text: 'Buproprion/Vareniclin (Zyban ®, Champix ®)'
                }, {
                    text: 'Entzugserscheinungen'
                }, {
                    text: 'Rauchstopp und Gewicht'
                }, {
                    text: 'Konsum anderer Substanzen'
                }, {
                    text: 'Lob und Dank'
                }, {
                    text: 'Beschwerde'
                }]
            }, {
                title: 'Aktuelles Rauchverhalten',
                expanded: false,
                children: [{
                    text: 'vor Rauchstopptermin, Rauchverhalten nicht verändert'
                }, {
                    text: 'Rauchfrei nach Plan ohne Ausrutscher'
                }, {
                    text: 'Rauchfrei nach Ausrutscher'
                }, {
                    text: 'mind. 24h rauchfrei, jetzt wieder rauchend'
                }, {
                    text: 'Rauchend, Konsum reduziert um mind. 50%'
                }, {
                    text: 'Rauchverhalten nicht verändert (nach Rauchstopptermin)'
                }]
            }, {
                title: 'In welchen Situationen fällt es schwer?',
                expanded: false,
                children: [{
                    text: 'nach dem Aufwachen'
                }, {
                    text: 'zum Kaffee'
                }, {
                    text: 'nach Mahlzeiten'
                }, {
                    text: 'in Verbindung mit Alkohol'
                }, {
                    text: 'bei emotional belastenden Ereignissen'
                }, {
                    text: 'bei emotional erfreulichen Ereignissen'
                }, {
                    text: 'in Pausen'
                }, {
                    text: 'andere Rauchende'
                }, {
                    text: 'bei starkem Rauchverlangen'
                }, {
                    text: 'andere Entzugssymptome'
                }]
            }, {
                title: 'Funktionen des Rauchens',
                expanded: false,
                children: [{
                    text: 'Entspannung/Stressbewältigung/Beruhigung'
                }, {
                    text: 'Genuss'
                }, {
                    text: 'Geselligkeit/ Umgang mit Einsamkeit'
                }, {
                    text: 'Kommunikation/Kontakt'
                }, {
                    text: 'Zeitstruktur/ Pausenhelfer'
                }, {
                    text: 'Pause, Auszeit-Signal'
                }, {
                    text: 'Alltagsritual'
                }, {
                    text: 'Gewichtskontrolle'
                }, {
                    text: 'Stimmung aufhellen'
                }, {
                    text: 'Teil des Selbstbild'
                }, {
                    text: 'Klarheit und Sicherheit'
                }, {
                    text: 'Belohnung'
                }, {
                    text: 'Konzentration'
                }, {
                    text: 'Verdauung'
                }, {
                    text: 'Symbol Freiheit und Widerstand'
                }, {
                    text: 'Anregung'
                }]
            }, {
                title: 'Medikamentöse Unterstützung (nur Nikotinpräparate)',
                expanded: false,
                children: [{
                    text: 'Nikotinpflaster'
                }, {
                    text: 'Nikotinkaugummi'
                }, {
                    text: 'Nikotintabletten'
                }, {
                    text: 'Nikotin-Mundspray'
                }, {
                    text: 'Nikotin-Inhaler'
                }, {
                    text: 'Kombination von 2 Präparaten'
                }, {
                    text: 'Fragen Anwendung/Dosierung'
                }]
            }]
        }, {
            title: 'Empfehlungen und Interventionen',
            expanded: false,
            children: [{
                title: 'Ansprechen und Stärken der Motivation',
                expanded: false,
                children: [{
                    text: '„Warum ist es Ihnen wichtig“ erfragen'
                }, {
                    text: 'Motive validieren'
                }, {
                    text: 'wie wichtig von 1 – 10'
                }, {
                    text: 'Ambivalenz ansprechen (zweiseitige Reflektion)'
                }, {
                    text: 'das Gute und das nicht so Gute'
                }, {
                    text: 'Vielzahl der Versuche gewürdigt'
                }, {
                    text: 'Belastung durch Rauchen erfragen'
                }]
            }, {
                title: 'Ansprechen und Stärken der Selbstwirksamkeit',
                expanded: false,
                children: [{
                    text: 'frühere Abstinenzphasen erfragen und als Teilerfolg würdigen'
                }, {
                    text: 'persönliche Stärken würdigen'
                }, {
                    text: 'was bräuchte es, damit Sie es schaffen?'
                }, {
                    text: 'es kann schwierig sein, aber es ist zu schaffen'
                }, {
                    text: 'wie zuversichtlich von 1 – 10'
                }]
            }, {
                title: 'Umgang mit individuellen Risikosituationen, wenn keine Zigaretten greifbar',
                expanded: false,
                children: [{
                    text: 'Risikosituation a besprechen + Alternativen/Bewältigungsstrategien'
                }, {
                    text: 'Risikosituation b besprechen + Alternativen/Bewältigungsstrategien'
                }, {
                    text: 'Risikosituation c besprechen + Alternativen/Bewältigungsstrategien'
                }]
            }, {
                title: 'über Funktionen sprechen und ersetzen',
                expanded: false,
                children: [{
                    text: 'Anderes eigenes Verwöhnprogramm'
                }, {
                    text: 'Entspannungs-/Atemübung'
                }, {
                    text: 'Bewegung/körperl. Training'
                }, {
                    text: 'Alternatives Genussprogramm'
                }, {
                    text: 'Freunde anrufen/Brief schreiben'
                }, {
                    text: 'Musik machen/hören'
                }, {
                    text: 'von eingespartem Geld belohnen'
                }, {
                    text: 'Stress akzeptieren und anderen Umgang damit pflegen'
                }]
            }, {
                title: 'Umgang mit Rauchverlangen',
                expanded: false,
                children: [{
                    text: 'Rauchverlangen annehmen'
                }, {
                    text: 'Rauchverlangen vorbei ziehen lassen'
                }, {
                    text: 'Rauchverlangen als Störgeräusch bewerten'
                }, {
                    text: 'Aufmerksamkeit aktiv umlenken'
                }, {
                    text: 'VOR-Stategie: Vermeiden, Oberhand behalten, Rückzug antreten'
                }, {
                    text: 'Körperliche Aktivität'
                }, {
                    text: 'Gewissheit, dass es nachlässt/vorbeigeht'
                }]
            }, {
                title: 'Soziale Unterstützung nutzen',
                expanded: false,
                children: [{
                    text: 'Partner'
                }, {
                    text: 'Freunde'
                }, {
                    text: 'Familie'
                }, {
                    text: 'Kollegen'
                }, {
                    text: 'Fachpersonen'
                }]
            }, {
                title: 'Medikamentöse Unterstützung (nur Nikotinpräparate)',
                expanded: false,
                children: [{
                    text: 'Nikotinpflaster'
                }, {
                    text: 'Nikotinkaugummi'
                }, {
                    text: 'Nikotintabletten'
                }, {
                    text: 'Nikotin-Mundspray'
                }, {
                    text: 'Nikotin-Inhaler'
                }, {
                    text: 'Kombination von 2 Präparaten'
                }, {
                    text: 'Fragen Anwendung/Dosierung'
                }]
            }, {
                title: 'Nutzung weiterer Unterstützung Gruppenprogramm',
                expanded: false,
                children: [{
                    text: 'Apotheke (Beratung Medikamente)'
                }, {
                    text: 'Arzt: Beratung verschreibungspflichtige Medikamente'
                }, {
                    text: 'Arzt: Beratung sonstige Symptomatik'
                }, {
                    text: 'Arzt: Beratung Wechselwirkung Medikamente'
                }, {
                    text: 'Suchtberatung'
                }, {
                    text: 'Andere webbasierte/mobile Angebote'
                }]
            }]
        }, {
            title: 'Gesprächsabschluss und weitere Unterstützung',
            expanded: false,
            children: [{
                title: 'Bedarf weitere Themen aus NRC',
                expanded: false,
                children: [{
                    text: 'Erkenntnisse aus Protokoll'
                }, {
                    text: 'Rauchutensilien '
                }, {
                    text: 'Termin Rauchstopp'
                }, {
                    text: 'was hilft?'
                }, {
                    text: 'was fehlt?'
                }]
            }, {
                title: 'Nächsten Telefonkontakt hier vereinbaren (nur wenn Anzahl Gespräche <4)',
                expanded: false,
                children: [{
                    text: 'Datum, Uhrzeit (mit Terminverwaltung abgleichen)'
                }, {
                    text: 'Begründung für max. 4 Gespräche'
                }]
            }, {
                title: 'Zusammenfassung',
                expanded: false,
                children: [{
                    text: 'besprochene Fragen/Themen'
                }, {
                    text: 'wichtigste Fortschritte'
                }, {
                    text: 'wichtigste Risiken'
                }, {
                    text: 'wichtigste Bewältigungsstrategien'
                }, {
                    text: 'Folgekontakt'
                }, {
                    text: 'Validierung: was machen Sie bei Rauchverlangen in den nächsten Tagen?'
                }, {
                    text: 'Verabschiedung'
                }]
            }, {
                title: 'Anmerkung oder Besonderheiten zum Gespräch',
                expanded: false,
                children: [{
                    text: 'Anmerkung zum Gespräch'
                }, {
                    text: 'Besonderheiten zum Gespräch'
                }]
            }]
        }]
    }
};
