import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class CancelSlotsUpdateService {

    confirmedCancelSlotsUpdateSubject: Subject<void> = new Subject();
    completedCancelSlotsUpdateSubject: Subject<void> = new Subject();
    cancelReservedDaysUpdateSubject: Subject<void> = new Subject();

    constructor() { }

    public get confirmedCancelSlotsUpdate$(): Observable<void> {
        return this.confirmedCancelSlotsUpdateSubject.asObservable();
    }

    public get completedCancelSlotsUpdate$(): Observable<void> {
        return this.completedCancelSlotsUpdateSubject.asObservable();
    }

    public get cancelReservedDaysUpdate$(): Observable<void> {
        return this.cancelReservedDaysUpdateSubject.asObservable();
    }

    public emitConfirmedSlotsCancellation(): void {
        this.confirmedCancelSlotsUpdateSubject.next();
    }

    public emitCompletedSlotsCancellation(): void {
        this.completedCancelSlotsUpdateSubject.next();
    }

    public emitReservedDaysCancellation(): void {
        this.cancelReservedDaysUpdateSubject.next();
    }
}
