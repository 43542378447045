import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {TimeslotUpdate} from '../../interfaces/appointment.interface';

@Injectable({
    providedIn: 'root'
})
export class TimeslotUpdateService {

    public timeslotUpdateStateSubject: Subject<TimeslotUpdate> = new Subject();

    private timeslotUpdateState: boolean;

    constructor() { }

    public get timeslotUpdateState$(): Observable<TimeslotUpdate> {
        return this.timeslotUpdateStateSubject.asObservable();
    }

    public update(columnNumber: string, employeeName: string, date: string): void {
        this.timeslotUpdateState = true;
        this.triggerSpinnerStateUpdate(columnNumber, employeeName, date);

        setTimeout(() => {
            // set back after update
            this.timeslotUpdateState = false;
        }, 1000);
    }


    private triggerSpinnerStateUpdate(columnNumber: string, employeeName: string, date: string): void {
        const timeslotUpdate = {
            state: this.timeslotUpdateState,
            columnNumber: columnNumber,
            employeeName: employeeName,
            date: date
        };

        this.timeslotUpdateStateSubject.next(timeslotUpdate);
    }
}
