<h2>
    AntistressCoaching – alle Aktivitäten
    <span class="decent">({{maxItems}}/{{activitiesList.length}})</span>
</h2>

<div class="table-container">
    <table *ngIf="activitiesList">
        <thead>
        <tr>
            <th>Titel der Aktivität</th>
            <th *ngIf="showTypeCol">Bereich</th>
            <th>Geplant</th>
            <th>Protokolliert</th>
        </tr>
        </thead>
        <tbody>
        <tr *ngFor="let activity of activitiesList; let i = index;">
            <ng-container *ngIf="!maxItems || i < maxItems">
                <td>{{activity.headline}}</td>
                <td *ngIf="showTypeCol">{{activity.typeLabel}}</td>
                <td>{{activity.totalPlannedProtocols}}</td>
                <td>{{activity.doneProtocols}}</td>
            </ng-container>
        </tr>
        </tbody>
    </table>
</div>
