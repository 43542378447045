import {Component, Input, OnInit} from '@angular/core';
import {ActivitySummaryInterface} from '../../../interfaces/activity.interface';

@Component({
    selector: 'tk-bo-anti-stress-activity-summaries',
    templateUrl: './anti-stress-activity-summaries.component.html',
    styleUrls: ['./anti-stress-activity-summaries.component.scss']
})
export class AntiStressActivitySummariesComponent implements OnInit {

    @Input() activitySummaries?: ActivitySummaryInterface[];
    @Input() maxItems = 3;
    @Input() showTypeCol = false;
    @Input() user: {firstName: string, lastName: string, externalId: any};

    public activitiesList: ActivitySummaryInterface[];

    constructor() { }

    ngOnInit() {
        this.initList();
    }

    private initList(): void {
        if (this.activitySummaries?.length) {
            this.activitiesList = this.activitySummaries?.sort((summaryA, summaryB) => {
                return summaryB.doneProtocols - summaryA.doneProtocols;
            });
        }
    }
}
