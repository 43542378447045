export const TenantConstants = {
    TK_GESUNDHEITSCOACH_WEB: 'TK-GesundheitsCoach (Web)',
    TK_COACH_APP: 'TK-Coach (App)',
    TK_RAUCHFREI_APP: 'TK-Rauchfrei (App)'
};

export const ProgramTypeToTenant = {
    ANTISTRESS: 'TK_COACH_APP',
    STOP_SMOKING: 'TK_RAUCHFREI_APP'
};

export const TenantToProgramType = {
    TK_COACH_APP: 'ANTISTRESS',
    TK_RAUCHFREI_APP: 'STOP_SMOKING',
    TK_GESUNDHEITSCOACH_WEB: ''
};
