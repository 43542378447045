import {Component, ElementRef, EventEmitter, Input, OnInit, Output} from '@angular/core';

import { ConversationGuideConstants} from './conversation-guide.constants';
import {UserService} from '../../../../services/global/user.service';

@Component({
    selector: 'tk-bo-conversation-guide',
    templateUrl: './conversation-guide.component.html',
    styleUrls: ['./conversation-guide.component.scss']
})
export class ConversationGuideComponent implements OnInit {

    @Input() programType: string;
    @Input() notesDisabled: boolean;
    @Input() protocol?: string;

    @Output() notes: EventEmitter<string> = new EventEmitter();

    public conversationGuide = ConversationGuideConstants;
    public notesString = '';

    constructor(public readonly userService: UserService,
                private readonly elementRef: ElementRef) {
    }

    private static expandItem(wrapperElement: any): void {
        const contentEl = wrapperElement.querySelector('.jsAccordionItemContent');
        if (!contentEl) {
            return;
        }

        wrapperElement.style.height = 'auto';
    }

    private static collapseItem(wrapperElement: HTMLElement): void {
        wrapperElement.style.height = '0';
    }

    ngOnInit() {
        const expandWrapperEls = this.elementRef.nativeElement && this.elementRef.nativeElement.querySelectorAll('.jsAccordionItemExpanded');

        for (let i = 0; i < expandWrapperEls.length; i++) {
            ConversationGuideComponent.expandItem(expandWrapperEls[i]);
        }

        if (this.protocol) {
            this.notesString = this.protocol;
        }
    }

    public toggleExpanded(event: any, item): void {
        let contentWrapperEl: HTMLElement;

        if (event.target.nodeName === 'svg') {
            // click on the arrow
            contentWrapperEl = event.target.parentElement.parentElement.nextElementSibling;
        } else if (event.target.nodeName === 'use') {
            // some few times the 'use' is the clicked target
            contentWrapperEl = event.target.parentElement.parentElement.parentElement.nextElementSibling;
        } else {
            contentWrapperEl = event.target.nextElementSibling;
        }

        if (item.expanded) {
            ConversationGuideComponent.collapseItem(contentWrapperEl);
        } else {
            ConversationGuideComponent.expandItem(contentWrapperEl);
        }

        item.expanded = !item.expanded;
    }

    public appendTextblock(text): void {
        this.notesString += this.notesString.length === 0 ? '' : ' ';
        this.notesString += text + ';';
        this.emitNoteStringChange();
    }

    public onChange(text): void {
        this.notesString = text;
        this.emitNoteStringChange();
    }

    public emitNoteStringChange(): void {
        this.notes.emit(this.notesString);
    }
}
