<div *ngIf="monthStatistics"
     class="row">
    <div class="col-6">
        <h2>Statistik abgeschlossener Termine</h2>
        <div class="table-container">
            <table>
                <tbody>
                <tr>
                    <th>Monat</th>
                    <th>Anzahl NRC</th>
                    <th>Anzahl ASC</th>
                </tr>
                <tr *ngFor="let monthStatistic of monthStatistics">
                    <th>{{parseDateToMonthYear(monthStatistic.month)}}</th>
                    <td>{{monthStatistic.stopSmoking || '/'}}</td>
                    <td>{{monthStatistic.antistress || '/'}}</td>
                </tr>
                </tbody>
            </table>
        </div>
        <div>
            <button class="btn btn-primary"
                    (click)="onDownloadCsvClicked()">
                Download CSV
            </button>
        </div>
    </div>

    <div class="col-6">
        <h2>ASC: Einschränkung der Terminbuchung für den Nutzer ab (>=):</h2>
        <form class="form"
              [formGroup]="settingsForm"
              (ngSubmit)="onSettingsFormSubmit(settingsForm.value)">
            <div class="form-row form-group">
                <input class="col-2"
                       type="number"
                       id="teaserWeekCount"
                       formControlName="teaserWeekCount"/>
                <label class="col-10"
                       for="teaserWeekCount">
                    Gesundheitsziel-Woche ({{validatorConstants.teaserWeekCount.min}} - {{validatorConstants.teaserWeekCount.max}})
                </label>
            </div>

            <div class="form-row form-group">
                <input class="col-2"
                       type="number"
                       id="teaserLoginCount"
                       formControlName="teaserLoginCount"/>
                <label class="col-10"
                       for="teaserLoginCount">
                    Anzahl der Logins ({{validatorConstants.teaserLoginCount.min}} - {{validatorConstants.teaserLoginCount.max}})
                </label>
            </div>

            <div class="form-row no-gutters">
                <div class="col-12">
                    <button class="btn btn-primary"
                            type="submit"
                            [disabled]="settingsForm.invalid">
                        Speichern
                    </button>
                </div>
                <div class="col-8"></div>
            </div>
        </form>
    </div>
</div>

<div class="spacer--top">
    <tk-bo-appointment-status-table  *ngIf="appointments?.booked"
                                     [title]="'Zugewiesene Termine'"
                                     [noItemsText]="'keine zugewiesenen Termine'"
                                     [pageSize]="pageSize"
                                     [appointments]="appointments.booked"
                                     (paginationUpdate)="getBookedByPage($event)">
    </tk-bo-appointment-status-table>
</div>

<div class="spacer--top">
    <tk-bo-appointment-status-table  *ngIf="appointments?.canceledByUser"
                                     [title]="'Abgesagte Termine / <strong>durch User</strong>'"
                                     [noItemsText]="'keine von User abgesagten Termine'"
                                     [pageSize]="pageSize"
                                     [appointments]="appointments.canceledByUser"
                                     (paginationUpdate)="getCanceledByUserByPage($event)">
    </tk-bo-appointment-status-table>
</div>

<div class="spacer--top">
    <tk-bo-appointment-status-table  *ngIf="appointments?.canceledByConsultant"
                                     class="spacer--top"
                                     [title]="'Abgesagte Termine / <strong>durch Vilua</strong>'"
                                     [noItemsText]="'keine von Vilua abgesagten Termine'"
                                     [pageSize]="pageSize"
                                     [appointments]="appointments.canceledByConsultant"
                                     (paginationUpdate)="getCanceledByConsultantByPage($event)">
    </tk-bo-appointment-status-table>
</div>

<div class="spacer--top">
    <tk-bo-appointment-status-table  *ngIf="appointments?.reached"
                                     class="spacer--top"
                                     [title]="'Abgeschlossene Termine'"
                                     [noItemsText]="'keine abgeschlossenen Termine'"
                                     [pageSize]="pageSize"
                                     [appointments]="appointments.reached"
                                     (paginationUpdate)="getReachedByPage($event)">
    </tk-bo-appointment-status-table>
</div>
