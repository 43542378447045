<div *ngIf="table.length > 0 && _date"
     class="spacer--top-xl">
    <h2>Verfügbarkeiten der Berater am {{_date.toLocaleDateString()}}</h2>

    <table class="availability-table spacer--top">
        <thead *ngIf="timeSlots?.length > 0">
            <tr>
                <th></th>
                <td *ngFor="let time of timeSlots"
                    class="time-slot">
                    {{utilitiesService.formatDisplayedTime(time)}}
                </td>
            </tr>
        </thead>

        <tbody>
            <tr *ngFor="let row of table">
                <td class="consultant-name">
                    {{row.name}}
                </td>
                <td *ngFor="let slot of getTableRow(row)"
                    class="availability-slot"
                    [ngClass]="slot.state">
                    {{slot.programTypeShorthand}}
                </td>
            </tr>
        </tbody>
    </table>
</div>
