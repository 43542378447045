import {Component} from '@angular/core';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {RoutesConstants} from '../../../constants/routes.constants';
import {UserService} from '../../../services/global/user.service';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
    selector: 'tk-bo-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {

    public hasValidAccessToken = false;
    public navigationEntries = RoutesConstants;

    constructor(private readonly auth: AuthenticationService,
                private readonly oauthService: OAuthService,
                public readonly userService: UserService) {
        this.auth.authenticationChanged.subscribe(value => {
            this.onAuthChanged();
        });
    }

    public logout(): void {
        this.auth.logout();
    }

    private onAuthChanged(): void {
        this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    }

}
