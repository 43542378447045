export const ConsultantUiConstants = {
    sessionStorage: {
        timeSlots: 'timeSlots',
        username: 'username',
        roles: 'roles',
        assignments: 'assignedAppointments',
        note: 'conversationNotes-id-',
        consultantInfo: 'consultantInfo'
    },
    timeSlotState: {
        available: {
            label: 'Anwesend',
            state: 'available'
        },
        not_available: {
            label: 'Nicht Anwesend',
            state: 'not_available'
        },
        not_confirmed: {
            label: 'Noch Ausstehend',
            state: 'not_confirmed'
        },
        reserved: {
            label: 'Gebucht',
            state: 'reserved'
        }
    },
    appointmentState: {
        RESERVED: 'Angenommen',
        CONFIRMED: 'Bestätigt',
        NOT_CONFIRMED: 'Noch Ausstehend',
        REACHED: 'Erreicht',
        NOT_REACHED: 'Nicht erreicht',
        CANCELED_BY_USER: 'Abgesagt durch User',
        CANCELED_BY_CONSULTANT: 'Abgesagt durch Berater',
        NEW_APPOINTMENT_ARRANGED: 'Neuer Termin vereinbart'
    },
    monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    program: {
        stop_smoking: {
            name: 'STOP_SMOKING',
            shorthand_name: 'NRC',
            programType: 'STOP_SMOKING',
            title: 'Nichtrauchen',
            duration: 30,
            shiftStart: '08:30:00',
            shiftEnd: '18:00:00'
        },
        antistress: {
            name: 'ANTISTRESS',
            shorthand_name: 'ASC',
            programType: 'ANTISTRESS',
            title: 'Antistress',
            duration: 30,
            shiftStart: '08:30:00',
            shiftEnd: '18:00:00'
        },
        fitness_improvement: {
            name: 'FITNESS_IMPROVEMENT',
            shorthand_name: 'FC',
            programType: 'FITNESS_IMPROVEMENT',
            title: 'Fitness',
            duration: 30,
            shiftStart: '08:30:00',
            shiftEnd: '18:00:00'
        },
        reduce_weight_food: {
            name: 'REDUCE_WEIGHT_FOOD',
            shorthand_name: 'RWD',
            programType: 'REDUCE_WEIGHT_FOOD',
            title: 'Fitness',
            duration: 30,
            shiftStart: '08:30:00',
            shiftEnd: '18:00:00'
        }
    },
    settingsBorderValues: {
        teaserLoginCount: {
            max: 100,
            min: 0
        },
        teaserWeekCount: {
            max: 13,
            min: 0
        }
    }
};
