import {NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

export const ModalConstants = {
    modalOptions: {
        backdropClass: 'modalBackdropContainer',
        centered: true,
        keyboard: false,
        windowClass: 'modalContainer'
    } as NgbModalOptions
};
