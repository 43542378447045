<ul class="pager">
  <li *ngIf="currentPage > 1"
      class="prev-page">
    <a (click)="prevPage()">
      <svg  class="arrow-svg"
            viewBox="0 0 13 24"
            width="100%"
            height="100%">
        <use xlink:href="./assets/icons/arrow.svg#icon"></use>
      </svg>
    </a>
  </li>

  <li class="current-page">
    {{currentPage}} von {{totalPages}}
  </li>

  <li *ngIf="currentPage < totalPages"
      class="next-page">
    <a (click)="nextPage()">
      <svg  class="arrow-svg"
            viewBox="0 0 13 24"
            width="100%"
            height="100%">
        <use xlink:href="./assets/icons/arrow.svg#icon"></use>
      </svg>
    </a>
  </li>
</ul>
