<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <h1>
        Möchten Sie folgende Tage mit gebuchten Terminen wirklich mit Ihrer Auswahl überschreiben?
    </h1>

    <ul>
        <li *ngFor="let slot of reservedDaysToCancel">
            {{util.formatToGermanDisplayedDate(slot.date)}}
        </li>
    </ul>

    <p>
        Weisen Sie die gebuchten Termine lieber über die Terminverwaltung einem Kollegen zu!
    </p>
</div>

<button class="btn btn-primary"
        (click)="confirmCancelDays()">
    Überschreiben
</button>
<button class="btn"
        (click)="closeModal()">
    Abbrechen
</button>
