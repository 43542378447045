<div class="row">
    <div class="col-12">
        <h2 [innerHTML]="title"></h2>

        <div *ngIf="totalPages > 1"
             class="pager-container">
            <tk-bo-pagination
                [currentPage]="currentPage"
                [pageSize]="pageSize"
                [totalItems]="totalItems"
                [totalPages]="totalPages"
                (paginationUpdate)="paginationUpdate.emit($event)">
            </tk-bo-pagination>
        </div>

        <div *ngIf="appointments.items?.length >= 1"
             class="table-container">
            <table>
                <thead>
                <tr>
                    <th>User</th>
                    <th>Berater</th>
                    <th>Termin</th>
                    <th>Status</th>
                    <th>Programm</th>
                </tr>
                </thead>
                <tbody>

                <tr *ngFor="let item of appointments.items">
                    <td>{{item.externalId}}</td>
                    <td>{{item.consultantFullname || item.consultantUsername}}</td>
                    <td>{{util.formatDisplayedDateAndTime(item.dateTime)}}</td>
                    <td>{{appointment.parseAppointmentState(item.status)}}</td>
                    <td>{{constants[item.programType.toLowerCase()].title}} ({{getPlatform(item)}})</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="appointments.items?.length < 1">
            <p>{{noItemsText}}</p>
        </div>
    </div>
</div>
