<h2 *ngIf="conversationGuide[programType]">Gesprächsleitfaden</h2>
<div *ngIf="!notesDisabled && conversationGuide[programType]"
     class="conversation-guide-container spacer--top">
    <div class="conversation-guide">
        <div *ngFor="let category of conversationGuide[programType].content"
             class="category">
            <button class="category-button"
                    (click)="toggleExpanded($event, category)">
                {{category.title}}

                <span class="arrow"
                      [class.arrow--reverse]="category.expanded">
                    <svg  class="arrow-svg"
                          viewBox="0 0 13 24"
                          width="100%"
                          height="100%">
                        <use xlink:href="assets/icons/arrow.svg#icon"></use>
                    </svg>
                </span>
            </button>

            <div class="category-content"
                 [class.jsAccordionItemExpanded]="category.expanded">
                <div class="jsAccordionItemContent">
                    <div *ngFor="let subcategory of category.children"
                         class="subcategory">

                        <button class="subcategory-button"
                                (click)="toggleExpanded($event, subcategory)">
                            {{subcategory.title}}
                            <span class="arrow"
                                  [class.arrow--reverse]="subcategory.expanded">
                                <svg  class="arrow-svg"
                                      viewBox="0 0 13 24"
                                      width="100%"
                                      height="100%">
                                    <use xlink:href="assets/icons/arrow.svg#icon"></use>
                                </svg>
                            </span>
                        </button>

                        <div class="subcategory-content"
                             [class.jsAccordionItemExpanded]="subcategory.expanded">
                            <div class="jsAccordionItemContent">
                                <div *ngFor="let sentence of subcategory.children">
                                    <a class="text-block"
                                       (click)="appendTextblock(sentence.text)">
                                        {{sentence.text}}
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="spacer--top">
    <h2>Notizen</h2>
</div>

<div>
    <textarea [value]="notesString"
              [disabled]="notesDisabled"
              (change)="onChange($event.target.value)">
    </textarea>
</div>
