import { Injectable } from '@angular/core';

import { ToastrService } from 'ngx-toastr';

@Injectable({
    providedIn: 'root'
})
export class ToastService {

    constructor(private readonly toastr: ToastrService) { }

    public showSuccess(text: string, title?: string) {
        this.toastr.success(text, title ? title : undefined);
    }

    public showWarning(text: string, title?: string) {
        this.toastr.warning(text, title ? title : undefined);
    }

    public showError(text: string, title?: string) {
        this.toastr.error(text, title ? title : undefined);
    }

    public showInfo(text: string, title?: string) {
        this.toastr.info(text, title ? title : undefined);
    }
}
