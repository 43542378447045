import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {AppointmentStatusInterface, AppointmentStatusTypeInterface, Tenant} from '../../../../../interfaces/appointment.interface';
import {UtilitiesService} from '../../../../../services/global/utilities.service';
import {AppointmentService} from '../../../../../services/consultant-ui/appointment.service';
import {ConsultantUiConstants} from '../../../consultant-ui.constants';

@Component({
    selector: 'tk-bo-appointment-status-table',
    templateUrl: './appointment-status-table.component.html',
    styleUrls: ['./appointment-status-table.component.scss']
})
export class AppointmentStatusTableComponent implements OnInit {

    @Input() appointments: AppointmentStatusTypeInterface;
    @Input() noItemsText: string;
    @Input() pageSize: number;
    @Input() title: string;

    @Output() paginationUpdate: EventEmitter<any> = new EventEmitter();

    public constants = ConsultantUiConstants.program;
    public currentPage: number;
    public totalItems: number;
    public totalPages: number;

    constructor(public readonly util: UtilitiesService,
                public readonly appointment: AppointmentService) {
    }

    ngOnInit() {
        this.totalItems = this.appointments.page.totalItems;
        this.currentPage = this.appointments.page.currentPage + 1;
        this.totalPages = this.appointments.page.totalPages;
    }

    public getPlatform(item: AppointmentStatusInterface): string {
        return item.tenant === Tenant.TK_GESUNDHEITSCOACH_WEB ? 'Web' : 'App';
    }

}
