import {Component, Input, OnInit} from '@angular/core';
import {SelectOptionInterface} from '../../../../interfaces/selectOption.interface';
import {AppointmentApiService} from '../../../../services/consultant-ui/appointment-api.service';
import {UserService} from '../../../../services/global/user.service';
import {UtilitiesService} from '../../../../services/global/utilities.service';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {SpinnerService} from '../../../../services/global/spinner.service';
import {SupervisorAppointmentApiService} from '../../../../services/consultant-ui/supervisor-appointment-api.service';
import {firstValueFrom} from 'rxjs';
interface CombinedSlots {
    slots: any[];
    shiftTimeSlots: any[];
}
@Component({
    selector: 'tk-bo-appointment-consultant-availability-table',
    templateUrl: './appointment-consultant-availability-table.component.html',
    styleUrls: ['./appointment-consultant-availability-table.component.scss']
})
export class AppointmentConsultantAvailabilityTableComponent implements OnInit {

    @Input() set consultants(value: SelectOptionInterface[]) {
        this._consultants = value;
        if (this._date && value) {
            this.collectConsultantAvailabilities(this._consultants);
        }
    }

    @Input() set date(value: Date) {
        this._date = value;
        if (this._consultants && value) {
            this.collectConsultantAvailabilities(this._consultants);
        }
    }

    public _date: Date;
    public table: any[];
    public timeSlots: CombinedSlots;

    private _consultants: SelectOptionInterface[];

    constructor(public readonly utilitiesService: UtilitiesService,
                private readonly appointmentApiService: AppointmentApiService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly userService: UserService,
                private readonly spinnerService: SpinnerService) {
    }

    ngOnInit() {
        firstValueFrom(this.appointmentApiService.getSlots(ConsultantUiConstants.program[this.userService.getProgramName().toLowerCase()]))
            .then((slots: CombinedSlots) => {
                this.timeSlots = slots;
            });
    }

    public getTableRow(row: any): any[] {
        const returnRow = [];

        for (const time of this.timeSlots.slots) {
            let state = ConsultantUiConstants.timeSlotState.not_available.state;
            let availabilityProgramType = '';

            for (const availability of row.availabilities) {
                if (UtilitiesService.extractTimeOfDate(availability.dateTime) === time) {
                    state = availability.state.toLowerCase();

                    if (availability.programType) {
                        availabilityProgramType = availability.programType;
                    }
                    break;
                }
            }

            returnRow.push({
                dateTime: time,
                programType: availabilityProgramType,
                programTypeShorthand: availabilityProgramType ? ConsultantUiConstants.program[availabilityProgramType.toLowerCase()].shorthand_name : '',
                state: state
            });
        }

        return returnRow;
    }

    private async collectConsultantAvailabilities(consultants: SelectOptionInterface[]): Promise<void> {
        this.table = [];
        this.spinnerService.show();
        for (const consultant of consultants) {
            this.spinnerService.onRequestStart();
            if (this.userService.isSupervisor()) {

                firstValueFrom(this.supervisorAppointmentApiService.getConsultantAvailabilityByDate(consultant.value, UtilitiesService.formatDateForRequest(this._date)))
                    .then((response: { slots: any[] }) => {
                        this.table.push({
                            name: consultant.label,
                            value: consultant.value,
                            availabilities: response.slots.sort(UtilitiesService.dateTimeSort)
                        });
                    }).finally(() => {
                    this.spinnerService.onRequestEnd();
                });
            } else {

                firstValueFrom(this.appointmentApiService.getConsultantAvailabilityByDate(consultant.value, UtilitiesService.formatDateForRequest(this._date)))
                    .then((availabilities: any) => {
                        this.table.push({
                            name: consultant.label,
                            value: consultant.value,
                            availabilities: availabilities.sort(UtilitiesService.dateTimeSort)
                        });
                    }).finally(() => {
                        this.spinnerService.onRequestEnd();
                    });
            }
        }
    }
}
