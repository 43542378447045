import {Component, OnDestroy, OnInit} from '@angular/core';

import {AppointmentApiService} from '../../../../services/consultant-ui/appointment-api.service';
import {UtilitiesService} from '../../../../services/global/utilities.service';
import {SupervisorAppointmentApiService} from '../../../../services/consultant-ui/supervisor-appointment-api.service';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {
    EmployeeAvailabilityArrayInterface,
    EmployeeAvailabilityInterface, EmployeeInterface,
    SelectedEmployeeTimeslot
} from '../../../../interfaces/employee.interface';
import {ConsultantSelectOptionInterface, ConsultantsInterface, SelectOptionInterface} from '../../../../interfaces/selectOption.interface';
import {CalendarLegendInterface} from '../../../../components/ui-components/calendar/calendarLegend.interface';
import {AssignmentInterface, TimeslotsForRequestInterface} from '../../../../interfaces/appointment.interface';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {UserSearchModalComponent} from '../../../../components/modals/user-search-modal/user-search-modal.component';
import {UserService} from '../../../../services/global/user.service';
import {ToastService} from '../../../../services/global/toast.service';
import {ModalConstants} from '../../../../constants/modal.constants';
import {TimeslotUpdateService} from '../../../../services/consultant-ui/timeslotUpdate.service';
import {firstValueFrom, lastValueFrom, Subscription} from 'rxjs';
import {AppointmentService} from '../../../../services/consultant-ui/appointment.service';
import {CancelSlotsUpdateService} from '../../../../services/consultant-ui/cancelSlotUpdate.service';
import {SpinnerService} from '../../../../services/global/spinner.service';
import * as moment from 'moment';
import {SessionStorageService} from '../../../../services/global/session-storage.service';
import { CalendarService } from 'src/app/shared/services/calendar.service';
import {AssignmentsUpdateService} from '../../../../services/global/assignments-update.service';

@Component({
    selector: 'tk-bo-appointment-management',
    templateUrl: './appointment-management.component.html',
    styleUrls: ['./appointment-management.component.scss']
})
export class AppointmentManagementComponent implements OnInit, OnDestroy {

    public calendar: any[];
    public calendarLegend: CalendarLegendInterface[] = [{
        label: ConsultantUiConstants.timeSlotState.available.label,
        cssClass: ConsultantUiConstants.timeSlotState.available.state
    }, {
        label: ConsultantUiConstants.timeSlotState.reserved.label,
        cssClass: ConsultantUiConstants.timeSlotState.reserved.state
    }, {
        label: ConsultantUiConstants.timeSlotState.not_available.label,
        cssClass: ConsultantUiConstants.timeSlotState.not_available.state
    }];
    public consultants: ConsultantSelectOptionInterface[] = [] as ConsultantSelectOptionInterface[];
    public consultantOneOptions: SelectOptionInterface[] = [] as SelectOptionInterface[];
    public consultantTwoOptions: SelectOptionInterface[] = [] as SelectOptionInterface[];
    public currentDate: Date;
    public employeeOne: EmployeeAvailabilityArrayInterface = {} as EmployeeAvailabilityArrayInterface;
    public employeeTwo: EmployeeAvailabilityArrayInterface = {} as EmployeeAvailabilityArrayInterface;
    public isRequestBusy = false;
    public programConstants = ConsultantUiConstants.program;

    public slotToBook: any = {};
    public slotsToCancel: TimeslotsForRequestInterface[] = [] as TimeslotsForRequestInterface[]; // available slots of left column
    public slotsToConfirm: TimeslotsForRequestInterface[] = [] as TimeslotsForRequestInterface[];
    public slotsToMakeAvailable: TimeslotsForRequestInterface[] = [] as TimeslotsForRequestInterface[]; // not_available slots of left column
    public slotsToReassign: TimeslotsForRequestInterface[] = [] as TimeslotsForRequestInterface[];

    private cancelSlotsSubscription: Subscription;
    private currentUser: EmployeeInterface;
    private modalOptions: NgbModalOptions = ModalConstants.modalOptions;
    private selectedEmployeeNameOne = '';
    private selectedEmployeeNameTwo = '';
    private spinnerSubscription: Subscription;
    private timeSlots: string[];
    private timeslotUpdateSubscription: Subscription;

    constructor(private readonly appointmentApiService: AppointmentApiService,
                private readonly appointmentService: AppointmentService,
                private readonly assignmentsUpdateService: AssignmentsUpdateService,
                private readonly calendarService: CalendarService,
                private readonly cancelSlotsUpdateService: CancelSlotsUpdateService,
                private readonly modal: NgbModal,
                private readonly sessionStorageService: SessionStorageService,
                private readonly spinnerService: SpinnerService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly timeslotUpdateService: TimeslotUpdateService,
                private readonly toast: ToastService,
                public readonly userService: UserService,
                public readonly util: UtilitiesService) {

        this.currentDate = this.calendarService.getCurrentDate();
    }

    ngOnInit() {
        this.spinnerSubscription = this.spinnerService.spinnerState$.subscribe({
            next: (isBusy) => {
                this.isRequestBusy = isBusy;
            }
        });

        this.getCalendarForSelectedDate();
        this.initTimeslotsUpdateSubscription();
        this.getAndProcessConsultants();

        if (!this.userService.isSupervisor()) {
            this.assignmentsUpdateService.initAssignmentUpdatesInterval();
            this.initUnconfirmedAssignments();
        }

    }

    ngOnDestroy() {
        this.timeslotUpdateSubscription.unsubscribe();
        this.spinnerSubscription.unsubscribe();
    }

    public get username(): string {
        return this.userService.getConsultantUsername()['fullname'];
    }

    public navigateDays(action: string): void {
        const oldDate = this.currentDate;
        let date;

        if (action === 'next') {
            date = UtilitiesService.getNextWorkDay(oldDate);
        } else if (action === 'prev') {
            date = UtilitiesService.getPreviousWorkDay(oldDate);
        }

        this.processDateUpdateAndUpdateEmployeeAvailabilities(date);
    }

    public isProgramTypeAvailable(availableProgramTypes: string[], programType): boolean {
        return availableProgramTypes?.indexOf(programType) !== -1;
    }

    public reassignSlots(): void {

        const uuids = this.slotsToReassign.map(slot => slot.uuid);

        const subscription = this.userService.isSupervisor() ?
            this.supervisorAppointmentApiService.postReassignAppointmentsToUser(uuids, this.employeeTwo.value)
            : this.appointmentApiService.postReassignAppointmentsToUser(uuids, this.employeeTwo.value);

        this.spinnerService.onRequestStart();
        lastValueFrom(subscription)
            .then(() => {
                this.updateSlotColumn();
            })
            .catch(error => {
                console.log('postReassignAppointmentsToUser error: ', error);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    public bookSlotsWithUser(): void {
        const modalOptions = Object.assign(this.modalOptions, {
            size: 'lg'
        });

        const modalRef = this.modal.open(UserSearchModalComponent, modalOptions);
        modalRef.componentInstance.timeSlotId = this.slotToBook.consultantUsername;
        modalRef.componentInstance.timeSlot = this.slotToBook;
        modalRef.componentInstance.time = this.slotToBook.dateTime;
        modalRef.componentInstance.date = this.currentDate;
        modalRef.componentInstance.consultantUsername = this.employeeOne.value;
        modalRef.componentInstance.availableProgramTypes = this.employeeOne.programTypes;
    }

    public makeSlotsAvailable(): void {
        const subscription = this.userService.isSupervisor() ?
            this.supervisorAppointmentApiService.postCreateAppointmentForUser(
                this.employeeOne.value,
                this.slotsToMakeAvailable,
                this.currentDate
            )
            : this.appointmentApiService.postCreateAppointment(this.slotsToMakeAvailable, this.currentDate);

        lastValueFrom(subscription)
            .then((response: { availabilities: TimeslotsForRequestInterface[] }) => {
                this.employeeOne.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(
                    response.availabilities,
                    this.employeeOne.availabilities,
                    ConsultantUiConstants.timeSlotState.available.state);

                this.updateEmployeeAvailabilities(this.employeeOne.value, this.employeeOne.availabilities);
                this.showSuccessToast();
                this.getCalendarForSelectedDate();
            })
            .catch((err) => {
                console.log('makeSlotsAvailable error', err);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });

    }

    public confirmAssignments(): void {
        const appointmentIds = UtilitiesService.extractIds(this.slotsToConfirm);
        this.spinnerService.onRequestStart();
        lastValueFrom(this.appointmentApiService.confirmAssignments(appointmentIds))
            .then(() => {
                this.employeeOne.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(this.slotsToConfirm,
                    this.employeeOne.availabilities,
                    ConsultantUiConstants.timeSlotState.reserved.state);

                // substract the confirmed assignments from updatesCount
                this.appointmentService.decrementAssignedAppointmentCount(appointmentIds.length);

                this.updateEmployeeAvailabilities(this.employeeOne.value, this.employeeOne.availabilities);
                this.toast.showSuccess('Neue Termine bestätigt!');
                this.getCalendarForSelectedDate();
            })
            .catch((err) => {
                console.log('confirmSlots error', err);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    public rejectAssignments(): void {
        const appointmentIds = UtilitiesService.extractIds(this.slotsToConfirm);
        this.spinnerService.onRequestStart();
        lastValueFrom(this.appointmentApiService.rejectAssignments(appointmentIds))
            .then(() => {
                this.employeeOne.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(this.slotsToConfirm,
                    this.employeeOne.availabilities,
                    ConsultantUiConstants.timeSlotState.not_available.state);

                // substract the confirmed assignments from updatesCount
                this.appointmentService.decrementAssignedAppointmentCount(appointmentIds.length);

                this.updateEmployeeAvailabilities(this.employeeOne.value, this.employeeOne.availabilities);
                this.toast.showSuccess('Terminzuweisung wurde abgelehnt!');
                this.getCalendarForSelectedDate();
            })
            .catch((err) => {
                console.log('confirmSlots error', err);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    public processDateUpdateAndUpdateEmployeeAvailabilities(date: Date): void {
        const previousDate = this.currentDate;
        this.currentDate = date;

        this.updateEmployees(date);

        if (!UtilitiesService.isSameMonth(previousDate, date)) {
            this.getCalendarForSelectedDate();
        }
    }

    public updateEmployeeOne(username): void {
        this.selectedEmployeeNameOne = username;
        this.generateEmployeeColumn(
            this.findConsultantByUsername(this.consultants, username), UtilitiesService.formatDateForRequest(this.currentDate), 'ONE'
        );
        this.updateEmployeeSelectOptions('TWO', this.selectedEmployeeNameTwo);
    }

    public updateEmployeeTwo(username): void {
        this.selectedEmployeeNameTwo = username;
        this.generateEmployeeColumn(
            this.findConsultantByUsername(this.consultants, username), UtilitiesService.formatDateForRequest(this.currentDate), 'TWO'
        );
        this.updateEmployeeSelectOptions('ONE', this.selectedEmployeeNameOne);
    }

    public updateSelectedSlots(slots: SelectedEmployeeTimeslot[]): void {
        this.processEmployeeOne(slots);
    }

    public cancelSlots(): void {
        this.appointmentService.carefullyCancelSlots(this.slotsToCancel, this.currentDate, this.employeeOne.value);

        this.cancelSlotsSubscription = this.cancelSlotsUpdateService.completedCancelSlotsUpdate$.subscribe(
            () => {
                this.handleCancelledSlots();
            }
        );
    }

    private initUnconfirmedAssignments(): void {
        firstValueFrom(this.appointmentApiService.getAssignments())
            .then((response: { assignedAppointments: AssignmentInterface[] }) => {
                this.sessionStorageService.save(ConsultantUiConstants.sessionStorage.assignments, response.assignedAppointments.length);
            });
    }

    private updateSlotColumn(): void {
        this.employeeOne.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(
            this.slotsToReassign,
            this.employeeOne.availabilities,
            ConsultantUiConstants.timeSlotState.not_available.state
        );
        this.employeeTwo.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(
            this.slotsToReassign,
            this.employeeTwo.availabilities,
            ConsultantUiConstants.timeSlotState.not_confirmed.state
        );

        this.updateEmployeeAvailabilities(this.employeeOne.value, this.employeeOne.availabilities);
        this.updateEmployeeAvailabilities(this.employeeTwo.value, this.employeeTwo.availabilities);
        this.showSuccessToast();
        this.getCalendarForSelectedDate();
    }

    private getCalendarForSelectedDate(): void {
        this.getCalendar(UtilitiesService.formatDateForRequest(this.currentDate));
    }

    private getCalendar(date: string): void {
        this.spinnerService.onRequestStart();
        lastValueFrom(this.appointmentApiService.getCalendar(date))
            .then((response: { calendarDays: string[] }) => {
                this.calendar = response.calendarDays;
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private getAndProcessConsultants(): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.appointmentApiService.getConsultants())
            .then(async (response: ConsultantsInterface) => {
                for (const consultant of response.consultants) {
                    this.consultants.push({
                        label: consultant.fullname,
                        value: consultant.username,
                        selected: undefined,
                        disabled: undefined,
                        programTypes: consultant.programTypes,
                        firstContact: consultant.firstContact
                    });
                }

                this.currentUser = await this.getConsultantInfo();

                if (this.userService.isSupervisor()) {
                    // select two employees for supervisor role
                    this.employeeOne = {
                        label: this.consultants[0].label,
                        value: this.consultants[0].value,
                        availabilities: undefined,
                        programTypes: this.consultants[0].programTypes,
                        firstContact: this.consultants[0].firstContact
                    };
                    const firstAvailableConsultantColTwo = this.consultants.find(consultant => consultant.value !== this.employeeOne.value);

                    this.employeeTwo = {
                        label: firstAvailableConsultantColTwo.label,
                        value: firstAvailableConsultantColTwo.value,
                        availabilities: undefined,
                        programTypes: firstAvailableConsultantColTwo.programTypes,
                        firstContact: firstAvailableConsultantColTwo.firstContact
                    };
                    this.selectedEmployeeNameOne = this.consultants[0].value;
                    this.selectedEmployeeNameTwo = firstAvailableConsultantColTwo.value;
                } else {
                    const consultantUsername = this.userService.getConsultantUsername();

                    this.employeeOne = {
                        label: consultantUsername.fullname,
                        value: consultantUsername.username,
                        availabilities: undefined,
                        programTypes: this.currentUser.programTypes,
                        firstContact: this.currentUser.firstContact
                    };

                    const firstAvailableConsultantColTwo = this.consultants.find(consultant => consultant.value !== this.employeeOne.value);

                    this.employeeTwo = {
                        label: firstAvailableConsultantColTwo.label,
                        value: firstAvailableConsultantColTwo.value,
                        availabilities: undefined,
                        programTypes: firstAvailableConsultantColTwo.programTypes,
                        firstContact: firstAvailableConsultantColTwo.firstContact
                    };

                    this.selectedEmployeeNameTwo = firstAvailableConsultantColTwo.value;
                }

                // select first consultant and get availabilities
                this.updateEmployees(this.currentDate);
            })
            .catch((err) => {
                console.log('getAndProcessConsultants error', err);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private initTimeslotsUpdateSubscription() {
        this.timeslotUpdateSubscription = this.timeslotUpdateService.timeslotUpdateState$.subscribe(
            (timeslotUpdate: { state: boolean, columnNumber: string, employeeName: string, date: string }) => {

                if (timeslotUpdate.state) {
                    this.resetEmployeeColumn(timeslotUpdate.columnNumber);
                    this.updateEmployeeColumnTimeslots(
                        timeslotUpdate.columnNumber,
                        this.findConsultantByUsername(this.consultants, timeslotUpdate.employeeName),
                        timeslotUpdate.date
                    );
                    this.getCalendarForSelectedDate();
                }
            });
    }

    private generateEmployeeColumn(employee: SelectOptionInterface, date: string, columnNumber: string): void {
        this.resetEmployeeColumn(columnNumber);
        this.updateEmployeeSelectOptions(columnNumber, employee.value);
        this.updateEmployeeColumnTimeslots(columnNumber, employee, date);

    }

    private resetEmployeeColumn(columnNumber: string): void {
        if (columnNumber === 'ONE') {
            this.employeeOne.availabilities = undefined;
        } else {
            this.employeeTwo.availabilities = undefined;
        }
    }

    private updateEmployeeSelectOptions(columnNumber: string, employeeName: string): void {

        if (columnNumber === 'ONE' && this.consultantOneOptions.length) {
            this.consultantOneOptions = [];
        }
        if (columnNumber === 'TWO' && this.consultantTwoOptions.length) {
            this.consultantTwoOptions = [];
        }

        for (const consultant of this.consultants) {
            if (columnNumber === 'ONE') {
                this.consultantOneOptions.push({
                    label: consultant.label,
                    value: consultant.value,
                    selected: columnNumber === 'ONE' ? consultant.value === employeeName : consultant.value === this.selectedEmployeeNameOne,
                    disabled: consultant.value === this.selectedEmployeeNameTwo && this.consultants.length > 2,
                    programTypes: consultant.programTypes,
                    firstContact: consultant.firstContact
                });

            } else {
                this.consultantTwoOptions.push({
                    label: consultant.label,
                    value: consultant.value,
                    selected: columnNumber !== 'ONE' ? consultant.value === employeeName : consultant.value === this.selectedEmployeeNameTwo,
                    disabled: consultant.value === this.currentUser.username && this.consultants.length > 2,
                    programTypes: consultant.programTypes,
                    firstContact: consultant.firstContact
                });
            }
        }
    }

    private updateAvailabilities(columnNumber, employee, availabilities): void {
        this.spinnerService.onRequestStart();
        availabilities = availabilities.sort(UtilitiesService.dateTimeSort);

        firstValueFrom(this.appointmentApiService.getSlots(ConsultantUiConstants.program[this.userService.getProgramName().toLowerCase()]))
            .then((timeSlots: { slots: string[], shiftTimeSlots: string[] }) => {
                    this.timeSlots = timeSlots.slots;

                    const availabilityColumn = AppointmentService.fillEmployeeColumn(this.timeSlots, availabilities);

                    if (columnNumber === 'ONE') {
                        this.employeeOne = {
                            label: employee.label,
                            value: employee.value,
                            availabilities: availabilityColumn,
                            programTypes: employee.programTypes,
                            firstContact: employee.firstContact
                        };
                    } else {
                        this.employeeTwo = {
                            label: employee.label,
                            value: employee.value,
                            availabilities: availabilityColumn,
                            programTypes: employee.programTypes,
                            firstContact: employee.firstContact
                        };
                    }
                }).finally(() => {
                    this.spinnerService.onRequestEnd();
                });
    }

    private updateEmployeeColumnTimeslots(columnNumber: string, employee: SelectOptionInterface, date: string): void {
        this.spinnerService.onRequestStart();

        const subscription = this.userService.isSupervisor() ?
            this.supervisorAppointmentApiService.getConsultantAvailabilityByDate(employee.value, date)
            : this.appointmentApiService.getConsultantAvailabilityByDate(employee.value, date);

        lastValueFrom(subscription)
            .then((availabilities: { slots: any[] }) => {
                this.updateAvailabilities(columnNumber, employee, availabilities.slots);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private updateEmployees(date: Date): void {
        const formattedDate = UtilitiesService.formatDateForRequest(date);

        this.generateEmployeeColumn(this.employeeOne, formattedDate, 'ONE');
        this.generateEmployeeColumn(this.employeeTwo, formattedDate, 'TWO');
    }

    private processEmployeeOne(slots: SelectedEmployeeTimeslot[]) {
        const sortedSelectedTimeslots = AppointmentService.processSelectedTimeslots(slots, this.currentDate, this.userService.isSupervisor(), this.employeeTwo.value);

        this.slotsToCancel = sortedSelectedTimeslots.slotsToCancel;
        this.slotsToMakeAvailable = sortedSelectedTimeslots.slotsToMakeAvailable;
        this.slotsToReassign = sortedSelectedTimeslots.slotsToReassign;
        this.slotToBook = sortedSelectedTimeslots.slotToBook;
        this.slotToBook.isBookable = UtilitiesService.isFutureDate(this.slotToBook.day);

        this.slotsToConfirm = sortedSelectedTimeslots.slotsToConfirm;
    }

    private findConsultantByUsername(consultants: ConsultantSelectOptionInterface[], username: string): ConsultantSelectOptionInterface {
        return consultants.find(obj => obj.value === username);
    }

    private handleCancelledSlots(): void {
        this.employeeOne.availabilities = AppointmentService.remapUpdatedEmployeeAvailabilities(
            this.slotsToCancel,
            this.employeeOne.availabilities,
            ConsultantUiConstants.timeSlotState.not_available.state
        );

        this.updateEmployeeAvailabilities(this.employeeOne.value, this.employeeOne.availabilities);
        this.showSuccessToast();
        this.getCalendarForSelectedDate();

        this.cancelSlotsSubscription.unsubscribe();
    }


    private updateEmployeeAvailabilities(name: string, availabilities: EmployeeAvailabilityInterface[]): void {
        if (this.employeeOne.value === name) {
            const allAvailabilitiesOne = this.employeeOne.availabilities;
            this.employeeOne.availabilities = undefined;

            setTimeout(() => {
                this.employeeOne.availabilities = AppointmentService.combineAvailabilities(allAvailabilitiesOne, availabilities);
            }, 250);
        } else if (this.employeeTwo.value === name) {
            const allAvailabilitiesTwo = this.employeeTwo.availabilities;
            this.employeeTwo.availabilities = undefined;

            setTimeout(() => {
                this.employeeTwo.availabilities = AppointmentService.combineAvailabilities(allAvailabilitiesTwo, availabilities);
            }, 250);
        }
    }

    private showSuccessToast(): void {
        this.toast.showSuccess('Neue Termine gespeichert!');
    }

    private async getConsultantInfo(): Promise<any> {
        let info: any = this.userService.getConsultantInfo();

        if (!info) {
            info = await lastValueFrom(this.appointmentApiService.getConsultantInfo());
            this.userService.setConsultantInfo(info);
        }

        return info;
    }
}
