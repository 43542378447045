import {Component} from '@angular/core';
import {RoutesConstants} from '../../../constants/routes.constants';
import {AuthenticationService} from '../../../shared/services/authentication.service';
import {UserService} from '../../../services/global/user.service';

@Component({
    selector: 'tk-bo-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent {

    public navigationEntries = {
        consultantInterface: [
            RoutesConstants.appointments
        ]
    };

    constructor(public readonly userService: UserService,
        public readonly auth: AuthenticationService) {
    }

    public isIE(): boolean {
        return window.navigator.userAgent.indexOf('Trident') > -1;
    }

}
