export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE',
    DIVERSE = 'DIVERSE'
}

export enum AppointmentResult {
    REACHED = 'REACHED',
    NOT_REACHED = 'NOT_REACHED'
}

export enum Tenant {
    TK_GESUNDHEITSCOACH_WEB = 'TK_GESUNDHEITSCOACH_WEB',
    TK_COACH_APP = 'TK_COACH_APP',
    TK_RAUCHFREI_APP = 'TK_RAUCHFREI_APP'
}

export enum ProgramType {
    ANTISTRESS = 'ANTISTRESS',
    STOP_SMOKING = 'STOP_SMOKING'
}

export enum ProtocolStatus {
    SCHEDULED = 'SCHEDULED',
    REACHED = 'REACHED',
    NOT_REACHED = 'NOT_REACHED',
    CANCELED_BY_USER = 'CANCELED_BY_USER',
    CANCELED_BY_CONSULTANT = 'CANCELED_BY_CONSULTANT'
}

export interface BookAppointmentRequestInterface {
    consultantUsername?: string;
    day: string;
    email?: string;
    externalId?: string;
    firstname: string;
    gender: Gender;
    lastname: string;
    phoneNumber: string;
    tenant: Tenant;
    time: string;
    programType: string;
}

export interface WeekAvailabilityInterface {
    checked?: boolean;
    dates: WeekAvailabilityDatesInterface[];
    duration?: number;
    shiftEnd: string;
    shiftStart: string;
    state: string;
}

export interface WeekAvailabilityRequestInterface {
    checked?: boolean;
    daysOfWeek: string[];
    duration?: number;
    shiftEnd: string;
    shiftStart: string;
    state: string;
}

export interface WeekAvailabilityDatesInterface {
    date: string;
    state: string;
}

export interface TimeslotsForRequestInterface {
    dateTime: string;
    employeeOneUsername?: string;
    employeeOneState?: string;
    employeeTwoUsername?: string;
    employeeTwoState?: string;
    firstConsultation?: boolean;
    selected?: boolean;
    state?: string;
    uuid?: string;
}

export interface CombinedAppointmentsStatusInterface {
    booked: AppointmentStatusTypeInterface;
    canceledByConsultant: AppointmentStatusTypeInterface;
    canceledByUser: AppointmentStatusTypeInterface;
    reached: AppointmentStatusTypeInterface;
    reachedStatistics?: any[];
    reserved?: AppointmentStatusTypeInterface;
}

export interface MonthlyStatisticInterface {
    antistress: number;
    month: string;
    stopSmoking: number;
}

export interface AppointmentStatusTypeInterface {
    items: AppointmentStatusInterface[];
    page: {
        currentPage: number;
        totalItems: number;
        totalPages: number;
    };
}

export interface AppointmentStatusInterface {
    consultantFullname: string;
    consultantUsername: string;
    dateTime: string;
    externalId: number;
    id?: number;
    programType: string;
    status: string;
    tenant: Tenant;
}

export interface TimeslotUpdate {
    columnNumber: string;
    date: string;
    employeeName: string;
    state: boolean;
}

export interface AssignmentInterface {
    dateTime: string;
    formerAssignedConsultant: string;
    userId: number;
    uuid: string;
}

export interface MyLastAppointments {
    externalId: string;
    tenant: Tenant;
    firstName: string;
    isBookable: boolean;
    lastName: string;
    nextAppointmentDate: string;
    phoneNumber: string;
    programType: ProgramType;
}

export interface DateTime {
    date: string;
    time: string;
}
