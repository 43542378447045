import { Injectable } from '@angular/core';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';

@Injectable({
    providedIn: 'root'
})
export class SessionStorageService {

    private readonly base = 'tk-consultants-';

    constructor() { }

    public static resetSessionStorage() {
        sessionStorage.clear();
    }

    public static removeSessionStorage() {
        sessionStorage.removeItem(ConsultantUiConstants.sessionStorage.assignments);
        sessionStorage.removeItem(ConsultantUiConstants.sessionStorage.roles);
        sessionStorage.removeItem(ConsultantUiConstants.sessionStorage.timeSlots);
        sessionStorage.removeItem(ConsultantUiConstants.sessionStorage.username);
        // exclude note in case consultant lost session during phone interview notes for interview can be restored from sessionStorage
    }

    public save(name: string, value: any) {
        sessionStorage.setItem(this.base + name, JSON.stringify(value));
    }

    public get(name: string): any {
        return JSON.parse(sessionStorage.getItem(this.base + name));
    }

    public delete(name: string): any {
        sessionStorage.removeItem(this.base + name);
    }

}
