import {Component} from '@angular/core';
import {AuthenticationService} from '../../shared/services/authentication.service';
import {OAuthService} from 'angular-oauth2-oidc';

@Component({
    selector: 'tk-bo-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent {

    public hasValidAccessToken = false;

    constructor(private readonly authenticationService: AuthenticationService,
                private readonly oauthService: OAuthService) {
        this.hasValidAccessToken = this.oauthService.hasValidAccessToken();
    }

    public login(): void {
        this.authenticationService.authenticate();
    }

}
