import {Component, Input} from '@angular/core';
import {TabsEntryInterface} from './tabs-entry.interface';

@Component({
    selector: 'tk-bo-tabs',
    templateUrl: './tabs.component.html',
    styleUrls: ['./tabs.component.scss']
})
export class TabsComponent{

    @Input() entries: TabsEntryInterface[];
    @Input() hasMarginTop: boolean;
    @Input() hasNoPadding?: boolean;
    @Input() queryParam: Object;

    constructor() { }

}
