import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {firstValueFrom, lastValueFrom, Subscription} from 'rxjs';
import {debounceTime} from 'rxjs/operators';
import {AccountService} from '../../../services/global/account.service';
import {
    ConsultationAccountSearchResultItemInterface
} from '../../../interfaces/account.interface';
import {UtilitiesService} from '../../../services/global/utilities.service';
import {UserService} from '../../../services/global/user.service';
import {AppointmentApiService} from '../../../services/consultant-ui/appointment-api.service';
import {TimeslotUpdateService} from '../../../services/consultant-ui/timeslotUpdate.service';
import {SelectedEmployeeTimeslot} from '../../../interfaces/employee.interface';
import {SupervisorAppointmentApiService} from '../../../services/consultant-ui/supervisor-appointment-api.service';
import {MyLastAppointments, ProgramType, Tenant} from '../../../interfaces/appointment.interface';
import {ConsultantUiConstants} from '../../../modules/consultant-ui/consultant-ui.constants';

@Component({
    selector: 'tk-bo-user-search-modal',
    templateUrl: './user-search-modal.component.html',
    styleUrls: ['./user-search-modal.component.scss']
})
export class UserSearchModalComponent implements OnInit, OnDestroy {

    @Input() availableProgramTypes: ProgramType[];
    @Input() consultantUsername: string;
    @Input() date: Date;
    @Input() time: string;
    @Input() timeSlot: SelectedEmployeeTimeslot;
    @Input() timeSlotId: any;

    public currentPage = 0;
    public formGroup: UntypedFormGroup = new UntypedFormGroup({
        searchTerm: new UntypedFormControl(null)
    });
    public isSuperVisor: boolean;
    public myLastAppointments: MyLastAppointments[];
    public searchHits: ConsultationAccountSearchResultItemInterface[];
    public totalCount: number;
    public totalPages: number;

    private content: any;
    private searchTerm: string;
    private valueChangesSubscription: Subscription;


    constructor(public activeModal: NgbActiveModal,
                public util: UtilitiesService,
                private readonly accountService: AccountService,
                private readonly userService: UserService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly appointmentApiService: AppointmentApiService,
                private readonly timeslotUpdateService: TimeslotUpdateService) {
    }

    ngOnInit() {
        this.content = ConsultantUiConstants.program;
        this.isSuperVisor = this.userService.isSupervisor();

        this.initValueChangeSubscription();

        if (!this.isSuperVisor) {
            this.fetchLastAppointments();
        }
    }

    ngOnDestroy(): void {
        this.valueChangesSubscription.unsubscribe();
    }

    public getProgramTypeLabel(programType: ProgramType): string {
        return this.content[programType.toLowerCase()].title;
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    public async bookAppointmentWithUser(user: ConsultationAccountSearchResultItemInterface): Promise<void> {
        const date = UtilitiesService.getISODateString(this.date);
        const dateTime = UtilitiesService.getTimeZoneDateTime(date, this.timeSlot.dateTime);

        try {
            if (this.isSuperVisor) {
                await lastValueFrom(this.supervisorAppointmentApiService.postBookAppointment(user.externalId, dateTime, user.tenant, user.programType, this.consultantUsername));
            } else {
                await lastValueFrom(this.appointmentApiService.postBookAppointment(user.externalId, dateTime, user.tenant, user.programType));
            }

            this.timeslotUpdateService.update('ONE', this.consultantUsername, date);
            this.closeModal();
        } catch (error) {
            console.log('error', error);
            this.closeModal();
        }
    }

    public async bookAppointmentWithPastUser(externalId: string, tenant: Tenant, programType: ProgramType): Promise<void> {
        const date = UtilitiesService.getISODateString(this.date);
        const dateTime = UtilitiesService.getTimeZoneDateTime(date, this.timeSlot.dateTime);

        try {
            await lastValueFrom(this.appointmentApiService.postBookAppointment(externalId, dateTime, tenant, programType));

            this.timeslotUpdateService.update('ONE', this.consultantUsername, date);
            this.closeModal();
        } catch (error) {
            console.log('error', error);
            this.closeModal();
        }
    }

    public async onPageChanged(page: any): Promise<void> {
        this.currentPage = page.currentPage - 1;
        const searchResult = await firstValueFrom(this.accountService.searchPhoneConsultationUser(this.searchTerm, this.currentPage));
        this.searchHits = searchResult.userSearchDetails;
    }

    private async fetchLastAppointments(): Promise<any> {
        const response = await lastValueFrom(this.accountService.getLastFiveAppointments());
        this.myLastAppointments = response.users;
    }

    private initValueChangeSubscription(): void {
        this.valueChangesSubscription = this.formGroup.controls['searchTerm'].valueChanges
            .pipe(debounceTime(250))
            .subscribe(async (searchTerm: any) => {
                if (searchTerm.length > 1) {
                    this.searchTerm = searchTerm;
                    const searchResult = await lastValueFrom(this.accountService.searchPhoneConsultationUser(searchTerm, 0));
                    this.searchHits = searchResult.userSearchDetails;
                    this.totalPages = searchResult.totalPages;
                    this.totalCount = searchResult.totalCount;
                }

                if (searchTerm.length === 0) {
                    this.searchHits = [];
                    this.totalPages = null;
                    this.totalCount = null;
                }
            });
    }
}
