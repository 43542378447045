import {Injectable} from '@angular/core';
import * as _ from 'lodash';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';
import {
    BookAppointmentRequestInterface,
    DateTime,
    Gender,
    ProgramType,
    Tenant,
    TimeslotsForRequestInterface
} from '../../interfaces/appointment.interface';
import {EmployeeAvailabilityInterface, SelectedEmployeeTimeslot} from '../../interfaces/employee.interface';
import {UtilitiesService} from '../global/utilities.service';
import {AccountConversationProtocolDetailsInterface} from '../../interfaces/account.interface';
import {InterviewProtocolComponent} from '../../components/modals/interview-protocol/interview-protocol.component';
import {AppointmentApiService} from './appointment-api.service';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalConstants} from '../../constants/modal.constants';
import {SessionStorageService} from '../global/session-storage.service';
import {UserService} from '../global/user.service';
import {SupervisorAppointmentApiService} from './supervisor-appointment-api.service';
import {CancelSlotsUpdateService} from './cancelSlotUpdate.service';
import {
    ConfirmReservedSlotsCancellationModalComponent
} from '../../components/modals/confirm-reserved-slots-cancellation-modal/confirm-reserved-slots-cancellation-modal.component';
import {firstValueFrom, lastValueFrom, Subscription} from 'rxjs';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class AppointmentService {

    modalOptions: NgbModalOptions = ModalConstants.modalOptions;

    constructor(private readonly appointmentApiService: AppointmentApiService,
                private readonly util: UtilitiesService,
                public modal: NgbModal,
                private readonly session: SessionStorageService,
                private readonly user: UserService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly cancelSlotsUpdateService: CancelSlotsUpdateService) {
    }

    public static isAvailable(slotState: string): boolean {
        return slotState === ConsultantUiConstants.timeSlotState.available.state;
    }

    public static isNotAvailable(slotState: string): boolean {
        return slotState === ConsultantUiConstants.timeSlotState.not_available.state;
    }

    public static isReserved(slotState: string): boolean {
        return slotState === ConsultantUiConstants.timeSlotState.reserved.state;
    }

    public static isNotConfirmed(slotState: string): boolean {
        return slotState === ConsultantUiConstants.timeSlotState.not_confirmed.state;
    }

    public static isReassignable(slot: SelectedEmployeeTimeslot): boolean {
        let returnVal = false;

        if (AppointmentService.isReserved(slot.employeeOneState)) {
            returnVal = AppointmentService.isAvailable(slot.employeeTwoState)
                && slot.employeeTwoProgramTypes.indexOf(slot.programType) !== -1;
        } else if (slot.employeeOneFirstConsultation) {
            returnVal = slot.employeeTwoFirstContact && AppointmentService.isAvailable(slot.employeeTwoState)
                && slot.employeeTwoProgramTypes.indexOf(slot.programType) !== -1;
        }

        return returnVal;
    }

    public static remapUpdatedEmployeeAvailabilities(updatedSlots: TimeslotsForRequestInterface[],
                                                     employeeAvailabilities: EmployeeAvailabilityInterface[],
                                                     newState: string): EmployeeAvailabilityInterface[] {
        for (const slot of updatedSlots) {
            const slotUpdate = _.find(employeeAvailabilities, ['dateTime', UtilitiesService.extractTimeOfDate(slot.dateTime)]);
            slotUpdate.state = newState;
            slotUpdate.firstConsultation = slot.firstConsultation;
            slotUpdate.uuid = slot.uuid;
        }

        return employeeAvailabilities;
    }

    public static processSelectedTimeslots(slots: SelectedEmployeeTimeslot[], currentDate: Date, isSupervisor: boolean, consultantUserName: string) {
        const slotsToCancel = [];
        const slotsToConfirm = [];
        const slotsToMakeAvailable = [];
        const slotsToReassign = [];
        let slotToBook = {} as SelectedEmployeeTimeslot;

        if (slots.length >= 1) {
            for (const slot of slots) {
                if (UtilitiesService.isNowMinutesBeforeTime(slot.dateTime, 0, currentDate)) {
                    if (AppointmentService.isAvailable(slot.employeeOneState)
                        || AppointmentService.isReserved(slot.employeeOneState) // TODO: this is for debugging
                        || (isSupervisor && AppointmentService.isReserved(slot.employeeOneState))) {
                        slotsToCancel.push({
                            dateTime: slot.dateTime,
                            id: slot.employeeOneUserName,
                            state: slot.employeeOneState,
                            uuid: slot.uuid
                        });
                    }

                    if (AppointmentService.isNotAvailable(slot.employeeOneState)) {
                        slotsToMakeAvailable.push({
                            dateTime: slot.dateTime,
                            id: slot.employeeOneUserName,
                            uuid: slot.uuid
                        });
                    }

                    if (AppointmentService.isReassignable(slot)) {
                        slotsToReassign.push({
                            dateTime: slot.dateTime,
                            id: slot.employeeOneUserName,
                            uuid: slot.uuid
                        });
                    }

                    if (AppointmentService.isNotConfirmed(slot.employeeOneState)) {
                        slotsToConfirm.push({
                            dateTime: slot.dateTime,
                            id: slot.employeeOneUserName,
                            uuid: slot.uuid,
                        });
                    }
                }
            }
        }

        if (slots.length === 1
            && AppointmentService.isAvailable(slots[0].employeeOneState)
            && UtilitiesService.isNowMinutesBeforeTime(slots[0].dateTime, 0, currentDate)) {
            slotToBook = slots[0];
            slotToBook.day = UtilitiesService.getISODateString(currentDate);
        }

        return {
            slotsToCancel: slotsToCancel,
            slotsToMakeAvailable: slotsToMakeAvailable,
            slotsToReassign: slotsToReassign,
            slotToBook: slotToBook,
            slotsToConfirm: slotsToConfirm
        };
    }

    public static combineAvailabilities(allAvailabilities: EmployeeAvailabilityInterface[],
                                        availabilities: EmployeeAvailabilityInterface[]): EmployeeAvailabilityInterface[] {
        const formattedAvailabilities: EmployeeAvailabilityInterface[] = [] as EmployeeAvailabilityInterface[];
        availabilities = availabilities.sort(UtilitiesService.dateTimeSort);

        for (let i = 0; i < availabilities.length; i++) {
            formattedAvailabilities.push({
                uuid: availabilities[i].uuid,
                state: availabilities[i].state.toLowerCase(),
                dateTime: UtilitiesService.extractTimeOfDate(availabilities[i].dateTime),
                programTypes: availabilities[i].programTypes ? availabilities[i].programTypes : [],
                firstConsultation: availabilities[i].firstConsultation
            });

            for (let j = 0; j < allAvailabilities.length; j++) {
                // replace allAvailabilities with availabilities
                if (allAvailabilities[j].dateTime === formattedAvailabilities[i].dateTime) {
                    allAvailabilities[j] = formattedAvailabilities[i];
                    break;
                }
            }
        }

        return allAvailabilities;
    }

    public static fillEmployeeColumn(timeSlots: string[], availabilities: EmployeeAvailabilityInterface[]) {
        const returnVal = [];

        for (let i = 0; i < timeSlots.length; i++) {
            const slot = timeSlots[i];
            const availability = AppointmentService.findAvailabilityByTime(availabilities, slot);

            if (availability) {
                // push every item that is in the array
                returnVal.push({
                    dateTime: UtilitiesService.extractTimeOfDate(availability.dateTime),
                    state: availability.state.toLowerCase(),
                    programType: availability.programType,
                    firstConsultation: availability.firstConsultation,
                    uuid: availability.uuid
                });

                // push unavailable for every timeSlot that does not fit availability
                if (slot !== UtilitiesService.extractTimeOfDate(availability.dateTime)) {
                    returnVal.push({
                        dateTime: slot,
                        state: availability.state.toLowerCase(),
                        programType: availability.programType,
                        firstConsultation: availability.firstConsultation,
                        uuid: availability.uuid,
                    });
                }
            } else {
                returnVal.push({
                    dateTime: slot,
                    state: ConsultantUiConstants.timeSlotState.not_available.state
                });
            }
        }
        return returnVal;
    }

    private static findAvailabilityByTime(availabilities: EmployeeAvailabilityInterface[], dateTime: string) {
        let returnVal;

        for (const availability of availabilities) {
            if (UtilitiesService.extractTimeOfDate(availability.dateTime) === dateTime) {
                returnVal = availability;
                break;
            }
        }

        return returnVal;
    }

    public parseAppointmentState(state: string): string {
        return ConsultantUiConstants.appointmentState[state.toUpperCase()];
    }

    public showInterviewProtocolById(protocolId: string): void {
        lastValueFrom(this.appointmentApiService.getInterviewProtocolById(protocolId))
            .then((response: AccountConversationProtocolDetailsInterface) => {

                const participantName = (response.userFirstName && response.userLastName) ? response.userFirstName + ' ' + response.userLastName : 'keine Angabe';
                const modalRef = this.modal.open(InterviewProtocolComponent, this.modalOptions);
                modalRef.componentInstance.data = {
                    consultant: response.consultantUsername,
                    dateTime: response.dateTime,
                    duration: response.duration,
                    notes: response.notes,
                    result: this.parseAppointmentState(response.result),
                    userExternalId: response.userExternalId,
                    userFirstName: response.userFirstName,
                    userLastName: response.userLastName,
                };
            });
    }

    public incrementAssignedAppointmentCount(summand: number): void {
        const sessionStorageKey = ConsultantUiConstants.sessionStorage.assignments;
        const assignmentAmount = this.session.get(sessionStorageKey);

        this.session.save(sessionStorageKey, assignmentAmount + summand);
    }

    public decrementAssignedAppointmentCount(subtrahend: number): void {
        const sessionStorageKey = ConsultantUiConstants.sessionStorage.assignments;
        const assignmentAmount = this.session.get(sessionStorageKey);

        this.session.save(sessionStorageKey, assignmentAmount - subtrahend);
    }

    public carefullyCancelSlots(slotsToCancel: TimeslotsForRequestInterface[], date: Date, username?: string): void {
        const slotDateTimesToCancel = UtilitiesService.extractDateTimes(slotsToCancel, date);
        const reservedSlotsToCancel = UtilitiesService.filterReservedSlots(slotsToCancel);

        if (reservedSlotsToCancel.length === 0) {
            this.cancelSlots(slotDateTimesToCancel, username);
        } else {
            const modalRef = this.modal.open(ConfirmReservedSlotsCancellationModalComponent, this.modalOptions);

            modalRef.componentInstance.reservedSlotsToCancel = reservedSlotsToCancel;


           firstValueFrom(this.cancelSlotsUpdateService.confirmedCancelSlotsUpdate$)
               .then(() => {
                    this.cancelSlots(slotDateTimesToCancel, username);
                });
        }
    }

    public cancelSlots(dateTimesToCancel: DateTime[], username?: string): void {

        const subscription = this.user.isSupervisor() ?
            this.supervisorAppointmentApiService.deleteAppointments(dateTimesToCancel, username)
            : this.appointmentApiService.deleteAppointments(dateTimesToCancel);

        lastValueFrom(subscription)
            .then(() => {
                this.cancelSlotsUpdateService.emitCompletedSlotsCancellation();
            });
    }
}
