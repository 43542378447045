<label *ngIf="label"
       class="label"
       [for]="inputId">
    {{label}}
</label>

<select class="select"
        [ngClass]="{'select--border': showBorder}"
        [id]="inputId"
        [value]="value"
        (change)="onChange($event.target.value)">
    <option *ngFor="let option of options"
            [attr.label]="option.label"
            [attr.value]="option.value"
            [attr.selected]="option.selected ? '' : null"
            [attr.disabled]="option.disabled ? '' : null">{{option.label}}
    </option>
</select>
