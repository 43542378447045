import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {DayInfo} from './month-calendar/models/day-info';
import {DayOfWeek} from './month-calendar/models/day-of-week';

import {UtilitiesService} from '../../../services/global/utilities.service';
import {CalendarLegendInterface} from './calendarLegend.interface';
import {CalendarService} from '../../../shared/services/calendar.service';

@Component({
    selector: 'tk-bo-calendar',
    templateUrl: './calendar.component.html',
    styleUrls: ['./calendar.component.scss']
})
export class CalendarComponent implements OnInit {

    @Input() availabilityArray: any[];
    @Input() legend: CalendarLegendInterface[];

    @Output() dateChange: EventEmitter<Date> = new EventEmitter();

    public config = {
        dayNames: ['M', 'D', 'M', 'D', 'F', 'S', 'S'],
        firstDayOfWeek: 1,
        growMode: {mode: 'proportional'},
        monthNames: ['Januar', 'Februar', 'März', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Dezember'],
    };

    public newDate: Date;
    public previousDay: number;
    public weekendDaysCounter: number;

    constructor(private readonly calendarService: CalendarService) {

    }

    ngOnInit() {
        this.weekendDaysCounter = 0;
        this.previousDay = 0;
        const persistedDate = this.calendarService.getCurrentDate();

        this.newDate = persistedDate || new Date();
    }

    public getAvailabilityStateCssClass(dayInfo?: DayInfo) {
        if (dayInfo) {
            let stateCssClass;

            // when run multiple times, set back weekendDaysCounter
            if (dayInfo.day > this.previousDay) {
                this.previousDay = dayInfo.day;
            } else {
                this.previousDay = 0;
                this.weekendDaysCounter = 0;
            }

            if (!UtilitiesService.isDateOnWeekend(dayInfo.date)) {
                const availabilityByDay = this.availabilityArray[dayInfo.day - 1];

                if (availabilityByDay.state) {
                    stateCssClass = availabilityByDay.state.toLowerCase();
                }
                if (availabilityByDay.hasReservedAppointments) {
                    stateCssClass += ' has-appointment';
                }

            } else {
                stateCssClass = 'weekend';
            }

            return stateCssClass;
        }
    }

    public dayOfWeekFormatter = (dayOfWeek: DayOfWeek) => {
        return this.config.dayNames[dayOfWeek.valueOf()];
    }

    public setNewDate(date: Date) {
        this.weekendDaysCounter = 0;
        this.previousDay = 0;
        if (UtilitiesService.isSunday(date)) {
            // if sunday, set to monday
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1);
        } else if (UtilitiesService.isSaturday(date)) {
            // if saturday, set to friday
            date = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1);
        }

        this.calendarService.persistDate(date);
        this.dateChange.next(date);
        this.newDate = date;
    }

    public navigateMonths(action): void {
        const day = this.newDate.getDate();
        let year = this.newDate.getFullYear();
        let month = this.newDate.getMonth() + 1;

        if (action === 'next') {
            if (month === 12) {
                month = 1;
                year = year + 1;
            } else {
                month = month + 1;
            }
        } else if (action === 'prev') {
            if (month === 1) {
                month = 12;
                year = year - 1;
            } else {
                month = month - 1;
            }
        }

        const dateString = this.invalidDateCheck(day, month, year);
        this.setNewDate(new Date(dateString));
    }

    private invalidDateCheck(day: number, month: number, year: number): string {
        let dateString = this.constructDateString(day, month, year);

        if (isNaN(new Date(dateString).getTime())) {
            // from month with 31 days to months with 30 days: - 1
            dateString = this.constructDateString(day - 1, month, year);

            if (isNaN(new Date(dateString).getTime())) {
                // from month with 31 days to months with 29 days: - 2
                dateString = this.constructDateString(day - 2, month, year);
            }
        }

        return dateString;
    }

    private constructDateString(day: number, month: number, year: number) {
        return year + '-' + (month < 10 ? '0' : '') + month + '-' + (day < 10 ? '0' : '') + day;
    }

}
