import { Injectable } from '@angular/core';
import {Observable, Subject} from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class SpinnerService {

    private requestsPending = 0;
    private spinnerState: boolean;
    private spinnerStateSubject: Subject<boolean> = new Subject();

    constructor() { }

    public get spinnerState$(): Observable<boolean> {
        return this.spinnerStateSubject.asObservable();
    }

    public triggerSpinnerStateUpdate(): void {
        this.spinnerStateSubject.next(this.spinnerState);
    }

    public show(): void {
        this.spinnerState = true;
        this.triggerSpinnerStateUpdate();
    }

    public hide(whenNoRequestsArePending?: boolean): void {
        if (!whenNoRequestsArePending || (whenNoRequestsArePending && this.requestsPending === 0)) {
            this.spinnerState = false;
            this.triggerSpinnerStateUpdate();
        }
    }

    public incrementPendingRequests(): void {
        this.requestsPending++;
    }

    public decrementPendingRequests(): void {
        this.requestsPending--;
        if (this.requestsPending < 0) {
            this.requestsPending = 0;
        }
    }

    public onRequestStart(): void {
        this.incrementPendingRequests();
        this.show();
    }

    public onRequestEnd(): void {
        this.decrementPendingRequests();
        this.hide(true);
    }
}
