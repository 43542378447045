<div class="row">
    <div class="col-12">
        <h2 class="headline">
            <span *ngIf="appointmentId">
                Termin: {{appointmentId}}
            </span>
            <span *ngIf="!appointmentId">
                Keine Termin-ID!!!
            </span>
        </h2>
    </div>
</div>

<div *ngIf="pageData"
     class="row spacer--top">
    <div class="col-6">
        <h2 class="headline">
            Teilnehmer
        </h2>

        <div class="table-container">
            <table>
                <tbody>
                <tr>
                    <td>ID</td>
                    <td>{{pageData.user.externalId}}</td>
                </tr>
                <tr>
                    <td>Name</td>
                    <td>{{pageData.user.firstName}} {{pageData.user.lastName}}</td>
                </tr>
                <tr>
                    <td>Telefonnummer</td>
                    <td>{{pageData.user.phoneNumber}}</td>
                </tr>
                <tr>
                    <td>Aktueller Termin</td>
                    <td>{{utilitiesService.formatDisplayedDateAndTime(pageData.appointment.dateTime)}}</td>
                </tr>
                </tbody>
            </table>
        </div>

        <div class="spacer--top">
            <tk-bo-conversation-guide *ngIf="showConversationGuide"
                                      [protocol]="pageData.appointment.protocol?.notes"
                                      [notesDisabled]="notesDisabled"
                                      [programType]="pageData.appointment.programType"
                                      (notes)="updateNotes($event)">
            </tk-bo-conversation-guide>
        </div>

        <div>
            <div *ngIf="timerStarted && !pageData?.appointment.protocol?.notes"
                 class="timer headline"
                 [ngClass]="{'too-long' : timeElapsed > timeLimit}">
                <div>
                    <button class="btn btn-primary"
                            (click)="saveProtocol(appointmentId, 'REACHED')">
                        Notizen speichern
                    </button>
                </div>
                <div>
                    <span class="font-weight-bold">
                        Gesprächszeit:
                    </span>
                    <span>
                        {{elapsedTimeString}}
                    </span>
                </div>
            </div>
        </div>

        <ng-container *ngIf="!pageData?.appointment.protocol?.notes">
            <div *ngIf="!timerStarted"
                 class="row spacer--top">
                <div class="col-12">
                    <button class="btn btn-primary"
                            (click)="startTimer()">
                        Teilnehmer erreicht
                    </button>
                </div>

                <div class="col-12">
                    <button class="btn btn-primary"
                            (click)="openConfirmSaveProtocolPopup()">
                        Teilnehmer nicht erreichbar
                    </button>
                </div>
            </div>
        </ng-container>
    </div>

    <div class="col-6">
        <h2 class="headline">
            Coach-Informationen
        </h2>

        <div class="table-container">
            <table>
                <tbody>
                <tr>
                    <td>Programm</td>
                    <td>{{programLabel}}</td>
                </tr>
                <ng-container *ngIf="isCoachWeb">
                    <tr>
                        <td>Start des Programms</td>
                        <td *ngIf="accountData">
                            {{accountData.registrationDate}}
                        </td>
                    </tr>
                    <tr>
                        <td>Ende des Programms</td>
                        <td *ngIf="accountData?.expectedEndDate">
                            {{accountData.expectedEndDate}}
                        </td>
                    </tr>
                </ng-container>
                </tbody>
            </table>
        </div>

        <div *ngIf="isCoachWeb"
             class="spacer--top">
            <tk-bo-stop-smoking-health-objective-details
                *ngIf="pageData.appointment?.programType === programConstants.stop_smoking.programType"
                [stopSmokingData]="pageData.stopSmokingData"
                [user]="{firstName: pageData.user.firstName, lastName: pageData.user.lastName, externalId: pageData.user.externalId}">
            </tk-bo-stop-smoking-health-objective-details>
        </div>

        <div class="spacer--top">
            <tk-bo-anti-stress-activity-summaries
                *ngIf="pageData.activitySummaries && pageData.appointment?.programType === programConstants.antistress.programType"
                [activitySummaries]="pageData.activitySummaries"
                [maxItems]="3"
                [user]="{firstName: pageData.user.firstName, lastName: pageData.user.lastName, externalId: pageData.user.externalId}">
            </tk-bo-anti-stress-activity-summaries>

            <div *ngIf="pageData.activitySummaries?.length > 3">
                <a class="modal-link"
                   (click)="openActivitiesModal()">
                    Alles anzeigen
                </a>
            </div>
        </div>


        <div *ngIf="isCoachApp"
             class="spacer--top">
            <h2 class="headline">
                Ergebnisse der Assessments
            </h2>

            <div *ngIf="!assessments"
                 class="decent">
                Es wurden bisher keine Assessments protokolliert.
            </div>

            <div *ngIf="assessments"
                 class="table-container">
                <table>
                    <thead>
                    <tr>
                        <th>Typ</th>
                        <th>Datum</th>
                        <th>Score</th>
                    </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let assessment of assessments">
                            <tr *ngIf="assessment.date">
                                <td>{{assessment.label}}</td>
                                <td>{{assessment.date}}</td>
                                <td>{{assessment.score}}</td>
                            </tr>
                        </ng-container>
                    </tbody>
                </table>
            </div>
        </div>


        <div class="spacer--top">
            <h2 class="headline">
                Gesprächsverlauf
            </h2>

            <div class="table-container">
                <table *ngIf="conversationHistory?.length > 0">
                    <thead>
                    <tr>
                        <td>Fachberater:</td>
                        <td>Termin:</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr *ngFor="let conversation of conversationHistory">
                        <td>
                            {{conversation.consultantUsername}}
                        </td>
                        <td>
                            <a class="table-link"
                               (click)="showInterviewProtocolById(conversation.appointmentUuid)">
                                {{utilitiesService.formatDisplayedDateAndTime(conversation.dateTime)}}
                            </a>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <div *ngIf="pageData.conversationHistory?.length === 0">
                    Keine Gesprächsverläufe vorhanden.
                </div>
            </div>
        </div>
    </div>
</div>
