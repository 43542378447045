import { Directive } from '@angular/core';

@Directive({
  selector: '[scMonthCaptionTemplate]'
})
export class MonthCaptionTemplateDirective {

  constructor() { }

}
