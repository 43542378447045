<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <div class="row">
        <div class="col-4">
            <h3>Teilnehmer</h3>
            <p>{{data.userExternalId}}</p>
        </div>

        <div class="col-4">
            <h3>Fachberater</h3>
            <p>{{data.consultant}}</p>
        </div>

        <div class="col-4">
            <h3>Datum</h3>
            <p>{{util.formatDisplayedDateAndTime(data.dateTime)}}</p>
        </div>
    </div>

    <div class="row spacer--top">
        <div class="col-12">
            <h4>Status: {{data.result}}</h4>
        </div>
    </div>

    <div class="row spacer--top">
        <div class="col-12">
            <h4>Protokoll:</h4>
            <p *ngIf="data.notes?.length > 0"
               [innerHTML]="data.notes">
            </p>
            <p *ngIf="data.notes?.length === 0">
                Keine Notizen gespeichert.
            </p>
        </div>
    </div>
</div>
