<h2>Entwöhnungsinformationen</h2>

<div class="table-container">
    <table *ngIf="stopSmokingData">
        <tbody>
        <tr>
            <td>Rauchstopp</td>
            <td>{{stopSmokingData.stopSmokingDate.length > 0 ? utilitiesService.formatToGermanDisplayedDate(stopSmokingData.stopSmokingDate) : 'Keine Angabe'}}</td>
        </tr>

        <tr>
            <td>Rauchutensilien entsorgt?</td>
            <td>{{stopSmokingData.removeSmokingUtils ? 'Ja' : 'Nein'}}</td>
        </tr>
        </tbody>
    </table>
</div>
