import {Component} from '@angular/core';

@Component({
    selector: 'tk-bo-spinner',
    templateUrl: './spinner.component.html',
    styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent {

    constructor() {
    }

}
