import {Injectable} from '@angular/core';
import {DateTime, TimeslotsForRequestInterface} from '../../interfaces/appointment.interface';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';
import {WeekdayConstants} from '../../constants/weekday.constants';
import * as moment from 'moment';

@Injectable({
    providedIn: 'root'
})
export class UtilitiesService {

    constructor() {
    }

    private static isSpecificDay(date: Date, day: number): boolean {
        // for day: 0 = sunday, 1 = monday, 2 = tuesday, 3 = wednesday, 4 = thursday, 5 = friday, 6 = saturday
        return date.getDay() === day;
    }

    public static extractTimeOfDate(dateString: string): string {
        let returnVal = dateString;

        if (dateString.length > 8) {
            returnVal = dateString.split('T')[1].substring(0, 8);
        }

        return returnVal;
    }

    public static dateTimeSort(a: any, b: any): number {
        const dateTimeA = +new Date(a.dateTime);
        const dateTimeB = +new Date(b.dateTime);

        return dateTimeA - dateTimeB;
    }

    public static isSunday(date: Date): boolean {
        return UtilitiesService.isSpecificDay(date, 0);
    }

    public static isSaturday(date: Date): boolean {
        return UtilitiesService.isSpecificDay(date, 6);
    }

    public static isDateOnWeekend(date: Date): boolean {
        return UtilitiesService.isSunday(date) || UtilitiesService.isSaturday(date);
    }

    public static timesort(a: any, b: any): number {
        const dateA = +new Date('1970/01/01 ' + a.dateTime);
        const dateB = +new Date('1970/01/01 ' + b.dateTime);

        return dateA - dateB;
    }

    public static isSameMonth(dateA: Date, dateB: Date): boolean {
        return dateA.getMonth() === dateB.getMonth();
    }

    public static formatDateForRequest(dateString: Date): string {
        const date = new Date(dateString);
        const year = date.getFullYear();
        let month = '' + (date.getMonth() + 1);
        let day = '' + date.getDate();

        if (month.length < 2) {
            month = '0' + month;
        }
        if (day.length < 2) {
            day = '0' + day;
        }

        return [year, month, day].join('-');
    }

    public static combineDateAndTimeForRequest(date: string, dateTime: string): string {
        if (dateTime.length < 5) {
            dateTime = '0' + dateTime; // add 0 for times before 10:00
        }

        return date + 'T' + dateTime;
    }

    public static getTimeZoneDateTime(date: string, time: string): string {
        const fullDate = UtilitiesService.combineDateAndTimeForRequest(date, time);
        return moment(fullDate).format();
    }

    public static formatDateAndTimeForRequest(slots: { dateTime: string }[], date: string) {
        const mappedSlots = slots.map(slot => ({
            time: slot.dateTime,
            date: date
        }));

        return mappedSlots;
    }

    public static toISOStringWithTimezone(date: any): any {
        const timeZoneOffset = (new Date()).getTimezoneOffset() * 60000; // offset in ms
        return new Date(date - timeZoneOffset).toISOString().slice(0, -1);
    }

    public static getISODateString(date: any): string {
        return moment(date).format('YYYY-MM-DD');
    }

    public static stringifyDateUnifyLength(num: number): string {
        return num > 9 ? num.toString() : '0' + num.toString();
    }

    public static extractIds(slots: TimeslotsForRequestInterface[]): any[] {
        const returnVal = [];

        for (const slot of slots) {
            returnVal.push(slot.uuid);
        }

        return returnVal;
    }

    public static extractDateTimes(slots: TimeslotsForRequestInterface[], date: Date): DateTime[] {
        const dateTimes = [];

        for (const slot of slots) {
            dateTimes.push(UtilitiesService.getDateTime(slot.dateTime, date));
        }

        return dateTimes;
    }

    public static isNowMinutesBeforeTime(time: string, minutes: number, date: Date): boolean {
        const datesDate = date.setHours(0, 0, 0, 0);
        const todaysDate = new Date().setHours(0, 0, 0, 0);

        if (datesDate === todaysDate) {
            const now = new Date().toLocaleTimeString();
            const lastMinuteTime = new Date('September 9, 1989 ' + time); // random date just for initialisation
            lastMinuteTime.setMinutes(lastMinuteTime.getMinutes() - minutes);

            return lastMinuteTime.toLocaleTimeString() > now;
        } else {
            return datesDate >= todaysDate;
        }
    }

    public static filterReservedSlots(slots): Array<TimeslotsForRequestInterface> {
        const reservedSlots = [];

        for (const slot of slots) {
            if (slot.state === ConsultantUiConstants.timeSlotState.reserved.state) {
                reservedSlots.push(slot);
            }
        }

        return reservedSlots;
    }

    public static formatGermanDateForRequest(dateString: string): string {
        if (!dateString) {
            return '';
        }
        // eg: input: 23.05.2019, output: 2019-05-23
        const parts = dateString.split('.');

        // format shortened inputs (eg: 3.1.19) to standard format
        const year = parts[2].length === 4 ? parts[2] : '20' + parts[2];
        const month = parts[1].length === 2 ? parts[1] : '0' + parts[1];
        const day = parts[0].length === 2 ? parts[0] : '0' + parts[0];

        return year + '-' + month + '-' + day;
    }

    public static getDateTime(time: string, date?: Date): DateTime {
        return {
            date: moment(date).format('YYYY-MM-DD'),
            time: time
        };
    }

    public static getNextWorkDay(oldDate: Date): Date {
        let newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate() + 1);
        if (UtilitiesService.isSaturday(newDate)) {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() + 2);
        }
        return newDate;
    }

    public static getPreviousWorkDay(oldDate: Date): Date {
        let newDate = new Date(oldDate.getFullYear(), oldDate.getMonth(), oldDate.getDate() - 1);
        if (UtilitiesService.isSunday(newDate)) {
            newDate = new Date(newDate.getFullYear(), newDate.getMonth(), newDate.getDate() - 2);
        }

        return newDate;
    }

    public static isFutureDate(date: string): boolean {
        const today = moment().format('YYYY-MM-DD');
        return moment(moment(date)).diff(today) > 0;
    }


    public isTodayOrInTheFuture(date: Date): boolean {
        const today = new Date();

        return date.setHours(0, 0, 0, 0) >= today.setHours(0, 0, 0, 0);
    }

    public formatToGermanDisplayedDate(dateString: string): string {
        // eg: input: 2019-05-23 or 07.01.2020, output: 23.5.2019 or 7.1.2020
        if (!dateString) {
            console.warn('formatToGermanDisplayedDate: no dateString given');
            return '';
        }
        let day;
        let month;
        let year;
        let parts = dateString.split('-');

        if (parts.length === 1) {
            // for german 'ugly' date format with leading zeros
            parts = dateString.split('.');
            day = parts[0];
            month = parts[1];
            year = parts[2];
        } else {
            day = parts[2];
            month = parts[1];
            year = parts[0];
        }

        return parseInt(day, 0) + '.' + parseInt(month, 0) + '.' + year;
    }

    public formatDisplayedTime(timeString: string): string {
        timeString = timeString?.substring(0, 5); // cut seconds
        if (timeString?.indexOf('0') === 0) {
            timeString = timeString.substring(1, 5); // cut leading zero
        }

        return timeString;
    }

    public getTimeWithoutSecs(time: string): string {
        return time?.substring(0, 5);
    }

    public formatDisplayedDateAndTime(dateTime: string): string {
        const localeDate = new Date(dateTime).toLocaleDateString();
        const localTime = this.formatDisplayedTime(UtilitiesService.extractTimeOfDate(dateTime));

        return localeDate + ' - ' + localTime + ' Uhr';
    }

    public formatDisplayedDate(dateString: string): string {
        return new Date(dateString).toLocaleDateString();
    }

    public getWeekdayByDate(date: Date, type: string): string {
        const weekdayNumber = date.getDay();

        return WeekdayConstants[type][weekdayNumber];
    }

    public formatBoolean(value: Boolean): string {
        return value == null ? 'Keine Angabe' : value === true ? 'Ja' : 'Nein';
    }
}
