import {Component, Input} from '@angular/core';
import {CancelSlotsUpdateService} from '../../../services/consultant-ui/cancelSlotUpdate.service';
import {WeekAvailabilityDatesInterface} from '../../../interfaces/appointment.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilitiesService} from '../../../services/global/utilities.service';

@Component({
    selector: 'tk-bo-confirm-reserved-days-cancellation-modal',
    templateUrl: './confirm-reserved-days-cancellation-modal.component.html',
    styleUrls: ['./confirm-reserved-days-cancellation-modal.component.scss']
})
export class ConfirmReservedDaysCancellationModalComponent {

    @Input() reservedDaysToCancel: Array<WeekAvailabilityDatesInterface>;

    constructor(public activeModal: NgbActiveModal,
        public readonly util: UtilitiesService,
        private readonly cancelSlotUpdateService: CancelSlotsUpdateService) {
    }

    public confirmCancelDays(): void {
        this.cancelSlotUpdateService.emitReservedDaysCancellation();
        this.activeModal.close();
    }

    public closeModal(): void {
        this.activeModal.close();
    }

}
