<header>
    <div class="content-container">
        <span class="image-wrapper">
            <img class="image"
                 alt="TK - Logo"
                 [src]="'./assets/images/tk-logo-s.svg'"/>
        </span>

        <nav *ngIf="hasValidAccessToken">
            <ul class="main-nav">
                <li *ngFor="let entry of userService.isConsultantUser() ? [navigationEntries.appointments] : []"
                    class="main-nav-entry">
                    <a class="main-nav-link"
                       [routerLink]="entry.href"
                       [routerLinkActive]="['main-nav-link--active']">
                        <span class="main-nav-link-text">
                            {{entry.label}}
                        </span>
                    </a>
                </li>

                <li class="main-nav-entry">
                    <a class="main-nav-link"
                       (click)="logout()">
                        <span class="main-nav-link-text">Logout</span>
                    </a>
                </li>
            </ul>
        </nav>
    </div>
</header>


