export const WeekdayConstants = {
    'LONG': {
        0: 'Sonntag',
        1: 'Montag',
        2: 'Dienstag',
        3: 'Mittwoch',
        4: 'Donnerstag',
        5: 'Freitag',
        6: 'Samstag'
    },
    'SHORT': {
        0: 'So',
        1: 'Mo',
        2: 'Di',
        3: 'Mi',
        4: 'Do',
        5: 'Fr',
        6: 'Sa'
    }
};
