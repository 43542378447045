<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>
    <h1 class="headline">
        Datum: {{date.toLocaleDateString()}} Uhrzeit: {{time}}
    </h1>
</div>

<div class="last-appointments">
    <div *ngIf="!isSuperVisor"
         class="search-results-container">
        <h2 class="subheadline">
            Teilnehmer:innen der letzten 5 abgeschlossenen Termine
        </h2>

        <div class="table-container">
            <table>
                <thead>
                <tr>
                    <th>
                        Account-ID
                    </th>
                    <th>
                        Programm
                    </th>
                    <th>
                        Name, Vorname
                    </th>
                    <th class="select-btn-column"><!-- empty for column with buttons --></th>
                </tr>
                </thead>

                <tbody *ngIf="myLastAppointments?.length > 0">
                <tr *ngFor="let lastAppointment of myLastAppointments"
                    class="table-row">
                    <td class="limited-width">
                        {{lastAppointment.externalId}}
                    </td>
                    <td class="limited-width">
                        {{getProgramTypeLabel(lastAppointment.programType)}}
                    </td>
                    <td>
                        {{lastAppointment.lastName}}, {{lastAppointment.firstName}}
                    </td>
                    <td>
                        <button *ngIf="lastAppointment.isBookable"
                                class="select-user-button"
                                (click)="bookAppointmentWithUser(lastAppointment)">
                            Termin für Teilnehmer:in buchen
                        </button>
                        <span *ngIf="!lastAppointment.isBookable">Teilnehmer hat bereits einen Termin am {{util.formatDisplayedDateAndTime(lastAppointment.nextAppointmentDate)}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="myLastAppointments?.length === 0"
             class="decent">
            Keinen Teilnehmer:innen gefunden
        </div>
    </div>

</div>

<div>
    <form [formGroup]="formGroup">

        <h2 class="subheadline">
            Suche nach Teilnehmer:innen
        </h2>

        <input class="input"
               type="text"
               id="searchTerm"
               formControlName="searchTerm"/>
    </form>

    <p class="explanation">Sie können nach ID oder Name suchen</p>

    <div class="search-results-container">
        <div class="table-container">
            <table>
                <thead>
                <tr>
                    <th>
                        Account-ID
                    </th>
                    <th>
                        Programm
                    </th>
                    <th>
                        Name, Vorname
                    </th>
                    <th class="select-btn-column"><!-- empty for column with buttons --></th>
                </tr>
                </thead>

                <tbody *ngIf="searchHits?.length > 0">
                <tr *ngFor="let user of searchHits"
                    class="table-row">
                    <td class="limited-width">{{user.externalId}}</td>

                    <td class="limited-width">
                        {{getProgramTypeLabel(user.programType)}}
                    </td>

                    <td>{{user.lastName}}, {{user.firstName}}</td>

                    <td>
                        <button *ngIf="user.isBookable"
                                class="select-user-button"
                                (click)="bookAppointmentWithUser(user)">
                            Termin für Teilnehmer:in buchen
                        </button>
                        <span *ngIf="!user.isBookable">Teilnehmer hat bereits einen Termin am {{util.formatDisplayedDateAndTime(user.nextAppointmentDate)}}</span>
                    </td>
                </tr>
                </tbody>
            </table>
        </div>

        <div *ngIf="totalCount > searchHits?.length"
             class="pagination-wrapper">
            <tk-bo-pagination [currentPage]="currentPage + 1"
                              [pageSize]="10"
                              [totalItems]="totalCount"
                              [totalPages]="totalPages"
                              (paginationUpdate)="onPageChanged($event)">
            </tk-bo-pagination>
        </div>

        <div *ngIf="searchHits?.length === 0"
             class="decent">
            Keinen Teilnehmer gefunden
        </div>
    </div>
</div>
