import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';

import {AuthenticationService} from '../../shared/services/authentication.service';
import {SessionStorageService} from '../../services/global/session-storage.service';
import {UtilitiesService} from '../../services/global/utilities.service';

import {ModalCloseComponent} from '../../components/ui-components/modal-close/modal-close.component';
import {SpinnerComponent} from '../../components/ui-components/spinner/spinner.component';
import {TabsComponent} from '../../components/ui-components/tabs/tabs.component';
import {ToggleSwitchComponent} from '../../components/ui-components/toggle-switch/toggle-switch.component';

import {InterviewProtocolComponent} from '../../components/modals/interview-protocol/interview-protocol.component';
import {SaveSuccessModalComponent} from '../../components/modals/save-success-modal/save-success-modal.component';
import {UserSearchModalComponent} from '../../components/modals/user-search-modal/user-search-modal.component';
import {ConfirmReservedSlotsCancellationModalComponent} from '../../components/modals/confirm-reserved-slots-cancellation-modal/confirm-reserved-slots-cancellation-modal.component';
import {ConfirmReservedDaysCancellationModalComponent} from '../../components/modals/confirm-reserved-days-cancellation-modal/confirm-reserved-days-cancellation-modal.component';
import {PaginationComponent} from '../../components/ui-components/pagination/pagination.component';
import {ConfirmModalComponent} from '../../components/modals/confirm-modal/confirm-modal.component';
import {SelectComponent} from '../../components/ui-components/select/select.component';
import {ConfirmUnavailableModalComponent} from '../../components/modals/confirm-unavailable-modal/confirm-unavailable-modal.component';
import {TkUiComponentsModule} from 'tk-ui-components';
import {UserActivitiesModalComponent} from '../../components/modals/user-activities-modal/user-activities-modal.component';
import {
    AntiStressActivitySummariesComponent
} from '../../components/global/anti-stress-activity-summaries/anti-stress-activity-summaries.component';
import {DragScrollComponent, DragScrollItemDirective} from 'ngx-drag-scroll';

@NgModule({
    declarations: [
        AntiStressActivitySummariesComponent,
        ConfirmModalComponent,
        ConfirmReservedDaysCancellationModalComponent,
        ConfirmReservedSlotsCancellationModalComponent,
        ConfirmUnavailableModalComponent,
        InterviewProtocolComponent,
        ModalCloseComponent,
        PaginationComponent,
        SaveSuccessModalComponent,
        SelectComponent,
        SpinnerComponent,
        TabsComponent,
        ToggleSwitchComponent,
        UserActivitiesModalComponent,
        UserSearchModalComponent,
    ],
    imports: [
        CommonModule,
        ReactiveFormsModule,
        RouterModule,
        TkUiComponentsModule,
        DragScrollComponent,
        DragScrollItemDirective,
    ],
    exports: [
        AntiStressActivitySummariesComponent,
        PaginationComponent,
        SelectComponent,
        SpinnerComponent,
        TabsComponent,
        ToggleSwitchComponent,
    ]
})
export class SharedModule {

    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                AuthenticationService,
                SessionStorageService,
                UtilitiesService
            ]
        };
    }
}
