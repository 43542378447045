import { Component } from '@angular/core';
import {TabsEntryInterface} from '../../../components/ui-components/tabs/tabs-entry.interface';
import {RoutesConstants} from '../../../constants/routes.constants';

@Component({
    selector: 'tk-bo-participants',
    templateUrl: './participants.component.html',
    styleUrls: []
})
export class ParticipantsComponent {

    public navigationEntries: TabsEntryInterface[] = [
        RoutesConstants.appointments.conversationProtocol
    ];

    constructor() { }

}
