// app
export const ActivityTypeConstants = {
    UNKNOWN: 'unbekannt',
    EXERCISE: 'Übung',
    TASK: 'Aufgabe',
    RECIPE: 'Rezept',
    PODCAST: 'Podcast',
};

// web
export const ActivityDomainConstants = {
    UNKNOWN: 'unbekannt',
    FITNESS: 'Fitness',
    FOOD: 'Ernährung',
    ANTISTRESS: 'Entspannung',
};
