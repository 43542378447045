<div class="main main--noBottomMargin">
    <nav>
        <div class="tabs">
            <span *ngFor="let entry of navigationEntries"
                  class="tabs-entry-wrapper">
                <a class="tabs-entry"
                   [routerLink]="entry.href"
                   [routerLinkActive]="['tabs-entry--active']">
                    <span class="tabs-link">
                        <span class="tabs-link-text">
                            {{entry.label}}
                        </span>
                    </span>
                </a>
            </span>

            <span *ngIf="userService.isConsultant()"
                  class="tabs-entry-wrapper">
                <a class="tabs-entry"
                   [routerLink]="'termine-bestaetigen'"
                   [routerLinkActive]="['tabs-entry--active']">
                    <span class="tabs-link">
                        <span class="tabs-link-text">
                            <span class="tabs-link-update">
                                {{sessionStorageService.get(sessionStorageConstants.assignments)}}
                            </span>
                            Neue Termine
                        </span>
                    </span>
                </a>
            </span>
        </div>
    </nav>
</div>


<div class="main main--white">
    <router-outlet></router-outlet>
</div>
