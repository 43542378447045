import {Injectable} from '@angular/core';
import {
    HttpRequest,
    HttpHandler,
    HttpEvent,
    HttpInterceptor,
    HttpResponse,
    HttpErrorResponse,
} from '@angular/common/http';
import {Observable} from 'rxjs';
import {AuthenticationService} from '../shared/services/authentication.service';
import {finalize, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {RoutesConstants} from '../constants/routes.constants';
import {ToastService} from '../services/global/toast.service';
import {SpinnerService} from '../services/global/spinner.service';

@Injectable()
export class DefaultHttpInterceptor implements HttpInterceptor {

    requestCount = 0;

    constructor(private readonly auth: AuthenticationService,
                private readonly toast: ToastService,
                private readonly spinner: SpinnerService,
                private readonly router: Router) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        req = req.clone({
            setHeaders: {
                'X-Requested-With': 'any',
            },
            withCredentials: true,
        });

        if (req.method === 'POST') {
            // show spinner
            this.requestCount++;
            this.spinner.show();
        }

        return next.handle(req)
            .pipe(tap(response => {
                    if (response instanceof HttpResponse) {
                        return response;
                    }
                }, error => {
                    if (error instanceof HttpErrorResponse) {
                        switch (error.status) {
                            case 400:
                                if (error.error?.status === 'USER_ALREADY_BOOKED') {
                                    this.toast.showWarning('Der Nutzer wurde bereits gebucht.');
                                } else {
                                    this.toast.showWarning('Die Daten wurden vom Server nicht akzeptiert.');
                                }

                                break;
                            case 401: // 401 unauthorised user
                                if (req.url.indexOf('/') === -1) {
                                    this.toast.showError('Sitzung abgelaufen.');
                                    this.auth.saveRequestedUrl(window.location.pathname, window.location.search);
                                }
                                this.router.navigate([RoutesConstants.login.href]);
                                break;
                            case 404:
                                // this.toast.showWarning('Es wurden keine Daten gefunden.');
                                break;
                            default:
                                this.toast.showError('Bei der Kommunikation mit dem Server gab es Probleme...');
                        }
                    }
                }), finalize(() => {
                        if (req.method === 'POST') {
                            this.requestCount--;
                            if (this.requestCount === 0) {
                                // hide spinner
                                this.spinner.hide();
                            }
                        }
                    },
                ),
            );
    }

}
