export const RoutesConstants = {
    context: '/',
    appointments: {
        label: 'Termine',
        overviewLabel: 'Terminverwaltung',
        href: '/termine',
        tabs: {
            consultant: {
                management: {
                    label: 'Termine',
                    href: '/termine/verwaltung'
                },
                availability: {
                    label: 'Verfügbarkeit',
                    href: '/termine/verfuegbarkeit'
                }
            },
            supervisor: {
                management: {
                    label: 'Terminverwaltung',
                    href: '/termine/verwaltung'
                },
                availability: {
                    label: 'Fachberaterverwaltung',
                    href: '/termine/verfuegbarkeit'
                },
                status: {
                    label: 'Terminstatus',
                    href: '/termine/status'
                }
            }
        },
        conversationProtocol: {
            label: 'Gesprächsverlauf',
            href: '/nutzer/gespraechsverlauf'
        },
    },
    login: {
        label: 'Login',
        href: '/login',
    }
};
