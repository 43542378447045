import {Injectable} from '@angular/core';
import {lastValueFrom, Observable, Subject} from 'rxjs';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';
import {SessionStorageService} from './session-storage.service';
import {ToastService} from './toast.service';
import {AppointmentApiService} from '../consultant-ui/appointment-api.service';

@Injectable({
    providedIn: 'root'
})
export class AssignmentsUpdateService {

    private assignedAppointmentSubject: Subject<number> = new Subject();
    private interval;

    constructor(public readonly session: SessionStorageService,
                private readonly toast: ToastService,
                private readonly appointmentApi: AppointmentApiService) {
    }

    private getAssignmentUpdates(): void {
        const savedAssignments = this.session.get(ConsultantUiConstants.sessionStorage.assignments);

        lastValueFrom(this.appointmentApi.getAssignedCount())
            .then((response: { assignedAppointments: any[] }) => {
                if (savedAssignments < response.assignedAppointments.length) {
                    this.toast.showInfo('Neue zugewiesene Termine!');
                }

                this.emitNewAssignedAppointments(response.assignedAppointments.length);
            });
    }

    private startAssignmentUpdatesInterval(): void {
        const timeout = 300000; // ... ms equals 5 minutes
        this.interval = setInterval(() => {
            this.getAssignmentUpdates();
        }, timeout);
    }

    public get assignedAppointments$(): Observable<number> {
        return this.assignedAppointmentSubject.asObservable();
    }

    public initAssignmentUpdatesInterval() {
        this.getAssignmentUpdates();
        this.startAssignmentUpdatesInterval();
    }

    public stopAssignmentUpdatesInterval() {
        clearInterval(this.interval);
    }

    public emitNewAssignedAppointments(count: number) {
        this.session.save(ConsultantUiConstants.sessionStorage.assignments, count);
        this.assignedAppointmentSubject.next(count);
    }
}
