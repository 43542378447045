import {Component, Input} from '@angular/core';
import {UtilitiesService} from '../../../../../../services/global/utilities.service';
import {StopSmokingData} from '../../../../../../interfaces/account.interface';


@Component({
    selector: 'tk-bo-stop-smoking-health-objective-details',
    templateUrl: './stop-smoking-health-objective-details.component.html',
    styleUrls: ['./stop-smoking-health-objective-details.component.scss']
})
export class StopSmokingHealthObjectiveDetailsComponent {

    @Input() stopSmokingData: StopSmokingData;
    @Input() user: { firstName: string, lastName: string, externalId: any };

    constructor(public readonly utilitiesService: UtilitiesService) {
    }

    // TODO: check with BE what this is for
    public showExtendedSmokingWithdrawalInformation(): void {
        let offset = 0;
        if (this.stopSmokingData.stopSmokingDate) {
            offset = this.getDateOffset(this.stopSmokingData.stopSmokingDate);
        }
    }

    // TODO update or remove when final API is available
    public reformatResponseForExtendedInfoPopup(obj: any): any {
        const returnVal = [];
        const dates = obj.xaxis.values;
        const protocolValues = obj.series[0].values;

        for (let i = 0; i < dates.length; i++) {
            let smokeFree;

            switch (protocolValues[i]) {
                case 1:
                    smokeFree = true;
                    break;
                case 0:
                    smokeFree = false;
                    break;
                case null:
                    smokeFree = undefined;
                    break;
            }
            returnVal.push({
                date: dates[i],
                smokeFree: smokeFree
            });
        }

        return returnVal;
    }

    public getDateOffset(stopSmokingDateString: string): number {
        const today = new Date();
        const stopSmokingDate = new Date(stopSmokingDateString);

        const diff = Math.floor((today.getTime() - stopSmokingDate.getTime()) / (1000 * 3600 * 24));

        return -diff;
    }

}
