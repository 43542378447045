import {Injectable} from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class CalendarService {

    private currentDate: Date;

    constructor() {
    }

    public persistDate(date: Date): void {
        this.currentDate = date;
    }

    public getCurrentDate(): Date {
        return this.currentDate || new Date();
    }
}
