import {Component, OnDestroy, OnInit} from '@angular/core';
import {TabsEntryInterface} from '../../../components/ui-components/tabs/tabs-entry.interface';
import {UserService} from '../../../services/global/user.service';
import {SessionStorageService} from '../../../services/global/session-storage.service';
import {ConsultantUiConstants} from '../consultant-ui.constants';
import {RoutesConstants} from '../../../constants/routes.constants';
import {AssignmentsUpdateService} from '../../../services/global/assignments-update.service';
import {Subscription} from 'rxjs';

@Component({
    selector: 'tk-bo-appointments',
    templateUrl: './appointments.component.html',
    styleUrls: ['./appointments.component.scss']
})
export class AppointmentsComponent implements OnInit, OnDestroy {

    private static consultantNavigationEntries: TabsEntryInterface[] = [
        RoutesConstants.appointments.tabs.consultant.management,
        RoutesConstants.appointments.tabs.consultant.availability,
    ];

    private static supervisorNavigationEntries: TabsEntryInterface[] = [
        RoutesConstants.appointments.tabs.supervisor.management,
        RoutesConstants.appointments.tabs.supervisor.availability,
        RoutesConstants.appointments.tabs.supervisor.status,
    ];

    public navigationEntries = this.userService.isSupervisor() ? AppointmentsComponent.supervisorNavigationEntries : AppointmentsComponent.consultantNavigationEntries;
    public sessionStorageConstants = ConsultantUiConstants.sessionStorage;


    private assignments: number;
    private assignedAppointmentsSubscription: Subscription;

    constructor(public readonly userService: UserService,
                public readonly sessionStorageService: SessionStorageService,
                public readonly assignedAppointmentUpdateService: AssignmentsUpdateService) {
    }

    ngOnInit() {
        this.assignedAppointmentsSubscription = this.assignedAppointmentUpdateService.assignedAppointments$.subscribe((assignedAppointments) => {
            this.assignments = assignedAppointments;
        });

        this.assignments = this.sessionStorageService.get(ConsultantUiConstants.sessionStorage.assignments);
    }

    ngOnDestroy() {
        this.assignedAppointmentsSubscription.unsubscribe();
    }

}
