import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import * as _ from 'lodash';

import {
    CombinedEmployeeAvailabilityArrayInterface,
    EmployeeAvailabilityArrayInterface,
    SelectedEmployeeTimeslot
} from '../../../interfaces/employee.interface';
import {UtilitiesService} from '../../../services/global/utilities.service';
import {ConsultantUiConstants} from '../consultant-ui.constants';
import {SessionStorageService} from '../../../services/global/session-storage.service';
import {AppointmentService} from '../../../services/consultant-ui/appointment.service';
import {UserService} from '../../../services/global/user.service';

@Component({
    selector: 'tk-bo-employee-appointments-column',
    templateUrl: './employee-appointments-column.component.html',
    styleUrls: ['./employee-appointments-column.component.scss']
})
export class EmployeeAppointmentsColumnComponent implements OnInit {

    @Input() currentDate: Date;
    @Input() employeeOne: EmployeeAvailabilityArrayInterface;
    @Input() employeeTwo?: EmployeeAvailabilityArrayInterface;

    @Output() selectedEmployeeOne: EventEmitter<SelectedEmployeeTimeslot[]> = new EventEmitter();

    public combinedEmployeeAvailabilities: CombinedEmployeeAvailabilityArrayInterface[];
    public constants = ConsultantUiConstants;
    public isAllSlotsSelected = false;

    private selectedTimeSlots: SelectedEmployeeTimeslot[] = [] as SelectedEmployeeTimeslot[];

    constructor(public readonly utilitiesService: UtilitiesService,
                public readonly userService: UserService,
                private readonly appointmentService: AppointmentService,
                private readonly sessionStorageService: SessionStorageService) {
    }

    private static toggleSlotsSelectedState(array, newValue: boolean) {
        for (const item of array) {
            if (item) {
                item.selected = newValue;
            }
        }

        return array;
    }

    ngOnInit() {
        if (this.employeeOne) {
            this.combinedEmployeeAvailabilities = this.combineEmployeeAvailability();
            this.processAllSlots();
        }
    }

    public onAvailabilitySelect(slot): void {
        this.processAllSlots(slot);
    }

    public onUnSelectAllSlots(): void {
        this.combinedEmployeeAvailabilities = EmployeeAppointmentsColumnComponent.toggleSlotsSelectedState(this.combinedEmployeeAvailabilities, false);
        this.processAllSlots();
    }

    public onSelectAllSlots(): void {
        this.combinedEmployeeAvailabilities = EmployeeAppointmentsColumnComponent.toggleSlotsSelectedState(this.combinedEmployeeAvailabilities, true);
        this.processAllSlots();
    }

    public hasSlotDefinedProgram(state): boolean {
        return state === ConsultantUiConstants.timeSlotState.reserved.state || state === ConsultantUiConstants.timeSlotState.not_confirmed.state;
    }

    public getLastWeekdayBeforeDate(date: Date, diff: number): Date {
        const lastWeekdayDate = new Date(date);
        lastWeekdayDate.setDate(date.getDate() - diff);

        // subtract additional 1 or 2 days for weekends
        if (UtilitiesService.isSunday(lastWeekdayDate)) {
            lastWeekdayDate.setDate(lastWeekdayDate.getDate() - 2);
        } else if (UtilitiesService.isSaturday(lastWeekdayDate)) {
            lastWeekdayDate.setDate(lastWeekdayDate.getDate() - 1);
        }

        return lastWeekdayDate;
    }

    public isSlotStillProtocolable(selectedDate: Date): boolean {
        const today = new Date();
        const lastWorkingDayDate = this.getLastWeekdayBeforeDate(today, 1);

        return selectedDate.setHours(0, 0, 0, 0) >= lastWorkingDayDate.setHours(0, 0, 0, 0);
    }

    public isSlotClickable(state: string): boolean {
        const isReserved = state === this.constants.timeSlotState.reserved.state;
        return this.userService.isConsultant() ? isReserved : isReserved && this.isSlotStillProtocolable(this.currentDate) && !this.userService.isSupervisor();
    }

    private getUniqueArrayItems(arr, comp): Array<string> {
        return arr
            .map(e => e[comp])
            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)
            // eliminate the dead keys & store unique objects
            .filter(e => arr[e]).map(e => arr[e]);
    }

    private combineEmployeeAvailability(): any {
        // change object key 'state' to 'employeeOne.state' and 'employeeTwo.state'
        const employeeOneArray = this.employeeOne.availabilities.map((item) => {
            // update assignment store anytime there is a new assignment
            if (item.state === ConsultantUiConstants.timeSlotState.not_confirmed.state) {
                if (this.sessionStorageService.get(ConsultantUiConstants.sessionStorage.assignments) === 0) {
                    this.appointmentService.incrementAssignedAppointmentCount(1);
                }
            }

            return {
                dateTime: item.dateTime,
                employeeOne: {
                    firstConsultation: item.firstConsultation,
                    firstContact: this.employeeOne.firstContact,
                    programType: this.hasSlotDefinedProgram(item.state) ? item.programType : undefined,
                    programTypes: this.employeeOne.programTypes,
                    selected: item.selected,
                    state: item.state,
                    uuid: item.uuid,
                },
                programType: item.programType,
            };
        });

        let employeeTwoArray = [];

        if (this.employeeTwo) {
            employeeTwoArray = this.employeeTwo.availabilities.map((item) => {
                return {
                    dateTime: item.dateTime,
                    employeeTwo: {
                        firstConsultation: item.firstConsultation,
                        firstContact: this.employeeTwo.firstContact,
                        programType: this.hasSlotDefinedProgram(item.state) ? item.programType : undefined,
                        programTypes: this.employeeTwo.programTypes,
                        selected: item.selected,
                        state: item.state,
                        uuid: item.uuid,
                    }
                };
            });
        }

        const timeA = this.employeeOne.availabilities.map(item => ({dateTime: item.dateTime}));
        const timeB = this.employeeTwo.availabilities.map(item => ({dateTime: item.dateTime}));
        // get one array with ALL the different times
        const totalTimeSlots = this.getUniqueArrayItems(timeA.concat(timeB), 'dateTime').sort(UtilitiesService.timesort);

        // find longest and shortest to map the shorter array onto the longer one
        const comparison = employeeOneArray.length > employeeTwoArray.length;
        const longestArray = comparison ? employeeOneArray : employeeTwoArray;
        const shortestArray = !comparison ? employeeOneArray : employeeTwoArray;

        // combine arrays
        return _.map(totalTimeSlots, (item) => {
            _.extend(item, _.find(shortestArray, ['dateTime', item.dateTime]));
            _.extend(item, _.find(longestArray, ['dateTime', item.dateTime]));

            return item;
        });
    }

    private processAllSlots(selectedSlot?): void {
        let isEveryCheckboxSelected = true;
        this.selectedTimeSlots = [];

        if (selectedSlot) {
            selectedSlot.selected = !selectedSlot.selected;
        }

        for (const slot of this.combinedEmployeeAvailabilities) {
            if (slot?.selected) {
                this.selectedTimeSlots.push({
                    dateTime: slot.dateTime,
                    employeeOneFirstConsultation: slot.employeeOne.firstConsultation,
                    employeeOneFirstContact: slot.employeeOne.firstContact,
                    employeeOneHealthProgramTypes: slot.employeeOne.programTypes,
                    employeeOneState: slot.employeeOne.state,
                    employeeOneUserName: slot.employeeOne.username,
                    employeeTwoFirstConsultation: slot.employeeTwo.firstConsultation,
                    employeeTwoFirstContact: slot.employeeTwo.firstContact,
                    employeeTwoProgramTypes: slot.employeeTwo.programTypes,
                    employeeTwoState: slot.employeeTwo.state,
                    employeeTwoUsername: slot.employeeTwo.username,
                    programType: slot.programType ? slot.programType : '',
                    selected: slot.selected,
                    uuid: slot.employeeOne.uuid,
                });
            } else {
                isEveryCheckboxSelected = false;
            }
        }

        this.emitSelectedEmployeeEvent(this.selectedTimeSlots);

        this.isAllSlotsSelected = isEveryCheckboxSelected;
    }

    private emitSelectedEmployeeEvent(slots): void {
        this.selectedEmployeeOne.next(slots);
    }
}
