import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'tk-bo-save-success-modal',
    templateUrl: './save-success-modal.component.html',
    styleUrls: ['./save-success-modal.component.scss']
})
export class SaveSuccessModalComponent implements OnInit {

    @Input() additionalText?: string;
    @Input() buttonLabel: string;
    @Input() nextRoute: string;
    @Input() text: string;

    constructor(public activeModal: NgbActiveModal) {
    }

    ngOnInit() {
        this.startCloseModalCountdown();
    }

    public closeModal(): void {
        this.activeModal.close();
    }

    public startCloseModalCountdown(): void {
        setTimeout(() => {
            this.closeModal();
        }, 10000);
    }

}
