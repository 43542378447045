import {Component, OnInit} from '@angular/core';

import {AppointmentApiService} from '../../../../services/consultant-ui/appointment-api.service';
import {UtilitiesService} from '../../../../services/global/utilities.service';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {ConsultantsInterface, SelectOptionInterface} from '../../../../interfaces/selectOption.interface';
import {CalendarLegendInterface} from '../../../../components/ui-components/calendar/calendarLegend.interface';
import {WeekAvailabilityInterface, WeekAvailabilityRequestInterface} from '../../../../interfaces/appointment.interface';
import {SupervisorAppointmentApiService} from '../../../../services/consultant-ui/supervisor-appointment-api.service';
import {UserService} from '../../../../services/global/user.service';
import {ToastService} from '../../../../services/global/toast.service';
import {SessionStorageService} from '../../../../services/global/session-storage.service';
import {lastValueFrom, Subscription} from 'rxjs';
import {CancelSlotsUpdateService} from '../../../../services/consultant-ui/cancelSlotUpdate.service';
import {
    ConfirmReservedDaysCancellationModalComponent
} from '../../../../components/modals/confirm-reserved-days-cancellation-modal/confirm-reserved-days-cancellation-modal.component';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';
import {ModalConstants} from '../../../../constants/modal.constants';
import {SpinnerService} from '../../../../services/global/spinner.service';


@Component({
    selector: 'tk-bo-appointment-availability',
    templateUrl: './appointment-availability.component.html',
    styleUrls: ['./appointment-availability.component.scss']
})
export class AppointmentAvailabilityComponent implements OnInit {

    public calendarLegend: CalendarLegendInterface[] = [{
        label: ConsultantUiConstants.timeSlotState.available.label,
        cssClass: ConsultantUiConstants.timeSlotState.available.state
    }, {
        label: ConsultantUiConstants.timeSlotState.reserved.label,
        cssClass: ConsultantUiConstants.timeSlotState.reserved.state
    }, {
        label: ConsultantUiConstants.timeSlotState.not_available.label,
        cssClass: ConsultantUiConstants.timeSlotState.not_available.state
    }];

    public calendar: string[];
    public consultants: SelectOptionInterface[] = [] as SelectOptionInterface[];
    public currentDate: Date = new Date();
    public programTypes = [
        ConsultantUiConstants.program.antistress,
        ConsultantUiConstants.program.stop_smoking,
    ];

    public showTable = false;
    public shiftStartOptions: SelectOptionInterface[] = [{
        label: 'Beginn wählen',
        value: 'default',
        selected: true,
        disabled: true
    }];
    public shiftEndOptions: SelectOptionInterface[] = [{
        label: 'Ende wählen',
        value: 'default',
        selected: true,
        disabled: true
    }];
    public weeks: { date, state }[][];
    public weekOptions: SelectOptionInterface[] = [{
        label: ConsultantUiConstants.timeSlotState.available.label,
        value: ConsultantUiConstants.timeSlotState.available.state
    }, {
        label: ConsultantUiConstants.timeSlotState.not_available.label,
        value: ConsultantUiConstants.timeSlotState.not_available.state
    }];

    private allConsultants;
    private cancelReservedDaysConfirmationSubscription: Subscription;
    private modalOptions: NgbModalOptions = ModalConstants.modalOptions;
    private month: WeekAvailabilityInterface[];
    private selectedConsultant: string;

    constructor(public readonly userService: UserService,
                private readonly appointmentApiService: AppointmentApiService,
                private readonly supervisorAppointmentApiService: SupervisorAppointmentApiService,
                private readonly toastService: ToastService,
                private readonly sessionStorageService: SessionStorageService,
                private readonly util: UtilitiesService,
                private readonly cancelSlotsUpdateService: CancelSlotsUpdateService,
                private readonly spinnerService: SpinnerService,
                public modal: NgbModal) {
    }

    ngOnInit() {
        if (this.userService.isSupervisor()) {
            this.initConsultants();
        } else {
            this.fetchCalendar(UtilitiesService.formatDateForRequest(new Date()), this.userService.getConsultantUsername().username);
        }
        this.setShiftOptions();
    }

    public setDate(date): void {
        const previousDate = this.currentDate;
        this.currentDate = date;
        this.processDaySelection(UtilitiesService.formatDateForRequest(date));

        // update calendar when month changes
        if (!UtilitiesService.isSameMonth(previousDate, date)) {
            const formattedDate = UtilitiesService.formatDateForRequest(date);
            this.fetchCalendar(formattedDate, this.selectedConsultant);
        }
    }

    public availabilityCheckClicked(state: string, weekIndex: string): void {
        this.month[weekIndex].state = state;
    }

    public processShiftStartSelection(event): void {
        this.processShiftSelection(event, 'Start');
    }

    public processShiftEndSelection(event): void {
        this.processShiftSelection(event, 'End');
    }

    public processWeekSelection(checked, daysOfWeek, weekIndex): void {
        this.month[weekIndex].dates = checked ? daysOfWeek : undefined;
        this.month[weekIndex].checked = checked;

        if (checked === false) {
            // if week-checkbox is unchecked, add the selected single date to the selection
            this.processDaySelection(UtilitiesService.formatDateForRequest(this.currentDate));
        }
    }


    public processRequestData(): void {
        const requestData: WeekAvailabilityRequestInterface[] = [];
        let requestEnabled = false;
        let hasReservedDay = false;
        const reservedDays = [];

        for (const week of this.month) {
            if (week.state && week.dates.length > 0) {
                // enable request when at least for one week a state and dates are selected
                requestEnabled = true;

                if (week.state === ConsultantUiConstants.timeSlotState.not_available.state) {
                    // find reserved days in weeks the user wants to change state to UNAVAILABLE
                    const reservedDaysByWeek = week.dates.find((day) => {
                        return day.state === ConsultantUiConstants.timeSlotState.reserved.state.toUpperCase();
                    });

                    if (reservedDaysByWeek) {
                        // collect days with reserved slot(s)
                        reservedDays.push(reservedDaysByWeek);
                    }
                }
                if (!hasReservedDay) {
                    hasReservedDay = reservedDays.length > 0;
                }

                const programConfig = ConsultantUiConstants.program[this.userService.getProgramName().toLowerCase()];
                week.duration = programConfig.duration;
                week.shiftStart = week.shiftStart ? week.shiftStart : programConfig.shiftStart;
                week.shiftEnd = week.shiftEnd ? week.shiftEnd : programConfig.shiftEnd;

                requestData.push({
                    duration: week.duration,
                    daysOfWeek: week.dates.map((date) => date.date),
                    shiftStart: week.shiftStart,
                    shiftEnd: week.shiftEnd,
                    state: week.state.toUpperCase()
                });
            }
        }

        if (requestEnabled && !hasReservedDay) {
            this.updateAvailability(requestData);
        } else {
            if (!requestEnabled) {
                this.toastService.showWarning('Überprüfen Sie Ihre Auswahl!');
            } else if (hasReservedDay) {
                // open confirmation popup
                const modalRef = this.modal.open(ConfirmReservedDaysCancellationModalComponent, this.modalOptions);
                modalRef.componentInstance.reservedDaysToCancel = reservedDays;
                // init subscription
                this.cancelReservedDaysConfirmationSubscription = this.cancelSlotsUpdateService.cancelReservedDaysUpdate$.subscribe(
                    () => {
                        this.updateAvailability(requestData);
                        this.cancelReservedDaysConfirmationSubscription.unsubscribe();
                    });
            }
        }
    }

    public updateConsultant(event: any): void {
        this.selectedConsultant = event;
        this.fetchCalendar(UtilitiesService.formatDateForRequest(this.currentDate), this.selectedConsultant);
    }

    public onProgramTypeChange(programType): void {
        if (programType === 'default') {
            this.setConsultantsDropdown(this.allConsultants);
            this.fetchCalendar(UtilitiesService.formatDateForRequest(this.currentDate), this.selectedConsultant);
        } else {
            const consultants = this.allConsultants.filter(consultant => consultant.programTypes.indexOf(programType) !== -1);
            this.setConsultantsDropdown(consultants);
            this.fetchCalendar(UtilitiesService.formatDateForRequest(this.currentDate), this.selectedConsultant);
        }
    }

    private fetchCalendar(date, user?): void {
        this.spinnerService.onRequestStart();

        lastValueFrom(this.appointmentApiService.getCalendar(date, user))
            .then((response: { calendarDays: any[] }) => {
                this.calendar = response.calendarDays;
                this.collectPossibleWeeks(this.calendar, new Date(date));
                this.setDate(this.currentDate);
                this.showTable = true;
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private initConsultants(): void {
        this.spinnerService.onRequestStart();

        lastValueFrom(this.supervisorAppointmentApiService.getConsultants())
            .then((response: ConsultantsInterface) => {
                this.allConsultants = response.consultants;
                this.setConsultantsDropdown(response.consultants);
                this.fetchCalendar(UtilitiesService.formatDateForRequest(new Date()), this.selectedConsultant);
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private collectPossibleWeeks(array: string[], date: Date): void {
        this.weeks = [];
        let weekNumber = 0;
        const month = date.getMonth() + 1;
        const monthString: string = UtilitiesService.stringifyDateUnifyLength(month); // add starting 0 to months < 10
        const year = date.getFullYear().toString();

        for (let i = 0; i < array.length; i++) {
            const day = i + 1;
            const dayString: string = UtilitiesService.stringifyDateUnifyLength(day); // add starting 0 to days < 10

            const dateToCheck = new Date([year, monthString, dayString].join('-'));

            if (!UtilitiesService.isDateOnWeekend(dateToCheck)) {
                if (!this.weeks[weekNumber]) {
                    this.weeks[weekNumber] = [];
                }

                this.weeks[weekNumber].push({
                    date: UtilitiesService.formatDateForRequest(dateToCheck),
                    state: array[i]
                });
            } else if (UtilitiesService.isSunday(dateToCheck)) {
                if (dayString !== '01') {
                    weekNumber += 1;
                }
            }
        }

        this.month = [] as WeekAvailabilityInterface[];
        for (let i = 0; i < this.weeks.length; i++) {
            this.month[i] = {
                state: undefined,
                dates: undefined,
                checked: false,
                shiftStart: undefined,
                shiftEnd: undefined
            };
        }
    }

    private setShiftOptions(): void {
        this.spinnerService.onRequestStart();

        lastValueFrom(this.appointmentApiService.getShiftTimeItems(ConsultantUiConstants.program[this.userService.getProgramName().toLowerCase()]))
            .then((slots: { slots: string[], shiftTimeSlots: string[] }) => {
                this.spinnerService.onRequestEnd();

                const shiftTimeSlots = slots.shiftTimeSlots;
                for (let i = 0; i < shiftTimeSlots.length; i++) {
                    const slot = shiftTimeSlots[i];

                    if (i !== shiftTimeSlots.length - 1) { // do not add last item to shiftStartOptions
                        this.shiftStartOptions.push({
                            label: 'von ' + this.util.formatDisplayedTime(slot) + ' Uhr',
                            value: slot
                        });
                    }

                    if (i !== 0) { // do not add first item to shiftEndOptions
                        this.shiftEndOptions.push({
                            label: 'bis ' + this.util.formatDisplayedTime(slot) + ' Uhr',
                            value: slot
                        });
                    }
                }
            });
    }

    private processShiftSelection(event, property): void {
        this.month[event.changeDetectionId]['shift' + property] = event.val;
    }

    private processDaySelection(date): void {
        // find date in this.weeks, add it to this.month
        for (let i = 0; i < this.weeks.length; i++) {
            const week = this.weeks[i];

            if (week && !this.month[i].checked) {
                // reset all weeks that are not checked - only one single day can be selected at once
                this.month[i].dates = [];

                const selectedDay = week.filter(day => day.date === date);
                if (week && selectedDay.length === 1) {
                    this.month[i].dates = selectedDay;
                }
            }
        }
    }

    private updateAvailability(requestData: WeekAvailabilityRequestInterface[]): void {
        const subscription = this.userService.isSupervisor() ?
            this.supervisorAppointmentApiService.postUpdateAvailabilityForConsultant(this.selectedConsultant, requestData)
            : this.appointmentApiService.postUpdateAvailability(requestData);

        this.spinnerService.onRequestStart();
        lastValueFrom(subscription)
            .then(() => {
                this.fetchCalendar(UtilitiesService.formatDateForRequest(this.currentDate), this.selectedConsultant);
                this.toastService.showSuccess('Neue Verfügbarkeiten gespeichert');
                if (this.userService.isSupervisor()) {
                    this.showTable = false;
                }
            })
            .finally(() => {
                this.spinnerService.onRequestEnd();
            });

    }

    private setConsultantsDropdown(consultants): void {
        const options: SelectOptionInterface[] = [];
        for (let i = 0; i < consultants.length; i++) {
            const consultant = consultants[i];

            options.push({
                label: consultant.fullname,
                value: consultant.username,
                selected: i === 0,
                disabled: undefined
            });
        }

        this.consultants = options;
        this.selectedConsultant = this.consultants[0]?.value;
    }
}
