import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal, NgbModalOptions} from '@ng-bootstrap/ng-bootstrap';

import {UtilitiesService} from '../../../../services/global/utilities.service';
import {AppointmentApiService} from '../../../../services/consultant-ui/appointment-api.service';
import {ToastService} from '../../../../services/global/toast.service';
import {ModalConstants} from '../../../../constants/modal.constants';
import {AccountConversationProtocolHistoryInterface, AccountConversationProtocolInterface} from '../../../../interfaces/account.interface';
import {ConsultantUiConstants} from '../../consultant-ui.constants';
import {AppointmentService} from '../../../../services/consultant-ui/appointment.service';
import {SessionStorageService} from '../../../../services/global/session-storage.service';
import {
    ConfirmUnavailableModalComponent
} from '../../../../components/modals/confirm-unavailable-modal/confirm-unavailable-modal.component';
import {AppointmentResult, ProgramType, ProtocolStatus, Tenant} from '../../../../interfaces/appointment.interface';
import {firstValueFrom, lastValueFrom} from 'rxjs';
import {UserService} from '../../../../services/global/user.service';
import {SpinnerService} from '../../../../services/global/spinner.service';
import {TenantConstants} from '../../../../constants/tenant.constants';
import {AccountService} from '../../../../services/global/account.service';
import {AssessmentInterface} from '../../../../interfaces/assessment.interface';
import {ActivitySummariesInterface} from '../../../../interfaces/activity.interface';
import {UserActivitiesModalComponent} from '../../../../components/modals/user-activities-modal/user-activities-modal.component';
import {ActivityDomainConstants, ActivityTypeConstants} from '../../../../constants/activity.constants';
import {AssessmentConstants} from '../../../../constants/assessment.constants';

@Component({
    selector: 'tk-bo-participant-conversation-protocol',
    templateUrl: './participant-conversation-protocol.component.html',
    styleUrls: ['./participant-conversation-protocol.component.scss']
})
export class ParticipantConversationProtocolComponent implements OnInit, OnDestroy {

    constructor(public readonly utilitiesService: UtilitiesService,
                private modal: NgbModal,
                private readonly route: ActivatedRoute,
                private readonly router: Router,
                private readonly appointmentApiService: AppointmentApiService,
                private readonly toastService: ToastService,
                private readonly appointmentService: AppointmentService,
                private readonly accountService: AccountService,
                private readonly sessionStorageService: SessionStorageService,
                private readonly spinnerService: SpinnerService,
                private readonly userService: UserService) {
    }

    public accountData: any;
    public appointmentId: string;
    public assessments: AssessmentInterface[];
    public consultant: { username: string, fullname: string };
    public conversationHistory: any[];
    public hoursRounded = 0;
    public maxActivitiesShown = 3;
    public minutesRounded = 0;
    public notes = '';
    public pageData: AccountConversationProtocolInterface;
    public programConstants = ConsultantUiConstants.program;
    public timeElapsed = 0;
    public timeLimit = 1800; // ...seconds = 30 minutes
    public timerStarted = false;

    private interval;
    private modalOptions: NgbModalOptions = ModalConstants.modalOptions;

    ngOnInit() {
        this.route.queryParams.subscribe(params => {
            if (params.appointmentId) {
                this.appointmentId = params.appointmentId;
                this.fetchPageDataByAppointmentId(this.appointmentId);
            }
        });

        this.consultant = this.userService.getConsultantUsername();
    }

    ngOnDestroy() {
        clearInterval(this.interval);
    }

    public get isCoachWeb(): boolean {
        return this.pageData.user.tenant === Tenant.TK_GESUNDHEITSCOACH_WEB;
    }

    public get isCoachApp(): boolean {
        return this.pageData.user.tenant === Tenant.TK_COACH_APP;
    }

    public get programLabel(): string {
        const programLabel = this.isCoachWeb ?
            `${TenantConstants[this.pageData.user.tenant]} / ${ConsultantUiConstants.program[this.pageData.appointment.programType.toLowerCase()].title}`
            : TenantConstants[this.pageData.user.tenant];

        return programLabel;
    }

    public get showConversationGuide(): boolean {
        return this.timerStarted || this.pageData.appointment.result === ProtocolStatus.REACHED;
    }

    public get notesDisabled(): boolean {
        return !!this.pageData.appointment.protocol?.notes;
    }

    public get elapsedTimeString(): string {
        let timeString;
        let hours;
        const seconds = UtilitiesService.stringifyDateUnifyLength(this.timeElapsed % 60);
        const minutes = UtilitiesService.stringifyDateUnifyLength(this.minutesRounded);
        timeString = `${minutes}:${seconds}`;
        if (this.hoursRounded > 0) {
            hours = UtilitiesService.stringifyDateUnifyLength(this.hoursRounded);
            timeString = `${hours}:${timeString}`;
        }

        return timeString;
    }

    public startTimer(): void {
        this.timerStarted = true;

        this.interval = setInterval(() => {
            this.timeElapsed++;

            this.minutesRounded = Math.floor(this.timeElapsed / 60) % 60;
            this.hoursRounded = Math.floor(this.timeElapsed / 3600);

        }, 1000);
    }

    public pauseTimer(): void {
        clearInterval(this.interval);
    }

    public updateNotes(notes): void {
        this.notes = notes;
        // save notes in sessionStorage - can be restored in case of timeout
        this.sessionStorageService.save(ConsultantUiConstants.sessionStorage.note + this.appointmentId, notes);
    }

    public openConfirmSaveProtocolPopup(): void {
        const modalRef = this.modal.open(ConfirmUnavailableModalComponent, this.modalOptions);
        modalRef.componentInstance.appointmentId = this.appointmentId;
        modalRef.componentInstance.confirmLabel = 'Bestätigen';
        modalRef.componentInstance.cancelLabel = 'Abbrechen';
        modalRef.result
            .then(() => {
                this.router.navigate(['/termine/verwaltung']);
            })
            .catch(() => {
            });
    }

    public saveProtocol(appointmentId: string, result: string): void {
        const notes = this.notes.replace(/\r\n|\r|\n/g, '<br>');

        firstValueFrom(this.appointmentApiService.saveAppointmentProtocol(appointmentId, AppointmentResult[result], this.minutesRounded, notes))
            .then(() => {
                this.sessionStorageService.delete(ConsultantUiConstants.sessionStorage.note + appointmentId);
                this.toastService.showSuccess('Protokoll wurde gespeichert');
                this.router.navigate(['/termine/verwaltung']);
            }, (err) => {
                console.log('saveAppointmentProtocol error: ', err.status);
            });
    }

    public showInterviewProtocolById(protocolId: string): void {
        this.appointmentService.showInterviewProtocolById(protocolId);
    }

    public showNumberOfActivities(activitiesCount: number): void {
      this.maxActivitiesShown = activitiesCount;
    }

    public openActivitiesModal(): void {
        const modalRef = this.modal.open(UserActivitiesModalComponent, {...this.modalOptions, ...{size: 'lg'}});
        modalRef.componentInstance.activitySummaries = this.pageData.activitySummaries;
        modalRef.componentInstance.user = this.pageData.user;
    }

    private fetchCoachWebHealthObjective(): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.appointmentApiService.getCoachWebHealthObjective(this.pageData.user.externalId))
            .then((response: any) => {
                this.accountData.expectedEndDate = this.utilitiesService.formatDisplayedDate(response.expectedEndDate);
            }, (error) => {
                if (error.status === 404) {
                    this.toastService.showError('Für den Nutzer ' + this.pageData.user.externalId + ' haben wir keine Daten gefunden');
                }
            }).finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private fetchPageDataByAppointmentId(appointmentId): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.appointmentApiService.getAppointmentDetails(appointmentId))
            .then((response: AccountConversationProtocolInterface) => {
                this.processAppointmentProtocolData(response);
            }, (error) => {
                if (error.status === 404) {
                    this.toastService.showError('Für die ID ' + appointmentId + ' haben wir keinen Termin gefunden');
                }
            }).finally(() => {
                this.spinnerService.onRequestEnd();
        });
    }

    private processAppointmentProtocolData(response): void {
        this.pageData = response;
        const user = this.pageData.user;

        this.fetchAccountDetails(user.externalId, user.tenant);
        this.fetchProtocolHistory(user.externalId, user.tenant);

        if (this.isCoachApp) {
           this.fetchCoachAppData(user);
        }

        if (this.isCoachWeb) {
            this.fetchCoachWebHealthObjective();
            this.fetchCoachWebData();
            if (this.pageData.appointment.programType === ProgramType.STOP_SMOKING) {
                this.fetchStopSmokingData();
            }
        }
    }

    private fetchCoachWebData(): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.appointmentApiService.getCoachWebActivityHistory(this.pageData.user.externalId))
            .then((response: ActivitySummariesInterface) => {
                this.pageData.activitySummaries = response.summaries;
                this.pageData.activitySummaries.map((activity) => {
                    activity.typeLabel = ActivityDomainConstants[activity.domain];
                });

            }, (error) => {
                if (error.status === 404) {
                    this.toastService.showError('Für den Nutzer ' + this.pageData.user.externalId + ' haben wir keine Daten gefunden');
                }
            }).finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private fetchStopSmokingData(): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.appointmentApiService.getCoachWebStopSmokingDetails(this.pageData.user.externalId))
            .then((response: any) => {
                this.pageData.stopSmokingData = response;
            }, (error) => {
                if (error.status === 404) {
                    this.toastService.showError('Für den Nutzer ' + this.pageData.user.externalId + ' haben wir keine Daten gefunden');
                }
            }).finally(() => {
                this.spinnerService.onRequestEnd();
            });
    }

    private fetchCoachAppData(user): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.accountService.getActivitiesByAccount(user.externalId))
            .then((activityResponse: ActivitySummariesInterface) => {
                activityResponse.summaries.map(activity => {
                    activity.typeLabel = ActivityTypeConstants[activity.type];
                });
                this.pageData.activitySummaries = activityResponse.summaries;
            }, (err) => {
                console.log('getActivitiesByAccount error: ', err.status);
            }).finally(() => {
                this.spinnerService.onRequestEnd();
            });

        firstValueFrom(this.accountService.getAssessmentsByAccount(user.externalId, user.tenant))
            .then((assessmentsResponse: {assessments: AssessmentInterface[] }) => {
                this.assessments = assessmentsResponse.assessments;
                this.assessments.map((assessment) => {
                    assessment.label = AssessmentConstants[assessment.assessmentType];
                });
            }, (err) => {
                console.log('getAssessmentsByAccount error: ', err.status);
            });
    }

    private fetchAccountDetails(accountUuid: string, tenant: Tenant): void {
        this.spinnerService.onRequestStart();
        firstValueFrom(this.accountService.getAccountDetailsById(accountUuid, tenant))
            .then((response) => {
                const accountData = response;
                accountData.registrationDate = this.utilitiesService.formatDisplayedDate(accountData.registrationDate);
                this.accountData = accountData;
            }).finally(() => {
                this.spinnerService.onRequestEnd();
        });
    }

    private fetchProtocolHistory(userId: string,  tenant: Tenant): void {
        this.spinnerService.onRequestStart();
        lastValueFrom(this.appointmentApiService.getProtocolHistoryByUserId(userId, tenant))
            .then((response: AccountConversationProtocolHistoryInterface) => {
                this.conversationHistory = response?.protocols.sort(UtilitiesService.dateTimeSort);
            }, error => {
                if (error.status === 404) {
                    this.conversationHistory = [];
                }
            }).finally(() => {
                this.spinnerService.onRequestEnd();
        });
    }

}
