import { Directive } from '@angular/core';

@Directive({
  selector: '[scDayOfWeekCaptionTemplate]'
})
export class DayOfWeekCaptionTemplateDirective {

  constructor() { }

}
