<div>
    <tk-bo-modal-close (closeModalEventEmitter)="closeModal()"></tk-bo-modal-close>

    <div class="row">
        <div class="col-12">

            <tk-bo-anti-stress-activity-summaries *ngIf="activitySummaries"
                                                  [activitySummaries]="activitySummaries"
                                                  [maxItems]="activitySummaries.length"
                                                  [showTypeCol]="true"
                                                  [user]="{firstName: user.firstName, lastName: user.lastName, externalId: user.externalId}">
            </tk-bo-anti-stress-activity-summaries>
        </div>
    </div>
