import {Component, Input} from '@angular/core';
import {ActivitySummaryInterface} from '../../../interfaces/activity.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilitiesService} from '../../../services/global/utilities.service';

@Component({
  selector: 'tk-bo-user-activities',
  templateUrl: './user-activities-modal.component.html',
  styleUrls: ['./user-activities-modal.component.scss']
})
export class UserActivitiesModalComponent {

    @Input() activitySummaries: ActivitySummaryInterface[];
    @Input() user: any;

    constructor(public activeModal: NgbActiveModal) {
    }

    public closeModal(): void {
        this.activeModal.close();
    }
}
