<div class="component-container">
    <div *ngFor="let availabilityItem of combinedEmployeeAvailabilities, let index = index"
         class="row">
        <div class="time col-2">
            <span *ngIf="!isSlotClickable(availabilityItem.employeeOne.state)">
                {{utilitiesService.formatDisplayedTime(availabilityItem.dateTime)}}
            </span>

            <a *ngIf="isSlotClickable(availabilityItem.employeeOne.state)"
               class="link-interview"
               title="Zum Interview {{availabilityItem.employeeOne.uuid}}"
               [routerLink]="'/nutzer/gespraechsverlauf'"
               [queryParams]="{appointmentId: availabilityItem.employeeOne.uuid}">
                {{utilitiesService.formatDisplayedTime(availabilityItem.dateTime)}}
            </a>
        </div>

        <div class="col-5">
            <div class="program-type-container program-type-container--employeeOne">
                <span *ngIf="availabilityItem.programType"
                      class="program-type">
                    {{constants.program[availabilityItem.programType.toLowerCase()].shorthand_name}}
                </span>
            </div>

            <label *ngIf="availabilityItem.employeeOne"
                   for="{{employeeOne.value}}-{{index}}"
                   [title]="employeeOne.label">
                <span class="state state--{{availabilityItem.employeeOne.state}}">
                    {{employeeOne.label}}
                </span>

                <span *ngIf="availabilityItem.employeeOne.firstConsultation"
                      class="first-consultation-flag first-consultation-flag--employeeOne">
                      1st
                </span>

                <input *ngIf="availabilityItem.employeeOne"
                       class="checkbox"
                       type="checkbox"
                       id="{{employeeOne.value}}-{{index}}"
                       [checked]="availabilityItem.selected"
                       (change)="onAvailabilitySelect(availabilityItem)"/>

                <span class="checkmark"></span>
            </label>
        </div>

        <div class="col-5">
            <div class="program-type-container program-type-container--employeeTwo">
                <span *ngIf="availabilityItem.employeeTwo.programType"
                      class="program-type">
                    {{constants.program[availabilityItem.employeeTwo.programType.toLowerCase()].shorthand_name}}
                </span>
            </div>

            <span *ngIf="availabilityItem.employeeTwo">
                <span class="state state--col-two state--{{availabilityItem.employeeTwo.state}}">
                    {{employeeTwo.label}}
                </span>
                <span *ngIf="availabilityItem.employeeTwo.firstConsultation"
                      class="first-consultation-flag first-consultation-flag--employeeTwo">
                    1st
                </span>
            </span>
        </div>
    </div>

    <div class="row spacer--top">
        <div class="col-2"></div>
        <div class="col-5">
            <label class="selectAll"
                   for="selectAll--{{employeeOne.value}}"
                   [ngClass]="{'disabled': isAllSlotsSelected}">
                <span class="state">
                    Alle Termine wählen
                </span>

                <input class="checkbox"
                       type="checkbox"
                       id="selectAll--{{employeeOne.value}}"
                       [disabled]="isAllSlotsSelected"
                       [checked]="isAllSlotsSelected"
                       (change)="onSelectAllSlots()" />
                <span class="checkmark checkmark--small"></span>
            </label>

            <div *ngIf="isAllSlotsSelected"
                 class="reset-checkboxes-container">
                <button class="btn no-btn"
                        (click)="onUnSelectAllSlots()">
                    Zurücksetzen
                </button>
            </div>
        </div>
        <div class="col-5"></div>
    </div>
</div>
