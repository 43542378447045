import { Injectable } from '@angular/core';
import {UserInterface} from '../../interfaces/user.interface';
import {SessionStorageService} from './session-storage.service';
import {ConsultantUiConstants} from '../../modules/consultant-ui/consultant-ui.constants';
import {UserConstants} from '../../constants/user.constants';
import {AuthenticationService} from '../../shared/services/authentication.service';

@Injectable({
    providedIn: 'root'
})
export class UserService {

    constructor(private readonly session: SessionStorageService,
                private readonly authenticationService: AuthenticationService) {
    }

    public getConsultantUsername(): {username: string, fullname: string} {
        const consultant = this.session.get(ConsultantUiConstants.sessionStorage.consultantInfo);

        return {
            username: consultant?.username || null,
            fullname: consultant?.fullname || null
        };
    }

    public setConsultantInfo(info: any) {
        this.session.save(ConsultantUiConstants.sessionStorage.consultantInfo, info);
    }

    public getConsultantInfo(): string[] {
        return this.session.get(ConsultantUiConstants.sessionStorage.consultantInfo);
    }

    public getProgramName(): string {
        let returnVal = '';

        if (this.hasStopSmokingRole()) {
            returnVal = ConsultantUiConstants.program.stop_smoking.name;
        } else {
            returnVal = ConsultantUiConstants.program.antistress.name;
        }

        return returnVal;
    }

    public isConsultant(): boolean {
        return this.authenticationService.hasRole(UserConstants.roles.CONSULTANT);
    }

    public isSupervisor(): boolean {
        return this.authenticationService.hasRole(UserConstants.roles.SUPERVISOR);
    }

    public isConsultantUser(): boolean {
        return this.isSupervisor() || this.isConsultant();
    }

    public hasStopSmokingRole(): boolean {
        return this.authenticationService.hasRole(UserConstants.roles.STOP_SMOKING);
    }
}
