import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
    selector: 'tk-bo-pagination',
    templateUrl: './pagination.component.html',
    styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent {

    @Input() currentPage: number;
    @Input() pageSize: number;
    @Input() totalItems: number;
    @Input() totalPages: number;

    @Output() paginationUpdate: EventEmitter<any> = new EventEmitter();

    public startIndex: number;

    constructor() {
    }

    public prevPage(): void {
        if (this.currentPage > 1) {
            this.currentPage = this.currentPage - 1;
            this.update();
        }
    }

    public nextPage(): void {
        if (this.currentPage < this.totalPages) {
            this.currentPage = this.currentPage + 1;
            this.update();
        }
    }

    private setStartIndex(): void {
        // calculate start and end item indexes
        this.startIndex = (this.currentPage + 1) * this.pageSize;
    }

    private update(): void {
        this.setStartIndex();

        const paginationUpdate = {
            currentPage: this.currentPage,
            pageSize: this.pageSize,
            startIndex: this.startIndex
        };

        this.paginationUpdate.emit(paginationUpdate);
    }
}
