import {Component, Input} from '@angular/core';
import {CancelSlotsUpdateService} from '../../../services/consultant-ui/cancelSlotUpdate.service';
import {TimeslotsForRequestInterface} from '../../../interfaces/appointment.interface';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilitiesService} from '../../../services/global/utilities.service';

@Component({
    selector: 'tk-bo-confirm-reserved-slots-cancellation-modal',
    templateUrl: './confirm-reserved-slots-cancellation-modal.component.html',
    styleUrls: ['./confirm-reserved-slots-cancellation-modal.component.scss']
})
export class ConfirmReservedSlotsCancellationModalComponent {

    @Input() reservedSlotsToCancel: Array<TimeslotsForRequestInterface>;

    constructor(public activeModal: NgbActiveModal,
                public readonly util: UtilitiesService,
                private readonly cancelSlotUpdateService: CancelSlotsUpdateService) {
    }

    public confirmCancelSlots(): void {
        this.cancelSlotUpdateService.emitConfirmedSlotsCancellation();
        this.activeModal.close();
    }

    public closeModal(): void {
        this.activeModal.close();
    }

}
