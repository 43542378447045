import {Component, Input} from '@angular/core';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';
import {UtilitiesService} from '../../../services/global/utilities.service';

@Component({
    selector: 'tk-bo-interview-protocol',
    templateUrl: './interview-protocol.component.html',
    styleUrls: ['./interview-protocol.component.scss']
})
export class InterviewProtocolComponent {

    @Input() data: any;

    constructor(public activeModal: NgbActiveModal,
        public readonly util: UtilitiesService) {
    }

    public closeModal(): void {
        this.activeModal.close();
    }

}
