<div class="grid-root-container main-container">
    <div class="grid-item">
        <div class="login">
            <h1 class="login__title">
                TK-GesundheitsCoach Beraterinterface
            </h1>
            <ng-container *ngIf="!(hasValidAccessToken)">
                <div class="login__button-wrapper">
                    <button class="button button-primary"
                            (click)="login()">
                        Login
                    </button>
                </div>
            </ng-container>
        </div>
    </div>
</div>
