<nav [ngClass]="{'spacer--top': hasMarginTop, 'noPadding': hasNoPadding}">
    <div class="tabs">
        <span *ngFor="let entry of entries"
              class="tabs-entry-wrapper tabs-entry-wrapper--1of{{entries.length}}">
            <a class="tabs-entry"
               [routerLink]="entry.href"
               [queryParams]="queryParam"
               [routerLinkActive]="['tabs-entry--active']">
                <span class="tabs-link">
                    <span class="tabs-link-text">
                        {{entry.label}}
                    </span>
                </span>
            </a>
        </span>
    </div>
</nav>
