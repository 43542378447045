import {AuthConfig} from 'angular-oauth2-oidc';

const callbackPath = '/auth/callback';

export const authConfig: AuthConfig = {
  scope: 'openid profile email offline_access urn:zitadel:iam:user:metadata',
  responseType: 'code',
  oidc: true,
  clientId: '246051528807363767@dev-tk-beraterinterface',
  issuer: 'https://dev-tk-customercare-vtldi0.zitadel.cloud',
  redirectUri: 'https://consultant-dev.tk.significo.dev' + callbackPath,
  postLogoutRedirectUri: 'https://consultant-dev.tk.significo.dev',
  requireHttps: true,
};
